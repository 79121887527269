var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        {
          staticStyle: { "z-index": "10" },
          attrs: {
            absolute: "",
            temporary: "",
            width: "512",
            height:
              _vm.$_windowMixin_contentHeight +
              _vm.$_windowMixin_getFooterHeight()
          },
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          _vm.loadingScheduled
            ? _c("v-skeleton-loader", { attrs: { type: "list-item@20" } })
            : _c(
                "v-list",
                { attrs: { nav: "", dense: "" } },
                [
                  _c(
                    "v-list-item-group",
                    {
                      attrs: {
                        value: _vm.mailingItemId,
                        "active-class": "deep-purple--text text--accent-4"
                      }
                    },
                    [
                      _vm._l(_vm.scheduledMailings, function(mailing, i) {
                        return [
                          i > 0 &&
                          !_vm.scheduledMailings[
                            i - 1
                          ].scheduleDateLocal.isSame(
                            mailing.scheduleDateLocal,
                            "date"
                          )
                            ? _c("v-divider", {
                                key: mailing.mailingItemId + "-D"
                              })
                            : _vm._e(),
                          i === 0 ||
                          !_vm.scheduledMailings[
                            i - 1
                          ].scheduleDateLocal.isSame(
                            mailing.scheduleDateLocal,
                            "date"
                          )
                            ? _c(
                                "v-subheader",
                                {
                                  key: mailing.mailingItemId + "-T",
                                  staticClass: "text-h5 font-weight-bold"
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("displayDateFormat3")(
                                          mailing.scheduleDateLocal
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "v-list-item",
                            {
                              key: mailing.mailingItemId,
                              attrs: { value: mailing.mailingItemId }
                            },
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push({
                                        name: "SingleMailingReport",
                                        params: {
                                          campaignId: mailing.campaignId,
                                          mailingItemId: mailing.mailingItemId
                                        }
                                      })
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "auto" } },
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                color:
                                                  mailing.status === 4
                                                    ? "green lighten-2"
                                                    : "accent"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "displayTimeFormat3"
                                                    )(mailing.scheduleDateLocal)
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "align-self-center",
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(mailing.campaignName) +
                                              " - " +
                                              _vm._s(mailing.mailingName) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
        ],
        1
      ),
      _c("CampaignHeader", {
        ref: "campaignHeader",
        attrs: {
          disabled: "",
          "campaign-id": _vm.campaignId,
          "mailing-item-id": _vm.mailingItemId
        }
      }),
      _vm.reportLoading
        ? _c("v-skeleton-loader", { attrs: { type: "table" } })
        : _vm.results
        ? _c(
            "v-card",
            { staticClass: "single-mailing-report" },
            [
              _c(
                "v-card-text",
                { staticClass: "black--text font-weight-normal" },
                [
                  _c(
                    "vue-html2pdf",
                    {
                      ref: "html2Pdf",
                      attrs: {
                        "show-layout": false,
                        "float-layout": false,
                        "enable-download": false,
                        "preview-modal": false,
                        "manual-pagination": true,
                        "html-to-pdf-options": _vm.htmlToPdfOptions,
                        "pdf-format": "letter",
                        "pdf-orientation": "landscape",
                        "pdf-content-width": "100%"
                      },
                      on: {
                        beforeDownload: function($event) {
                          return _vm.beforeDownload($event)
                        }
                      }
                    },
                    [
                      _c(
                        "section",
                        {
                          staticClass: "pdf-content",
                          attrs: { slot: "pdf-content" },
                          slot: "pdf-content"
                        },
                        [
                          _c(
                            "section",
                            { staticClass: "pdf-header" },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "primary--text pb-4",
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-h4 font-weight-bold"
                                        },
                                        [_vm._v("Med-E-Mail Analytics")]
                                      ),
                                      _c("br"),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-h5 font-weight-light"
                                        },
                                        [
                                          _vm._v(
                                            "Single Mailing Report Summary"
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var onTooltip = ref.on
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: !_vm.pdfMenu,
                                                            expression:
                                                              "!pdfMenu"
                                                          }
                                                        ],
                                                        staticClass: "ml-4",
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.drawer = !_vm.drawer
                                                          }
                                                        }
                                                      },
                                                      onTooltip
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "tertiary"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        mdi-menu\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("View Other Sent Mailings")
                                          ])
                                        ]
                                      ),
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: {
                                            right: "",
                                            "x-offset": "",
                                            origin: "right",
                                            "close-on-click": false,
                                            transition: "slide-x-transition"
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var onMenu = ref.on
                                                return [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var onTooltip =
                                                                ref.on
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    {
                                                                      directives: [
                                                                        {
                                                                          name:
                                                                            "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value: !_vm.pdfMenu,
                                                                          expression:
                                                                            "!pdfMenu"
                                                                        }
                                                                      ],
                                                                      attrs: {
                                                                        icon: ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.beforePdfDownload()
                                                                        }
                                                                      }
                                                                    },
                                                                    Object.assign(
                                                                      {},
                                                                      onMenu,
                                                                      onTooltip
                                                                    )
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "success"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                            mdi-file-pdf-box\n                          "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Download Single Mailing Report PDF"
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value: _vm.pdfMenu,
                                            callback: function($$v) {
                                              _vm.pdfMenu = $$v
                                            },
                                            expression: "pdfMenu"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            [
                                              _c(
                                                "v-card-text",
                                                { staticClass: "pa-5" },
                                                [
                                                  _c("div", [
                                                    _vm._v(
                                                      "Formatting PDF for download..."
                                                    )
                                                  ]),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "font-italic"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        If the report looks too small, resize your browser window!\n                      "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c("v-divider"),
                                              _c(
                                                "v-card-actions",
                                                [
                                                  _c("v-spacer"),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "tertiary"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.afterPdfDownload()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        Cancel\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "success"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.generatePdfReport()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        Download\n                      "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var onTooltip = ref.on
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: !_vm.pdfMenu,
                                                            expression:
                                                              "!pdfMenu"
                                                          }
                                                        ],
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.downloadEntireReport()
                                                          }
                                                        }
                                                      },
                                                      onTooltip
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color:
                                                              "green lighten-3"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        mdi-file-download\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "Download Single Mailing Report CSV"
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm.results.reportType === 2 &&
                                      _vm.results.deliverabilityMetricsTotals
                                        .totalAudience > 0
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var onTooltip = ref.on
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value: !_vm.pdfMenu,
                                                                  expression:
                                                                    "!pdfMenu"
                                                                }
                                                              ],
                                                              attrs: {
                                                                icon: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.downloadLeadScoresReport()
                                                                }
                                                              }
                                                            },
                                                            onTooltip
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "green lighten-3"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        mdi-file-download\n                      "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                3952730613
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "Download Lead Scores Report"
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.results.reportType === 3 &&
                                      _vm.results.deliverabilityMetricsTotals
                                        .totalAudience > 0
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var onTooltip = ref.on
                                                      return [
                                                        _c(
                                                          "v-menu",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              "close-on-content-click": false
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              directives: [
                                                                                {
                                                                                  name:
                                                                                    "show",
                                                                                  rawName:
                                                                                    "v-show",
                                                                                  value: !_vm.pdfMenu,
                                                                                  expression:
                                                                                    "!pdfMenu"
                                                                                }
                                                                              ],
                                                                              attrs: {
                                                                                icon:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            "v-btn",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          Object.assign(
                                                                            {},
                                                                            on,
                                                                            onTooltip
                                                                          )
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                color:
                                                                                  "green lighten-3"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                            mdi-file-download\n                          "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _c(
                                                              "v-list",
                                                              [
                                                                _c(
                                                                  "v-list-item",
                                                                  {
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.downloadComprehensiveReportZip()
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      [
                                                                        _vm._v(
                                                                          "All Reports"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-list-item",
                                                                  {
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.downloadComprehensiveReportReceived()
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      [
                                                                        _vm._v(
                                                                          "Received"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-list-item",
                                                                  {
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.downloadComprehensiveReportOpens()
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      [
                                                                        _vm._v(
                                                                          "Opens"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-list-item",
                                                                  {
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.downloadComprehensiveReportClicks()
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      [
                                                                        _vm._v(
                                                                          "Clicks"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-list-item",
                                                                  {
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.downloadComprehensiveReportOptOuts()
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      [
                                                                        _vm._v(
                                                                          "Opt Outs"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-list-item",
                                                                  {
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.downloadComprehensiveReportEnhanced()
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      [
                                                                        _vm._v(
                                                                          "Enhanced"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                1977620560
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "Download Comprehensive Reports"
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.showLinkToggle
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var onTooltip = ref.on
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value: !_vm.pdfMenu,
                                                                  expression:
                                                                    "!pdfMenu"
                                                                }
                                                              ],
                                                              attrs: {
                                                                icon: "",
                                                                color: "white"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.toggleLinkData = !_vm.toggleLinkData
                                                                }
                                                              }
                                                            },
                                                            onTooltip
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color: _vm.toggleLinkData
                                                                    ? "info"
                                                                    : "tertiary"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        " +
                                                                    _vm._s(
                                                                      _vm.toggleLinkData
                                                                        ? "mdi-toggle-switch"
                                                                        : "mdi-toggle-switch-off"
                                                                    ) +
                                                                    "\n                      "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                3907131188
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "Toggle Link Data " +
                                                    _vm._s(
                                                      _vm.toggleLinkData
                                                        ? "off"
                                                        : "on"
                                                    )
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.showAgeToggle
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var onTooltip = ref.on
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value: !_vm.pdfMenu,
                                                                  expression:
                                                                    "!pdfMenu"
                                                                }
                                                              ],
                                                              attrs: {
                                                                icon: "",
                                                                color: "white"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.toggleAgeData = !_vm.toggleAgeData
                                                                }
                                                              }
                                                            },
                                                            onTooltip
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color: _vm.toggleAgeData
                                                                    ? "info"
                                                                    : "tertiary"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        " +
                                                                    _vm._s(
                                                                      _vm.toggleAgeData
                                                                        ? "mdi-toggle-switch"
                                                                        : "mdi-toggle-switch-off"
                                                                    ) +
                                                                    "\n                      "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                3947975700
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "Toggle Age Data " +
                                                    _vm._s(
                                                      _vm.toggleAgeData
                                                        ? "off"
                                                        : "on"
                                                    )
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4" } },
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          height: "73",
                                          contain: "",
                                          src: require("@/assets/mms-signature.jpg")
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider", { staticClass: "mb-4" }),
                          _c(
                            "section",
                            { staticClass: "summ-table" },
                            [
                              _c(
                                "v-card",
                                { staticClass: "pa-2 ma-2" },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "4" } },
                                        [
                                          _c("v-simple-table", {
                                            attrs: {
                                              id: "headerTable1",
                                              dense: ""
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function() {
                                                  return [
                                                    _c("tbody", [
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-left",
                                                            attrs: {
                                                              width: "175"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                            Client Name:\n                          "
                                                            )
                                                          ]
                                                        ),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.results
                                                                .customerName
                                                            )
                                                          )
                                                        ])
                                                      ]),
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-left",
                                                            attrs: {
                                                              width: "175"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                            Campaign Name:\n                          "
                                                            )
                                                          ]
                                                        ),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.results
                                                                .campaignName
                                                            )
                                                          )
                                                        ])
                                                      ]),
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-left",
                                                            attrs: {
                                                              width: "175"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                            Mailing Name:\n                          "
                                                            )
                                                          ]
                                                        ),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.results
                                                                .mailingMetaData
                                                                .name
                                                            )
                                                          )
                                                        ])
                                                      ]),
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-left",
                                                            attrs: {
                                                              width: "175"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                            NOWW Job Number:\n                          "
                                                            )
                                                          ]
                                                        ),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.results
                                                                .mailingMetaData
                                                                .campaignNowwOrderNumber
                                                            )
                                                          )
                                                        ])
                                                      ]),
                                                      _vm.results
                                                        .mailingMetaData
                                                        .sequence > 1
                                                        ? _c("tr", [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-left",
                                                                attrs: {
                                                                  width: "175"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                            Resend Type:\n                          "
                                                                )
                                                              ]
                                                            ),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$_resendType_display(
                                                                    _vm.results
                                                                      .mailingMetaData
                                                                      .resendType
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ])
                                                        : _vm._e(),
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-left",
                                                            attrs: {
                                                              width: "175"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                            Lists:\n                          "
                                                            )
                                                          ]
                                                        ),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.results.lists.join(
                                                                ", "
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ])
                                                    ])
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      ),
                                      _c("v-divider", {
                                        staticClass: "mx-2 d-none d-sm-flex",
                                        attrs: { vertical: "" }
                                      }),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "7" } },
                                        [
                                          _c("v-simple-table", {
                                            attrs: {
                                              id: "headerTable2",
                                              dense: ""
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "tbody",
                                                      [
                                                        _vm._l(
                                                          _vm.results
                                                            .mailingMetaData
                                                            .splits,
                                                          function(split) {
                                                            return _c(
                                                              "tr",
                                                              { key: split.id },
                                                              [
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "text-left",
                                                                    attrs: {
                                                                      width:
                                                                        "175"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                            Subject (" +
                                                                        _vm._s(
                                                                          split.splitLetter
                                                                        ) +
                                                                        "):\n                          "
                                                                    )
                                                                  ]
                                                                ),
                                                                _c("td", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      split.subject
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        _c("tr", [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "text-left",
                                                              attrs: {
                                                                width: "175"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    _vm.results
                                                                      .mailingMetaData
                                                                      .winningDistribution >
                                                                      0
                                                                      ? "Split "
                                                                      : ""
                                                                  ) +
                                                                  "Send Schedule:\n                          "
                                                              )
                                                            ]
                                                          ),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f(
                                                                  "displayDateTimeFormat"
                                                                )(
                                                                  _vm.$_global_parseAsUtc(
                                                                    _vm.results
                                                                      .mailingMetaData
                                                                      .scheduleDate
                                                                  )
                                                                )
                                                              ) +
                                                                " - " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "displayDateTimeFormat"
                                                                  )(
                                                                    _vm.$_global_parseAsUtc(
                                                                      _vm
                                                                        .results
                                                                        .mailingMetaData
                                                                        .scheduleDateEnd
                                                                    )
                                                                  )
                                                                )
                                                            )
                                                          ])
                                                        ]),
                                                        _vm.results
                                                          .mailingMetaData
                                                          .winningDistribution >
                                                        0
                                                          ? _c("tr", [
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-left",
                                                                  attrs: {
                                                                    width: "175"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            Winning Send Schedule:\n                          "
                                                                  )
                                                                ]
                                                              ),
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "displayDateTimeFormat"
                                                                    )(
                                                                      _vm.$_global_parseAsUtc(
                                                                        _vm
                                                                          .results
                                                                          .mailingMetaData
                                                                          .winningScheduleDate
                                                                      )
                                                                    )
                                                                  ) +
                                                                    " - " +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "displayDateTimeFormat"
                                                                      )(
                                                                        _vm.$_global_parseAsUtc(
                                                                          _vm
                                                                            .results
                                                                            .mailingMetaData
                                                                            .winningScheduleDateEnd
                                                                        )
                                                                      )
                                                                    )
                                                                )
                                                              ])
                                                            ])
                                                          : _vm._e(),
                                                        _vm.results
                                                          .mailingMetaData
                                                          .winningDistribution >
                                                        0
                                                          ? _c("tr", [
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-left",
                                                                  attrs: {
                                                                    width: "175"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            Winner:\n                          "
                                                                  )
                                                                ]
                                                              ),
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.results
                                                                      .winningSplit
                                                                      .splitLetter
                                                                  ) +
                                                                    " (" +
                                                                    _vm._s(
                                                                      _vm.$_winningCriteria_display(
                                                                        _vm
                                                                          .results
                                                                          .mailingMetaData
                                                                          .winningCriteria
                                                                      )
                                                                    ) +
                                                                    ")"
                                                                )
                                                              ])
                                                            ])
                                                          : _vm._e()
                                                      ],
                                                      2
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider", { staticClass: "my-4" }),
                          _c("div", { staticClass: "html2pdf__page-break" }),
                          _vm.results.deliverabilityMetricsTotals
                            .totalAudience > 0
                            ? [
                                _c(
                                  "section",
                                  { staticClass: "title-bar" },
                                  [
                                    _c(
                                      "v-card-subtitle",
                                      {
                                        staticClass:
                                          "grey white--text font-weight-bold text-h5 pa-2 mb-4 title-bar-text",
                                        staticStyle: { display: "flex" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                Deliverability Metrics\n                "
                                        ),
                                        _c("v-spacer"),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var onTooltip = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: !_vm.pdfMenu,
                                                                expression:
                                                                  "!pdfMenu"
                                                              }
                                                            ],
                                                            attrs: {
                                                              icon: "",
                                                              color: "white"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.$_downloadFileMixin_downloadReportTable(
                                                                  "deliverabilityMetrics",
                                                                  _vm.results
                                                                    .mailingMetaData
                                                                    .campaignNowwOrderNumber +
                                                                    "-" +
                                                                    _vm.results.mailingMetaData.name.replace(
                                                                      / /g,
                                                                      ""
                                                                    ) +
                                                                    "-DeliverabilityMetrics"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          onTooltip
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "green lighten-3"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        mdi-file-download\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              2708238758
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Download Deliverability Metrics"
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          lg:
                                            _vm.results.mailingMetaData.splits
                                              .length > 1 && _vm.pdfMenu
                                              ? "12"
                                              : "6",
                                          sm: "6"
                                        }
                                      },
                                      [
                                        _c(
                                          "section",
                                          { staticClass: "delv-met" },
                                          [
                                            _c(
                                              "v-card",
                                              { staticClass: "pa-2 ma-2" },
                                              [
                                                _c("v-simple-table", {
                                                  attrs: {
                                                    id: "deliverabilityMetrics",
                                                    dense: ""
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function() {
                                                          return [
                                                            _c("thead", [
                                                              _c(
                                                                "tr",
                                                                [
                                                                  _c("th", {
                                                                    staticClass:
                                                                      "text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text",
                                                                    style: [
                                                                      {
                                                                        color:
                                                                          "#6d2c8a",
                                                                        "text-align":
                                                                          "right"
                                                                      }
                                                                    ]
                                                                  }),
                                                                  _vm.results
                                                                    .splitData
                                                                    .deliverabilityMetrics
                                                                    .length > 1
                                                                    ? [
                                                                        _vm._l(
                                                                          _vm
                                                                            .results
                                                                            .splitData
                                                                            .deliverabilityMetrics,
                                                                          function(
                                                                            split,
                                                                            i
                                                                          ) {
                                                                            return [
                                                                              _c(
                                                                                "th",
                                                                                {
                                                                                  key: i,
                                                                                  staticClass:
                                                                                    "text-right primary--text font-weight-bold text-subtitle-2 table-border-left table-border-bottom-bold delv-met-th-text",
                                                                                  style: [
                                                                                    {
                                                                                      color:
                                                                                        "#6d2c8a",
                                                                                      "text-align":
                                                                                        "right"
                                                                                    }
                                                                                  ]
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .results
                                                                                          .mailingMetaData
                                                                                          .splits[
                                                                                          i
                                                                                        ]
                                                                                          .splitLetter
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "th",
                                                                                {
                                                                                  key:
                                                                                    i +
                                                                                    "-rate",
                                                                                  staticClass:
                                                                                    "text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text",
                                                                                  style: [
                                                                                    {
                                                                                      color:
                                                                                        "#6d2c8a",
                                                                                      "text-align":
                                                                                        "right"
                                                                                    }
                                                                                  ]
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .results
                                                                                          .mailingMetaData
                                                                                          .splits[
                                                                                          i
                                                                                        ]
                                                                                          .splitLetter
                                                                                      ) +
                                                                                      " Rate\n                                "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          }
                                                                        )
                                                                      ]
                                                                    : _vm._e(),
                                                                  _vm.results
                                                                    .winnerData
                                                                    .deliverabilityMetrics
                                                                    .length > 0
                                                                    ? [
                                                                        _vm._l(
                                                                          _vm
                                                                            .results
                                                                            .winnerData
                                                                            .deliverabilityMetrics,
                                                                          function(
                                                                            split,
                                                                            i
                                                                          ) {
                                                                            return [
                                                                              _c(
                                                                                "th",
                                                                                {
                                                                                  key:
                                                                                    "w-" +
                                                                                    i,
                                                                                  staticClass:
                                                                                    "text-right primary--text font-weight-bold text-subtitle-2 table-border-left table-border-bottom-bold delv-met-th-text",
                                                                                  style: [
                                                                                    {
                                                                                      color:
                                                                                        "#6d2c8a",
                                                                                      "text-align":
                                                                                        "right"
                                                                                    }
                                                                                  ]
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm.results.winnerLabel.replace(
                                                                                          "Winner",
                                                                                          "W"
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "th",
                                                                                {
                                                                                  key:
                                                                                    "w-" +
                                                                                    i +
                                                                                    "-rate",
                                                                                  staticClass:
                                                                                    "text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text",
                                                                                  style: [
                                                                                    {
                                                                                      color:
                                                                                        "#6d2c8a",
                                                                                      "text-align":
                                                                                        "right"
                                                                                    }
                                                                                  ]
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm.results.winnerLabel.replace(
                                                                                          "Winner",
                                                                                          "W"
                                                                                        )
                                                                                      ) +
                                                                                      " Rate\n                                "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          }
                                                                        )
                                                                      ]
                                                                    : _vm._e(),
                                                                  !_vm.pdfMenu ||
                                                                  _vm.results
                                                                    .splitData
                                                                    .deliverabilityMetrics
                                                                    .length < 4
                                                                    ? [
                                                                        _c(
                                                                          "th",
                                                                          {
                                                                            staticClass:
                                                                              "text-right primary--text font-weight-bold text-subtitle-2 table-border-left-bold table-border-bottom-bold delv-met-th-total",
                                                                            style: {
                                                                              color:
                                                                                "#6d2c8a"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                Total\n                              "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "th",
                                                                          {
                                                                            staticClass:
                                                                              "text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-total",
                                                                            style: {
                                                                              color:
                                                                                "#6d2c8a"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                Rate\n                              "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    : _vm._e()
                                                                ],
                                                                2
                                                              )
                                                            ]),
                                                            _c("tbody", [
                                                              _c(
                                                                "tr",
                                                                [
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text",
                                                                      style: {
                                                                        color:
                                                                          "#6d2c8a"
                                                                      },
                                                                      attrs: {
                                                                        width:
                                                                          "200"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              Sent\n                            "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm.results
                                                                    .splitData
                                                                    .deliverabilityMetrics
                                                                    .length > 1
                                                                    ? [
                                                                        _vm._l(
                                                                          _vm
                                                                            .results
                                                                            .splitData
                                                                            .deliverabilityMetrics,
                                                                          function(
                                                                            deliverabilityMetrics,
                                                                            i
                                                                          ) {
                                                                            return [
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  key: i,
                                                                                  staticClass:
                                                                                    "text-right table-border-left table-border-bottom-bold",
                                                                                  style: {
                                                                                    "text-align":
                                                                                      "right"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "commaNumber"
                                                                                        )(
                                                                                          deliverabilityMetrics.sentMessages
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  key:
                                                                                    i +
                                                                                    "-rate",
                                                                                  staticClass:
                                                                                    "text-right table-border-bottom-bold",
                                                                                  style: {
                                                                                    "text-align":
                                                                                      "right"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "percent"
                                                                                        )(
                                                                                          deliverabilityMetrics.sentRate,
                                                                                          2
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          }
                                                                        )
                                                                      ]
                                                                    : _vm._e(),
                                                                  _vm.results
                                                                    .winnerData
                                                                    .deliverabilityMetrics
                                                                    .length > 0
                                                                    ? [
                                                                        _vm._l(
                                                                          _vm
                                                                            .results
                                                                            .winnerData
                                                                            .deliverabilityMetrics,
                                                                          function(
                                                                            deliverabilityMetrics,
                                                                            i
                                                                          ) {
                                                                            return [
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  key:
                                                                                    "w-" +
                                                                                    i,
                                                                                  staticClass:
                                                                                    "text-right table-border-left table-border-bottom-bold",
                                                                                  style: {
                                                                                    "text-align":
                                                                                      "right"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "commaNumber"
                                                                                        )(
                                                                                          deliverabilityMetrics.sentMessages
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  key:
                                                                                    "w-" +
                                                                                    i +
                                                                                    "-rate",
                                                                                  staticClass:
                                                                                    "text-right table-border-bottom-bold",
                                                                                  style: {
                                                                                    "text-align":
                                                                                      "right"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "percent"
                                                                                        )(
                                                                                          deliverabilityMetrics.sentRate,
                                                                                          2
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          }
                                                                        )
                                                                      ]
                                                                    : _vm._e(),
                                                                  !_vm.pdfMenu ||
                                                                  _vm.results
                                                                    .splitData
                                                                    .deliverabilityMetrics
                                                                    .length < 4
                                                                    ? [
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-right table-border-left-bold font-weight-bold table-border-bottom-bold delv-met-th-total"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "commaNumber"
                                                                                  )(
                                                                                    _vm
                                                                                      .results
                                                                                      .deliverabilityMetricsTotals
                                                                                      .sentMessages
                                                                                  )
                                                                                ) +
                                                                                "\n                              "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-right font-weight-bold table-border-bottom-bold delv-met-th-total"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "percent"
                                                                                  )(
                                                                                    _vm
                                                                                      .results
                                                                                      .deliverabilityMetricsTotals
                                                                                      .sentRate,
                                                                                    2
                                                                                  )
                                                                                ) +
                                                                                "\n                              "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    : _vm._e()
                                                                ],
                                                                2
                                                              ),
                                                              _c(
                                                                "tr",
                                                                [
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "text-left primary--text font-weight-bold text-subtitle-2 delv-met-th-text",
                                                                      style: {
                                                                        color:
                                                                          "#6d2c8a"
                                                                      },
                                                                      attrs: {
                                                                        width:
                                                                          "200"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              Hard Bounce\n                            "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm.results
                                                                    .splitData
                                                                    .deliverabilityMetrics
                                                                    .length > 1
                                                                    ? [
                                                                        _vm._l(
                                                                          _vm
                                                                            .results
                                                                            .splitData
                                                                            .deliverabilityMetrics,
                                                                          function(
                                                                            deliverabilityMetrics,
                                                                            i
                                                                          ) {
                                                                            return [
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  key: i,
                                                                                  staticClass:
                                                                                    "text-right table-border-left",
                                                                                  style: {
                                                                                    "text-align":
                                                                                      "right"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "commaNumber"
                                                                                        )(
                                                                                          deliverabilityMetrics.hardBounces
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  key:
                                                                                    i +
                                                                                    "-rate",
                                                                                  staticClass:
                                                                                    "text-right",
                                                                                  style: {
                                                                                    "text-align":
                                                                                      "right"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "percent"
                                                                                        )(
                                                                                          deliverabilityMetrics.hardBounceRate,
                                                                                          2
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          }
                                                                        )
                                                                      ]
                                                                    : _vm._e(),
                                                                  _vm.results
                                                                    .winnerData
                                                                    .deliverabilityMetrics
                                                                    .length > 0
                                                                    ? [
                                                                        _vm._l(
                                                                          _vm
                                                                            .results
                                                                            .winnerData
                                                                            .deliverabilityMetrics,
                                                                          function(
                                                                            deliverabilityMetrics,
                                                                            i
                                                                          ) {
                                                                            return [
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  key:
                                                                                    "w-" +
                                                                                    i,
                                                                                  staticClass:
                                                                                    "text-right table-border-left",
                                                                                  style: {
                                                                                    "text-align":
                                                                                      "right"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "commaNumber"
                                                                                        )(
                                                                                          deliverabilityMetrics.hardBounces
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  key:
                                                                                    "w-" +
                                                                                    i +
                                                                                    "-rate",
                                                                                  staticClass:
                                                                                    "text-right",
                                                                                  style: {
                                                                                    "text-align":
                                                                                      "right"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "percent"
                                                                                        )(
                                                                                          deliverabilityMetrics.hardBounceRate,
                                                                                          2
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          }
                                                                        )
                                                                      ]
                                                                    : _vm._e(),
                                                                  !_vm.pdfMenu ||
                                                                  _vm.results
                                                                    .splitData
                                                                    .deliverabilityMetrics
                                                                    .length < 4
                                                                    ? [
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-right table-border-left-bold font-weight-bold delv-met-th-total"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "commaNumber"
                                                                                  )(
                                                                                    _vm
                                                                                      .results
                                                                                      .deliverabilityMetricsTotals
                                                                                      .hardBounces
                                                                                  )
                                                                                ) +
                                                                                "\n                              "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-right font-weight-bold delv-met-th-total"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "percent"
                                                                                  )(
                                                                                    _vm
                                                                                      .results
                                                                                      .deliverabilityMetricsTotals
                                                                                      .hardBounceRate,
                                                                                    2
                                                                                  )
                                                                                ) +
                                                                                "\n                              "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    : _vm._e()
                                                                ],
                                                                2
                                                              ),
                                                              _c(
                                                                "tr",
                                                                [
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "text-left primary--text font-weight-bold text-subtitle-2 delv-met-th-text",
                                                                      style: {
                                                                        color:
                                                                          "#6d2c8a"
                                                                      },
                                                                      attrs: {
                                                                        width:
                                                                          "200"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              Soft Bounce\n                            "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm.results
                                                                    .splitData
                                                                    .deliverabilityMetrics
                                                                    .length > 1
                                                                    ? [
                                                                        _vm._l(
                                                                          _vm
                                                                            .results
                                                                            .splitData
                                                                            .deliverabilityMetrics,
                                                                          function(
                                                                            deliverabilityMetrics,
                                                                            i
                                                                          ) {
                                                                            return [
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  key: i,
                                                                                  staticClass:
                                                                                    "text-right table-border-left",
                                                                                  style: {
                                                                                    "text-align":
                                                                                      "right"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "commaNumber"
                                                                                        )(
                                                                                          deliverabilityMetrics.softBounces
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  key:
                                                                                    i +
                                                                                    "-rate",
                                                                                  staticClass:
                                                                                    "text-right",
                                                                                  style: {
                                                                                    "text-align":
                                                                                      "right"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "percent"
                                                                                        )(
                                                                                          deliverabilityMetrics.softBounceRate,
                                                                                          2
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          }
                                                                        )
                                                                      ]
                                                                    : _vm._e(),
                                                                  _vm.results
                                                                    .winnerData
                                                                    .deliverabilityMetrics
                                                                    .length > 0
                                                                    ? [
                                                                        _vm._l(
                                                                          _vm
                                                                            .results
                                                                            .winnerData
                                                                            .deliverabilityMetrics,
                                                                          function(
                                                                            deliverabilityMetrics,
                                                                            i
                                                                          ) {
                                                                            return [
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  key:
                                                                                    "w-" +
                                                                                    i,
                                                                                  staticClass:
                                                                                    "text-right table-border-left",
                                                                                  style: {
                                                                                    "text-align":
                                                                                      "right"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "commaNumber"
                                                                                        )(
                                                                                          deliverabilityMetrics.softBounces
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  key:
                                                                                    "w-" +
                                                                                    i +
                                                                                    "-rate",
                                                                                  staticClass:
                                                                                    "text-right",
                                                                                  style: {
                                                                                    "text-align":
                                                                                      "right"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "percent"
                                                                                        )(
                                                                                          deliverabilityMetrics.softBounceRate,
                                                                                          2
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          }
                                                                        )
                                                                      ]
                                                                    : _vm._e(),
                                                                  !_vm.pdfMenu ||
                                                                  _vm.results
                                                                    .splitData
                                                                    .deliverabilityMetrics
                                                                    .length < 4
                                                                    ? [
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-right table-border-left-bold font-weight-bold delv-met-th-total"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "commaNumber"
                                                                                  )(
                                                                                    _vm
                                                                                      .results
                                                                                      .deliverabilityMetricsTotals
                                                                                      .softBounces
                                                                                  )
                                                                                ) +
                                                                                "\n                              "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-right font-weight-bold delv-met-th-total"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "percent"
                                                                                  )(
                                                                                    _vm
                                                                                      .results
                                                                                      .deliverabilityMetricsTotals
                                                                                      .softBounceRate,
                                                                                    2
                                                                                  )
                                                                                ) +
                                                                                "\n                              "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    : _vm._e()
                                                                ],
                                                                2
                                                              ),
                                                              _c(
                                                                "tr",
                                                                [
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "text-left primary--text font-weight-bold text-subtitle-2 delv-met-th-text",
                                                                      style: {
                                                                        color:
                                                                          "#6d2c8a"
                                                                      },
                                                                      attrs: {
                                                                        width:
                                                                          "200"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              Delivered Messages\n                            "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm.results
                                                                    .splitData
                                                                    .deliverabilityMetrics
                                                                    .length > 1
                                                                    ? [
                                                                        _vm._l(
                                                                          _vm
                                                                            .results
                                                                            .splitData
                                                                            .deliverabilityMetrics,
                                                                          function(
                                                                            deliverabilityMetrics,
                                                                            i
                                                                          ) {
                                                                            return [
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  key: i,
                                                                                  staticClass:
                                                                                    "text-right table-border-left",
                                                                                  style: {
                                                                                    "text-align":
                                                                                      "right"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "commaNumber"
                                                                                        )(
                                                                                          deliverabilityMetrics.deliveredMessages
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  key:
                                                                                    i +
                                                                                    "-rate",
                                                                                  staticClass:
                                                                                    "text-right",
                                                                                  style: {
                                                                                    "text-align":
                                                                                      "right"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "percent"
                                                                                        )(
                                                                                          deliverabilityMetrics.deliveredRate,
                                                                                          2
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          }
                                                                        )
                                                                      ]
                                                                    : _vm._e(),
                                                                  _vm.results
                                                                    .winnerData
                                                                    .deliverabilityMetrics
                                                                    .length > 0
                                                                    ? [
                                                                        _vm._l(
                                                                          _vm
                                                                            .results
                                                                            .winnerData
                                                                            .deliverabilityMetrics,
                                                                          function(
                                                                            deliverabilityMetrics,
                                                                            i
                                                                          ) {
                                                                            return [
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  key:
                                                                                    "w-" +
                                                                                    i,
                                                                                  staticClass:
                                                                                    "text-right table-border-left",
                                                                                  style: {
                                                                                    "text-align":
                                                                                      "right"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "commaNumber"
                                                                                        )(
                                                                                          deliverabilityMetrics.deliveredMessages
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  key:
                                                                                    "w-" +
                                                                                    i +
                                                                                    "-rate",
                                                                                  staticClass:
                                                                                    "text-right",
                                                                                  style: {
                                                                                    "text-align":
                                                                                      "right"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "percent"
                                                                                        )(
                                                                                          deliverabilityMetrics.deliveredRate,
                                                                                          2
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          }
                                                                        )
                                                                      ]
                                                                    : _vm._e(),
                                                                  !_vm.pdfMenu ||
                                                                  _vm.results
                                                                    .splitData
                                                                    .deliverabilityMetrics
                                                                    .length < 4
                                                                    ? [
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-right table-border-left-bold font-weight-bold delv-met-th-total"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "commaNumber"
                                                                                  )(
                                                                                    _vm
                                                                                      .results
                                                                                      .deliverabilityMetricsTotals
                                                                                      .deliveredMessages
                                                                                  )
                                                                                ) +
                                                                                "\n                              "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-right font-weight-bold delv-met-th-total"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "percent"
                                                                                  )(
                                                                                    _vm
                                                                                      .results
                                                                                      .deliverabilityMetricsTotals
                                                                                      .deliveredRate,
                                                                                    2
                                                                                  )
                                                                                ) +
                                                                                "\n                              "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    : _vm._e()
                                                                ],
                                                                2
                                                              )
                                                            ])
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    476006596
                                                  )
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _vm.pdfMenu &&
                                        _vm.results.splitData
                                          .deliverabilityMetrics.length >= 4
                                          ? [
                                              _c(
                                                "section",
                                                {
                                                  staticClass: "delv-met",
                                                  style: [
                                                    {
                                                      "margin-top": "20px",
                                                      "margin-bottom": "20px"
                                                    }
                                                  ]
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticStyle: {
                                                        display: "inline-table"
                                                      },
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "6"
                                                      }
                                                    },
                                                    [
                                                      _c("tr", [
                                                        _c("th", {
                                                          staticClass:
                                                            "text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text",
                                                          style: [
                                                            {
                                                              "border-bottom":
                                                                "2px solid rgba(0, 0, 0, 0.12)"
                                                            }
                                                          ]
                                                        }),
                                                        _c(
                                                          "th",
                                                          {
                                                            staticClass:
                                                              "text-right primary--text font-weight-bold text-subtitle-2 table-border-left-bold table-border-bottom-bold delv-met-th-total",
                                                            style: [
                                                              {
                                                                color:
                                                                  "#6d2c8a",
                                                                "text-align":
                                                                  "right",
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          Total\n                        "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "th",
                                                          {
                                                            staticClass:
                                                              "text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-total",
                                                            style: [
                                                              {
                                                                color:
                                                                  "#6d2c8a",
                                                                "text-align":
                                                                  "right",
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          Rate\n                        "
                                                            )
                                                          ]
                                                        )
                                                      ]),
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text",
                                                            style: [
                                                              {
                                                                color:
                                                                  "#6d2c8a",
                                                                "text-align":
                                                                  "left",
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ],
                                                            attrs: {
                                                              width: "200"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          Sent\n                        "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right table-border-left-bold font-weight-bold table-border-bottom-bold delv-met-th-total",
                                                            style: [
                                                              {
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "commaNumber"
                                                                  )(
                                                                    _vm.results
                                                                      .deliverabilityMetricsTotals
                                                                      .sentMessages
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right font-weight-bold table-border-bottom-bold delv-met-th-total",
                                                            style: [
                                                              {
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "percent"
                                                                  )(
                                                                    _vm.results
                                                                      .deliverabilityMetricsTotals
                                                                      .sentRate,
                                                                    2
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        )
                                                      ]),
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text",
                                                            style: [
                                                              {
                                                                color:
                                                                  "#6d2c8a",
                                                                "text-align":
                                                                  "left",
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ],
                                                            attrs: {
                                                              width: "200"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          Hard Bounce\n                        "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right table-border-left-bold font-weight-bold delv-met-th-total",
                                                            style: [
                                                              {
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "commaNumber"
                                                                  )(
                                                                    _vm.results
                                                                      .deliverabilityMetricsTotals
                                                                      .hardBounces
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right font-weight-bold delv-met-th-total",
                                                            style: [
                                                              {
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "percent"
                                                                  )(
                                                                    _vm.results
                                                                      .deliverabilityMetricsTotals
                                                                      .hardBounceRate,
                                                                    2
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        )
                                                      ]),
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text",
                                                            style: [
                                                              {
                                                                color:
                                                                  "#6d2c8a",
                                                                "text-align":
                                                                  "left",
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ],
                                                            attrs: {
                                                              width: "200"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          Soft Bounce\n                        "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right table-border-left-bold font-weight-bold delv-met-th-total",
                                                            style: [
                                                              {
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "commaNumber"
                                                                  )(
                                                                    _vm.results
                                                                      .deliverabilityMetricsTotals
                                                                      .softBounces
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right font-weight-bold delv-met-th-total",
                                                            style: [
                                                              {
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "percent"
                                                                  )(
                                                                    _vm.results
                                                                      .deliverabilityMetricsTotals
                                                                      .softBounceRate,
                                                                    2
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        )
                                                      ]),
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text",
                                                            style: [
                                                              {
                                                                color:
                                                                  "#6d2c8a",
                                                                "text-align":
                                                                  "left",
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ],
                                                            attrs: {
                                                              width: "200"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          Delivered Messages\n                        "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right table-border-left-bold font-weight-bold delv-met-th-total",
                                                            style: [
                                                              {
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "commaNumber"
                                                                  )(
                                                                    _vm.results
                                                                      .deliverabilityMetricsTotals
                                                                      .deliveredMessages
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right font-weight-bold delv-met-th-total",
                                                            style: [
                                                              {
                                                                "border-bottom":
                                                                  "2px solid rgba(0, 0, 0, 0.12)"
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "percent"
                                                                  )(
                                                                    _vm.results
                                                                      .deliverabilityMetricsTotals
                                                                      .deliveredRate,
                                                                    2
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          sm:
                                            _vm.results.mailingMetaData.splits
                                              .length > 1 && !_vm.pdfMenu
                                              ? "3"
                                              : "6"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          { staticClass: "pa-2 ma-2" },
                                          [
                                            _c("pie-chart", {
                                              staticClass: "pb-2",
                                              attrs: {
                                                "chart-data":
                                                  _vm.results
                                                    .deliverabilityMetricsTotalsPieChart,
                                                options: _vm.getChartOptionsPie(
                                                  "Total Deliverability"
                                                ),
                                                height: 224
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm.results.mailingMetaData.splits.length >
                                    1
                                      ? _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              lg: _vm.pdfMenu ? "6" : "3",
                                              sm: "3"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              { staticClass: "pa-2 ma-2" },
                                              [
                                                _c("pie-chart", {
                                                  staticClass: "pb-2",
                                                  attrs: {
                                                    "chart-data":
                                                      _vm.results
                                                        .sentCountPieChart,
                                                    options: _vm.getChartOptionsPie(
                                                      "Sent Count"
                                                    ),
                                                    height: 224
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  _vm._l(
                                    _vm.liveResults.splitData.summaries,
                                    function(summary, i) {
                                      return _c(
                                        "v-col",
                                        {
                                          key: summary.mailingName,
                                          attrs: { cols: "11" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-h5 font-weight-bold text-center ml-4 mb-3"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Sending Status: " +
                                                  _vm._s(
                                                    summary.isSendComplete
                                                      ? 100
                                                      : Math.round(
                                                          (summary.sends /
                                                            summary.sendCount) *
                                                            100
                                                        )
                                                  ) +
                                                  "%\n                "
                                              )
                                            ]
                                          ),
                                          _c("v-progress-linear", {
                                            staticClass: "ml-4 mr-4 mb-5",
                                            attrs: {
                                              value: summary.isSendComplete
                                                ? 100
                                                : Math.round(
                                                    (summary.sends /
                                                      summary.sendCount) *
                                                      100
                                                  ),
                                              color:
                                                _vm.$_splitMixin_splitColors[i],
                                              height: 20,
                                              "buffer-value": summary.isSendComplete
                                                ? 0
                                                : Math.round(
                                                    (summary.sends /
                                                      summary.sendCount) *
                                                      100
                                                  ) + 5,
                                              striped: "",
                                              stream: ""
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                                !_vm.pdfMenu
                                  ? _c("v-divider", { staticClass: "my-4" })
                                  : _vm._e(),
                                _c("div", {
                                  staticClass: "html2pdf__page-break"
                                }),
                                _c(
                                  "section",
                                  { staticClass: "title-bar" },
                                  [
                                    _c(
                                      "v-card-subtitle",
                                      {
                                        staticClass:
                                          "grey white--text font-weight-bold text-h5 pa-2 mb-4 title-bar-text",
                                        staticStyle: { display: "flex" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                Engagement Summary\n                "
                                        ),
                                        _c("v-spacer"),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var onTooltip = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: !_vm.pdfMenu,
                                                                expression:
                                                                  "!pdfMenu"
                                                              }
                                                            ],
                                                            attrs: {
                                                              icon: "",
                                                              color: "white"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.$_downloadFileMixin_downloadReportTable(
                                                                  "engagementSummaryByDay",
                                                                  _vm.results
                                                                    .mailingMetaData
                                                                    .campaignNowwOrderNumber +
                                                                    "-" +
                                                                    _vm.results.mailingMetaData.name.replace(
                                                                      / /g,
                                                                      ""
                                                                    ) +
                                                                    "-EngagementSummaryByDay"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          onTooltip
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "green lighten-3"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        mdi-file-download\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              3876172070
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Download Engagement Summary by Day"
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var onTooltip = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: !_vm.pdfMenu,
                                                                expression:
                                                                  "!pdfMenu"
                                                              }
                                                            ],
                                                            attrs: {
                                                              icon: "",
                                                              color: "white"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.toggleGrossData = !_vm.toggleGrossData
                                                              }
                                                            }
                                                          },
                                                          onTooltip
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: _vm.toggleGrossData
                                                                  ? "light-blue lighten-4"
                                                                  : "white"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.toggleGrossData
                                                                      ? "mdi-toggle-switch"
                                                                      : "mdi-toggle-switch-off"
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              3394166472
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Toggle Gross/Opt Out Data " +
                                                  _vm._s(
                                                    _vm.toggleGrossData
                                                      ? "off"
                                                      : "on"
                                                  )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c("v-col", { attrs: { cols: "12" } }, [
                                      _c(
                                        "section",
                                        { staticClass: "dist-eng-summ" },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "pa-2 ma-2 text-center"
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  attrs: {
                                                    "no-gutters": "",
                                                    justify: "center"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-h5 font-weight-bold pa-2 dist-eng-summ-title"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          Distinct Engagement Summary\n                        "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-h5 pb-2"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          You delivered messages to "
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "green--text font-weight-bold dist-eng-summ-green"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "commaNumber"
                                                                  )(
                                                                    _vm.results
                                                                      .distinctMetrics
                                                                      .distinctReceived
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " individuals in your mailing.\n                        "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-h5 pb-2 text-center"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          Of those delivered messages...\n                          "
                                                          ),
                                                          _c("div", [
                                                            _vm._v(
                                                              "\n                            Your mailing engaged with "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "green--text font-weight-bold dist-eng-summ-green"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "commaNumber"
                                                                    )(
                                                                      _vm
                                                                        .results
                                                                        .distinctMetrics
                                                                        .distinctOpens
                                                                    )
                                                                  ) +
                                                                    " (" +
                                                                    _vm._s(
                                                                      _vm
                                                                        .results
                                                                        .distinctMetrics
                                                                        .distinctOpenRate
                                                                    ) +
                                                                    "%)"
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " individuals!\n                          "
                                                            )
                                                          ]),
                                                          _c("div", [
                                                            _vm._v(
                                                              "\n                            You got clicks from "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "green--text font-weight-bold dist-eng-summ-green"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "commaNumber"
                                                                    )(
                                                                      _vm
                                                                        .results
                                                                        .distinctMetrics
                                                                        .distinctClicks
                                                                    )
                                                                  ) +
                                                                    " (" +
                                                                    _vm._s(
                                                                      _vm
                                                                        .results
                                                                        .distinctMetrics
                                                                        .distinctClickRate
                                                                    ) +
                                                                    "%)"
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " individuals!\n                          "
                                                            )
                                                          ]),
                                                          _c(
                                                            "div",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    _vm.toggleGrossData,
                                                                  expression:
                                                                    "toggleGrossData"
                                                                }
                                                              ]
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "grey--text font-weight-bold dist-eng-summ-gray"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "commaNumber"
                                                                      )(
                                                                        _vm
                                                                          .results
                                                                          .distinctMetrics
                                                                          .distinctOptOuts
                                                                      )
                                                                    ) +
                                                                      " (" +
                                                                      _vm._s(
                                                                        _vm
                                                                          .results
                                                                          .distinctMetrics
                                                                          .distinctOptOutRate
                                                                      ) +
                                                                      "%)"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " individuals opted out of your emails.\n                          "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "3" } },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "pa-2 ma-2 dist-eng-charts"
                                          },
                                          [
                                            _c("bar-chart", {
                                              attrs: {
                                                "chart-data":
                                                  _vm.results.clickToOpenChart,
                                                options: _vm.getChartOptionsBarPercent(
                                                  "Unique Click to Open %",
                                                  "",
                                                  ""
                                                ),
                                                height: 400
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "9" } },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "pa-2 ma-2 dist-eng-charts"
                                          },
                                          [
                                            _c("line-chart", {
                                              attrs: {
                                                "chart-data":
                                                  _vm.engagementMetricsDataSplits,
                                                options: _vm.getChartOptionsLine(
                                                  _vm.results.mailingMetaData
                                                    .winningDistribution > 0
                                                    ? "Split Clicks/Opens from " +
                                                        _vm.$options.filters.displayDateFormat(
                                                          _vm.$_global_parseAsUtc(
                                                            _vm.results
                                                              .mailingMetaData
                                                              .scheduleDate
                                                          )
                                                        )
                                                    : "Clicks/Opens from " +
                                                        _vm.$options.filters.displayDateFormat(
                                                          _vm.$_global_parseAsUtc(
                                                            _vm.results
                                                              .mailingMetaData
                                                              .scheduleDate
                                                          )
                                                        )
                                                )
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm.results.mailingMetaData
                                      .winningDistribution > 0
                                      ? _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "html2pdf__page-break"
                                            }),
                                            _c(
                                              "v-card",
                                              {
                                                staticClass:
                                                  "pa-2 ma-2 dist-eng-charts",
                                                style: _vm.pdfMenu
                                                  ? { "margin-top": "20px" }
                                                  : ""
                                              },
                                              [
                                                _c("line-chart", {
                                                  attrs: {
                                                    "chart-data":
                                                      _vm.engagementMetricsDataWinner,
                                                    options: _vm.getChartOptionsLine(
                                                      _vm.results.winnerLabel +
                                                        " Clicks/Opens from " +
                                                        _vm.$options.filters.displayDateFormat(
                                                          _vm.$_global_parseAsUtc(
                                                            _vm.results
                                                              .mailingMetaData
                                                              .winningScheduleDate
                                                          )
                                                        )
                                                    )
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("v-divider", {
                                          staticClass: "my-4"
                                        }),
                                        _c(
                                          "section",
                                          { staticClass: "eng-summ-table" },
                                          [
                                            _c(
                                              "v-card",
                                              { staticClass: "pa-2 ma-2" },
                                              [
                                                _c("v-simple-table", {
                                                  attrs: {
                                                    id:
                                                      "engagementSummaryByDay",
                                                    dense: ""
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function() {
                                                          return [
                                                            _c("thead", [
                                                              _c(
                                                                "tr",
                                                                [
                                                                  _c("th", {
                                                                    staticClass:
                                                                      "text-left primary--text font-weight-bold text-subtitle-2 eng-summ-table-th",
                                                                    class: {
                                                                      "table-border-bottom-bold":
                                                                        _vm
                                                                          .results
                                                                          .mailingMetaData
                                                                          .splits
                                                                          .length <=
                                                                        1
                                                                    },
                                                                    style: [
                                                                      {
                                                                        color:
                                                                          "#6d2c8a",
                                                                        "text-align":
                                                                          "left"
                                                                      }
                                                                    ]
                                                                  }),
                                                                  _vm._l(
                                                                    _vm.engagementMetricsComputed,
                                                                    function(
                                                                      metric,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "th",
                                                                        {
                                                                          key: index,
                                                                          staticClass:
                                                                            "text-right primary--text font-weight-bold text-subtitle-2 table-border-left-bold eng-summ-table-th",
                                                                          class: {
                                                                            "table-border-bottom-bold":
                                                                              _vm
                                                                                .results
                                                                                .mailingMetaData
                                                                                .splits
                                                                                .length <=
                                                                              1
                                                                          },
                                                                          style: {
                                                                            color:
                                                                              "#6d2c8a"
                                                                          },
                                                                          attrs: {
                                                                            colspan: _vm.getEngagementHeaderColspan()
                                                                          }
                                                                        },
                                                                        [
                                                                          !_vm.pdfMenu &&
                                                                          _vm
                                                                            .results
                                                                            .mailingMetaData
                                                                            .splits
                                                                            .length ===
                                                                            1
                                                                            ? _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    color:
                                                                                      _vm
                                                                                        .engagementMetricsDataSplits
                                                                                        .datasets[
                                                                                        index
                                                                                      ]
                                                                                        .backgroundColor
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                mdi-square-rounded\n                              "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm
                                                                            .results
                                                                            .mailingMetaData
                                                                            .winningDistribution >
                                                                            0 &&
                                                                          metric ===
                                                                            _vm.$_winningCriteria_display(
                                                                              _vm
                                                                                .results
                                                                                .mailingMetaData
                                                                                .winningCriteria
                                                                            )
                                                                            ? _c(
                                                                                "v-tooltip",
                                                                                {
                                                                                  attrs: {
                                                                                    top:
                                                                                      ""
                                                                                  },
                                                                                  scopedSlots: _vm._u(
                                                                                    [
                                                                                      {
                                                                                        key:
                                                                                          "activator",
                                                                                        fn: function(
                                                                                          ref
                                                                                        ) {
                                                                                          var on =
                                                                                            ref.on
                                                                                          return [
                                                                                            _c(
                                                                                              "v-icon",
                                                                                              _vm._g(
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "mr-2 mb-1",
                                                                                                  attrs: {
                                                                                                    color:
                                                                                                      _vm.$_splitMixin_winningColor
                                                                                                  }
                                                                                                },
                                                                                                on
                                                                                              ),
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                    mdi-crown\n                                  "
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ]
                                                                                        }
                                                                                      }
                                                                                    ],
                                                                                    null,
                                                                                    true
                                                                                  )
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Winning Criteria"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm._v(
                                                                            "\n                              " +
                                                                              _vm._s(
                                                                                metric
                                                                              ) +
                                                                              "\n                            "
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  )
                                                                ],
                                                                2
                                                              ),
                                                              _vm.results
                                                                .mailingMetaData
                                                                .splits.length >
                                                              1
                                                                ? _c(
                                                                    "tr",
                                                                    [
                                                                      _c(
                                                                        "th",
                                                                        {
                                                                          staticClass:
                                                                            "text-left primary--text font-weight-bold text-subtitle-2 eng-summ-table-th",
                                                                          style: [
                                                                            {
                                                                              color:
                                                                                "#6d2c8a",
                                                                              "text-align":
                                                                                "left"
                                                                            }
                                                                          ]
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            Date\n                            "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._l(
                                                                        _vm.engagementMetricsComputed,
                                                                        function(
                                                                          metrics,
                                                                          n
                                                                        ) {
                                                                          return [
                                                                            _vm._l(
                                                                              _vm
                                                                                .results
                                                                                .mailingMetaData
                                                                                .splits,
                                                                              function(
                                                                                split,
                                                                                i
                                                                              ) {
                                                                                return [
                                                                                  _c(
                                                                                    "th",
                                                                                    {
                                                                                      key:
                                                                                        n +
                                                                                        "-" +
                                                                                        i,
                                                                                      staticClass:
                                                                                        "text-right primary--text font-weight-bold text-subtitle-2 eng-summ-table-th",
                                                                                      class: {
                                                                                        "table-border-left-bold":
                                                                                          i ===
                                                                                          0
                                                                                      },
                                                                                      style: [
                                                                                        {
                                                                                          color:
                                                                                            "#6d2c8a",
                                                                                          "text-align":
                                                                                            "right"
                                                                                        }
                                                                                      ]
                                                                                    },
                                                                                    [
                                                                                      !_vm.pdfMenu
                                                                                        ? _c(
                                                                                            "v-icon",
                                                                                            {
                                                                                              attrs: {
                                                                                                color:
                                                                                                  _vm
                                                                                                    .engagementMetricsDataSplits
                                                                                                    .datasets[
                                                                                                    i *
                                                                                                      _vm
                                                                                                        .engagementMetricsComputed
                                                                                                        .length +
                                                                                                      n
                                                                                                  ]
                                                                                                    .backgroundColor
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                    mdi-square-rounded\n                                  "
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _vm._v(
                                                                                        "\n                                  " +
                                                                                          _vm._s(
                                                                                            split.splitLetter
                                                                                          ) +
                                                                                          "\n                                "
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ]
                                                                              }
                                                                            ),
                                                                            _vm
                                                                              .results
                                                                              .mailingMetaData
                                                                              .winningDistribution >
                                                                            0
                                                                              ? _c(
                                                                                  "th",
                                                                                  {
                                                                                    key:
                                                                                      "w-" +
                                                                                      n,
                                                                                    staticClass:
                                                                                      "text-right primary--text font-weight-bold text-subtitle-2 eng-summ-table-th",
                                                                                    style: [
                                                                                      {
                                                                                        color:
                                                                                          "#6d2c8a",
                                                                                        "text-align":
                                                                                          "right"
                                                                                      }
                                                                                    ]
                                                                                  },
                                                                                  [
                                                                                    !_vm.pdfMenu
                                                                                      ? _c(
                                                                                          "v-icon",
                                                                                          {
                                                                                            attrs: {
                                                                                              color:
                                                                                                _vm
                                                                                                  .engagementMetricsDataWinner
                                                                                                  .datasets[
                                                                                                  n
                                                                                                ]
                                                                                                  .backgroundColor
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                  mdi-square-rounded\n                                "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    _vm._v(
                                                                                      "\n                                " +
                                                                                        _vm._s(
                                                                                          _vm.results.winnerLabel.replace(
                                                                                            "Winner",
                                                                                            "W"
                                                                                          )
                                                                                        ) +
                                                                                        "\n                              "
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e(),
                                                                            _c(
                                                                              "th",
                                                                              {
                                                                                key:
                                                                                  "t-" +
                                                                                  n,
                                                                                staticClass:
                                                                                  "text-right primary--text font-weight-bold text-subtitle-2 table-border-left eng-summ-table-th",
                                                                                style: [
                                                                                  {
                                                                                    color:
                                                                                      "#6d2c8a",
                                                                                    "text-align":
                                                                                      "right"
                                                                                  }
                                                                                ]
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                Total\n                              "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      )
                                                                    ],
                                                                    2
                                                                  )
                                                                : _vm._e()
                                                            ]),
                                                            _c(
                                                              "tbody",
                                                              [
                                                                _vm._l(
                                                                  _vm
                                                                    .engagementMetricsDataSplits
                                                                    .labels,
                                                                  function(
                                                                    label,
                                                                    i
                                                                  ) {
                                                                    return _c(
                                                                      "tr",
                                                                      {
                                                                        key:
                                                                          "" +
                                                                          label,
                                                                        staticStyle: {
                                                                          display:
                                                                            "none"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-left primary--text font-weight-bold text-subtitle-2"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              " +
                                                                                _vm._s(
                                                                                  label
                                                                                ) +
                                                                                "\n                            "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._l(
                                                                          _vm.engagementMetricsComputed,
                                                                          function(
                                                                            metric
                                                                          ) {
                                                                            return [
                                                                              _vm._l(
                                                                                _vm
                                                                                  .results
                                                                                  .mailingMetaData
                                                                                  .splits,
                                                                                function(
                                                                                  split,
                                                                                  splitIndex
                                                                                ) {
                                                                                  return _c(
                                                                                    "td",
                                                                                    {
                                                                                      key:
                                                                                        metric +
                                                                                        "-" +
                                                                                        split.splitLetter +
                                                                                        "-" +
                                                                                        i,
                                                                                      staticClass:
                                                                                        "text-right",
                                                                                      class: {
                                                                                        "table-border-left-bold":
                                                                                          splitIndex ===
                                                                                          0
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                " +
                                                                                          _vm._s(
                                                                                            _vm._f(
                                                                                              "commaNumber"
                                                                                            )(
                                                                                              _vm.engagementMetricsDataSplits.datasets.find(
                                                                                                function(
                                                                                                  x
                                                                                                ) {
                                                                                                  return (
                                                                                                    x.label ===
                                                                                                    split.splitLetter +
                                                                                                      " - " +
                                                                                                      metric
                                                                                                  )
                                                                                                }
                                                                                              )
                                                                                                .data[
                                                                                                i
                                                                                              ]
                                                                                            )
                                                                                          ) +
                                                                                          "\n                              "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              ),
                                                                              _vm
                                                                                .results
                                                                                .mailingMetaData
                                                                                .winningDistribution >
                                                                              0
                                                                                ? _c(
                                                                                    "td",
                                                                                    {
                                                                                      key:
                                                                                        "w-" +
                                                                                        metric,
                                                                                      staticClass:
                                                                                        "text-right"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                " +
                                                                                          _vm._s(
                                                                                            _vm._f(
                                                                                              "commaNumber"
                                                                                            )(
                                                                                              _vm.engagementMetricsDataWinner.datasets.find(
                                                                                                function(
                                                                                                  x
                                                                                                ) {
                                                                                                  return (
                                                                                                    x.label ===
                                                                                                    _vm
                                                                                                      .results
                                                                                                      .winnerLabel +
                                                                                                      " - " +
                                                                                                      metric
                                                                                                  )
                                                                                                }
                                                                                              )
                                                                                                .data[
                                                                                                i
                                                                                              ]
                                                                                            )
                                                                                          ) +
                                                                                          "\n                              "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm
                                                                                .results
                                                                                .mailingMetaData
                                                                                .splits
                                                                                .length >
                                                                              1
                                                                                ? _c(
                                                                                    "td",
                                                                                    {
                                                                                      key:
                                                                                        "t-" +
                                                                                        metric,
                                                                                      staticClass:
                                                                                        "text-right font-weight-bold table-border-left"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                " +
                                                                                          _vm._s(
                                                                                            _vm._f(
                                                                                              "commaNumber"
                                                                                            )(
                                                                                              _vm.getTotalRowForEngagement(
                                                                                                i,
                                                                                                metric
                                                                                              )
                                                                                            )
                                                                                          ) +
                                                                                          "\n                              "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e()
                                                                            ]
                                                                          }
                                                                        )
                                                                      ],
                                                                      2
                                                                    )
                                                                  }
                                                                ),
                                                                _c(
                                                                  "tr",
                                                                  [
                                                                    _c(
                                                                      "td",
                                                                      {
                                                                        staticClass:
                                                                          "text-left primary--text font-weight-bold text-subtitle-2 table-border-top-bold eng-summ-table-row-top-border",
                                                                        style: [
                                                                          {
                                                                            color:
                                                                              "#6d2c8a",
                                                                            "text-align":
                                                                              "left"
                                                                          }
                                                                        ]
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                              Total\n                            "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._l(
                                                                      _vm.engagementMetricsComputed,
                                                                      function(
                                                                        metric
                                                                      ) {
                                                                        return [
                                                                          _vm._l(
                                                                            _vm
                                                                              .results
                                                                              .mailingMetaData
                                                                              .splits,
                                                                            function(
                                                                              split,
                                                                              splitIndex
                                                                            ) {
                                                                              return _c(
                                                                                "td",
                                                                                {
                                                                                  key:
                                                                                    "t-" +
                                                                                    metric +
                                                                                    "-" +
                                                                                    split.splitLetter,
                                                                                  staticClass:
                                                                                    "text-right table-border-top-bold font-weight-bold eng-summ-table-row-top-border",
                                                                                  class: {
                                                                                    "table-border-left-bold":
                                                                                      splitIndex ===
                                                                                      0
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "commaNumber"
                                                                                        )(
                                                                                          _vm.engagementMetricsDataSplits.datasets
                                                                                            .find(
                                                                                              function(
                                                                                                x
                                                                                              ) {
                                                                                                return (
                                                                                                  x.label ===
                                                                                                  split.splitLetter +
                                                                                                    " - " +
                                                                                                    metric
                                                                                                )
                                                                                              }
                                                                                            )
                                                                                            .data.reduce(
                                                                                              function(
                                                                                                prev,
                                                                                                next
                                                                                              ) {
                                                                                                return (
                                                                                                  prev +
                                                                                                  next
                                                                                                )
                                                                                              }
                                                                                            )
                                                                                        )
                                                                                      ) +
                                                                                      "\n                              "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            }
                                                                          ),
                                                                          _vm
                                                                            .results
                                                                            .mailingMetaData
                                                                            .winningDistribution >
                                                                          0
                                                                            ? _c(
                                                                                "td",
                                                                                {
                                                                                  key:
                                                                                    "w-" +
                                                                                    metric,
                                                                                  staticClass:
                                                                                    "text-right table-border-top-bold font-weight-bold eng-summ-table-row-top-border"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "commaNumber"
                                                                                        )(
                                                                                          _vm.engagementMetricsDataWinner.datasets
                                                                                            .find(
                                                                                              function(
                                                                                                x
                                                                                              ) {
                                                                                                return (
                                                                                                  x.label ===
                                                                                                  _vm
                                                                                                    .results
                                                                                                    .winnerLabel +
                                                                                                    " - " +
                                                                                                    metric
                                                                                                )
                                                                                              }
                                                                                            )
                                                                                            .data.reduce(
                                                                                              function(
                                                                                                prev,
                                                                                                next
                                                                                              ) {
                                                                                                return (
                                                                                                  prev +
                                                                                                  next
                                                                                                )
                                                                                              }
                                                                                            )
                                                                                        )
                                                                                      ) +
                                                                                      "\n                              "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm
                                                                            .results
                                                                            .mailingMetaData
                                                                            .splits
                                                                            .length >
                                                                          1
                                                                            ? _c(
                                                                                "td",
                                                                                {
                                                                                  key:
                                                                                    "tt-" +
                                                                                    metric,
                                                                                  staticClass:
                                                                                    "text-right table-border-top-bold font-weight-bold table-border-left eng-summ-table-head-total"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "commaNumber"
                                                                                        )(
                                                                                          _vm.getTotalTableForEngagement(
                                                                                            metric
                                                                                          )
                                                                                        )
                                                                                      ) +
                                                                                      "\n                              "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _vm._e()
                                                                        ]
                                                                      }
                                                                    )
                                                                  ],
                                                                  2
                                                                ),
                                                                _c(
                                                                  "tr",
                                                                  [
                                                                    _c(
                                                                      "td",
                                                                      {
                                                                        staticClass:
                                                                          "text-left primary--text font-weight-bold text-subtitle-2 eng-summ-table-th",
                                                                        style: [
                                                                          {
                                                                            color:
                                                                              "#6d2c8a",
                                                                            "text-align":
                                                                              "left"
                                                                          }
                                                                        ]
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                              %\n                            "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm.results
                                                                      .mailingMetaData
                                                                      .splits
                                                                      .length >
                                                                      1 &&
                                                                    _vm.pdfMenu
                                                                      ? [
                                                                          _vm._l(
                                                                            _vm.engagementMetricsComputed,
                                                                            function(
                                                                              metric
                                                                            ) {
                                                                              return [
                                                                                _vm._l(
                                                                                  _vm
                                                                                    .results
                                                                                    .mailingMetaData
                                                                                    .splits,
                                                                                  function(
                                                                                    split,
                                                                                    splitIndex
                                                                                  ) {
                                                                                    return _c(
                                                                                      "td",
                                                                                      {
                                                                                        key:
                                                                                          "%-" +
                                                                                          metric +
                                                                                          "-" +
                                                                                          splitIndex,
                                                                                        staticClass:
                                                                                          "text-right font-weight-bold eng-summ-table-td-total",
                                                                                        class: {
                                                                                          "table-border-left-bold":
                                                                                            splitIndex ===
                                                                                            0
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              _vm._f(
                                                                                                "percent"
                                                                                              )(
                                                                                                _vm.getEngagementPercent(
                                                                                                  _vm
                                                                                                    .engagementMetricsDataSplits
                                                                                                    .datasets,
                                                                                                  split.splitLetter +
                                                                                                    " - " +
                                                                                                    metric,
                                                                                                  _vm
                                                                                                    .results
                                                                                                    .splitData
                                                                                                    .engagementSummary[
                                                                                                    splitIndex
                                                                                                  ]
                                                                                                    .deliveredMessages
                                                                                                ),
                                                                                                0
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  }
                                                                                ),
                                                                                _vm
                                                                                  .results
                                                                                  .mailingMetaData
                                                                                  .winningDistribution >
                                                                                0
                                                                                  ? _c(
                                                                                      "td",
                                                                                      {
                                                                                        key:
                                                                                          "w-" +
                                                                                          metric,
                                                                                        staticClass:
                                                                                          "text-right font-weight-bold eng-summ-table-td-total"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              _vm._f(
                                                                                                "percent"
                                                                                              )(
                                                                                                _vm.getEngagementPercent(
                                                                                                  _vm
                                                                                                    .engagementMetricsDataWinner
                                                                                                    .datasets,
                                                                                                  _vm
                                                                                                    .results
                                                                                                    .winnerLabel +
                                                                                                    " - " +
                                                                                                    metric,
                                                                                                  _vm
                                                                                                    .results
                                                                                                    .winnerData
                                                                                                    .engagementSummary[0]
                                                                                                    .deliveredMessages
                                                                                                ),
                                                                                                0
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _vm
                                                                                  .results
                                                                                  .mailingMetaData
                                                                                  .splits
                                                                                  .length >
                                                                                1
                                                                                  ? _c(
                                                                                      "td",
                                                                                      {
                                                                                        key:
                                                                                          "tt-" +
                                                                                          metric,
                                                                                        staticClass:
                                                                                          "text-right font-weight-bold table-border-left eng-summ-table-bottom-total"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              _vm._f(
                                                                                                "percent"
                                                                                              )(
                                                                                                _vm.getTotalTableForEngagementPercent(
                                                                                                  metric
                                                                                                ),
                                                                                                0
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e()
                                                                              ]
                                                                            }
                                                                          )
                                                                        ]
                                                                      : [
                                                                          _vm._l(
                                                                            _vm.engagementMetricsComputed,
                                                                            function(
                                                                              metric
                                                                            ) {
                                                                              return [
                                                                                _vm._l(
                                                                                  _vm
                                                                                    .results
                                                                                    .mailingMetaData
                                                                                    .splits,
                                                                                  function(
                                                                                    split,
                                                                                    splitIndex
                                                                                  ) {
                                                                                    return _c(
                                                                                      "td",
                                                                                      {
                                                                                        key:
                                                                                          "%-" +
                                                                                          metric +
                                                                                          "-" +
                                                                                          splitIndex,
                                                                                        staticClass:
                                                                                          "text-right font-weight-bold",
                                                                                        class: {
                                                                                          "table-border-left-bold":
                                                                                            splitIndex ===
                                                                                            0
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              _vm._f(
                                                                                                "percent"
                                                                                              )(
                                                                                                _vm.getEngagementPercent(
                                                                                                  _vm
                                                                                                    .engagementMetricsDataSplits
                                                                                                    .datasets,
                                                                                                  split.splitLetter +
                                                                                                    " - " +
                                                                                                    metric,
                                                                                                  _vm
                                                                                                    .results
                                                                                                    .splitData
                                                                                                    .engagementSummary[
                                                                                                    splitIndex
                                                                                                  ]
                                                                                                    .deliveredMessages
                                                                                                ),
                                                                                                2
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  }
                                                                                ),
                                                                                _vm
                                                                                  .results
                                                                                  .mailingMetaData
                                                                                  .winningDistribution >
                                                                                0
                                                                                  ? _c(
                                                                                      "td",
                                                                                      {
                                                                                        key:
                                                                                          "w-" +
                                                                                          metric,
                                                                                        staticClass:
                                                                                          "text-right font-weight-bold"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              _vm._f(
                                                                                                "percent"
                                                                                              )(
                                                                                                _vm.getEngagementPercent(
                                                                                                  _vm
                                                                                                    .engagementMetricsDataWinner
                                                                                                    .datasets,
                                                                                                  _vm
                                                                                                    .results
                                                                                                    .winnerLabel +
                                                                                                    " - " +
                                                                                                    metric,
                                                                                                  _vm
                                                                                                    .results
                                                                                                    .winnerData
                                                                                                    .engagementSummary[0]
                                                                                                    .deliveredMessages
                                                                                                ),
                                                                                                2
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _vm
                                                                                  .results
                                                                                  .mailingMetaData
                                                                                  .splits
                                                                                  .length >
                                                                                1
                                                                                  ? _c(
                                                                                      "td",
                                                                                      {
                                                                                        key:
                                                                                          "tt-" +
                                                                                          metric,
                                                                                        staticClass:
                                                                                          "text-right font-weight-bold table-border-left"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              _vm._f(
                                                                                                "percent"
                                                                                              )(
                                                                                                _vm.getTotalTableForEngagementPercent(
                                                                                                  metric
                                                                                                ),
                                                                                                2
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e()
                                                                              ]
                                                                            }
                                                                          )
                                                                        ]
                                                                  ],
                                                                  2
                                                                )
                                                              ],
                                                              2
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    1702797743
                                                  )
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("v-divider", { staticClass: "my-4" }),
                                _c("div", {
                                  staticClass: "html2pdf__page-break"
                                }),
                                _c(
                                  "section",
                                  { staticClass: "title-bar" },
                                  [
                                    _c(
                                      "v-card-subtitle",
                                      {
                                        staticClass:
                                          "grey white--text font-weight-bold text-h5 pa-2 mb-4 title-bar-text",
                                        staticStyle: { display: "flex" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                Reading Engagement\n                "
                                        ),
                                        _c("v-spacer"),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var onTooltip = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: !_vm.pdfMenu,
                                                                expression:
                                                                  "!pdfMenu"
                                                              }
                                                            ],
                                                            attrs: {
                                                              icon: "",
                                                              color: "white"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.$_downloadFileMixin_downloadReportChart(
                                                                  _vm.results
                                                                    .readingEnvironmentsChart,
                                                                  "%",
                                                                  _vm.results
                                                                    .mailingMetaData
                                                                    .campaignNowwOrderNumber +
                                                                    "-" +
                                                                    _vm.results.mailingMetaData.name.replace(
                                                                      / /g,
                                                                      ""
                                                                    ) +
                                                                    "-ReadingEnvironments"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          onTooltip
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "green lighten-3"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        mdi-file-download\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              4029061413
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Download Reading Environments"
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var onTooltip = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: !_vm.pdfMenu,
                                                                expression:
                                                                  "!pdfMenu"
                                                              }
                                                            ],
                                                            attrs: {
                                                              icon: "",
                                                              color: "white"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.$_downloadFileMixin_downloadReportChart(
                                                                  _vm.results
                                                                    .emailClientDeviceSummary,
                                                                  "%",
                                                                  _vm.results
                                                                    .mailingMetaData
                                                                    .campaignNowwOrderNumber +
                                                                    "-" +
                                                                    _vm.results.mailingMetaData.name.replace(
                                                                      / /g,
                                                                      ""
                                                                    ) +
                                                                    "-EmailClientsUsed"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          onTooltip
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "green lighten-3"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        mdi-file-download\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              950858345
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Download Email Clients Used"
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var onTooltip = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: !_vm.pdfMenu,
                                                                expression:
                                                                  "!pdfMenu"
                                                              }
                                                            ],
                                                            attrs: {
                                                              icon: "",
                                                              color: "white"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.$_downloadFileMixin_downloadReportChart(
                                                                  _vm.results
                                                                    .viewingEngagementTotalSummary,
                                                                  "%",
                                                                  _vm.results
                                                                    .mailingMetaData
                                                                    .campaignNowwOrderNumber +
                                                                    "-" +
                                                                    _vm.results.mailingMetaData.name.replace(
                                                                      / /g,
                                                                      ""
                                                                    ) +
                                                                    "-TimeSpentViewing"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          onTooltip
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "green lighten-3"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        mdi-file-download\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              1979730515
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Download Time Spent Viewing"
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm.results.mailingMetaData.splits
                                          .length > 1
                                          ? _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var onTooltip = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value: !_vm.pdfMenu,
                                                                    expression:
                                                                      "!pdfMenu"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  icon: "",
                                                                  color: "white"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.$_downloadFileMixin_downloadReportTable(
                                                                      "readingEngagement",
                                                                      _vm
                                                                        .results
                                                                        .mailingMetaData
                                                                        .campaignNowwOrderNumber +
                                                                        "-" +
                                                                        _vm.results.mailingMetaData.name.replace(
                                                                          / /g,
                                                                          ""
                                                                        ) +
                                                                        "-TimeSpentViewingTable"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              onTooltip
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "green lighten-3"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        mdi-file-download\n                      "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  2100540499
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Download Time Spent Viewing Table"
                                                  )
                                                ])
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.showAgeData
                                          ? _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var onTooltip = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value: !_vm.pdfMenu,
                                                                    expression:
                                                                      "!pdfMenu"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  icon: "",
                                                                  color: "white"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.$_downloadFileMixin_downloadReportChart(
                                                                      _vm
                                                                        .results
                                                                        .ageEngagementChart,
                                                                      "%",
                                                                      _vm
                                                                        .results
                                                                        .mailingMetaData
                                                                        .campaignNowwOrderNumber +
                                                                        "-" +
                                                                        _vm.results.mailingMetaData.name.replace(
                                                                          / /g,
                                                                          ""
                                                                        ) +
                                                                        "-AgeEngagement",
                                                                      true
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              onTooltip
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "green lighten-3"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        mdi-file-download\n                      "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  2131621087
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Download Age Engagement"
                                                  )
                                                ])
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm.validateReadingEngagement(
                                  _vm.results.splitData.viewingEngagementSummary
                                )
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-h5 font-weight-light",
                                        staticStyle: {
                                          color: "#8E24AA",
                                          "font-size": "24px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n              Crunching the numbers, check back tomorrow...\n            "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "section",
                                  { staticClass: "reading-eng" },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass: "pa-2 ma-2 dist-eng-charts"
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          { attrs: { "no-gutters": "" } },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c("line-chart", {
                                                  staticClass: "pb-2",
                                                  attrs: {
                                                    "chart-data":
                                                      _vm.results.splitData
                                                        .viewingEngagementSummary,
                                                    options: _vm.getChartOptionsLinePercent(
                                                      "Total Viewing Engagement",
                                                      "% of Open Audience",
                                                      "Time spent viewing (seconds)"
                                                    )
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                !_vm.pdfMenu
                                  ? _c("v-divider", { staticClass: "my-4" })
                                  : _vm._e(),
                                _c("div", {
                                  staticClass: "html2pdf__page-break"
                                }),
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          md: _vm.pdfMenu ? "6" : "4"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "pa-2 ma-2 dist-eng-charts"
                                          },
                                          [
                                            _c("bar-chart", {
                                              attrs: {
                                                "chart-data":
                                                  _vm.results
                                                    .readingEnvironmentsChart,
                                                options: _vm.getChartOptionsBarPercent(
                                                  "Reading Environments",
                                                  "% of Gross Opens",
                                                  "Environment"
                                                ),
                                                height: 350
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          md: _vm.pdfMenu ? "6" : "4"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          { staticClass: "pa-2 ma-2" },
                                          [
                                            _c("bar-chart", {
                                              attrs: {
                                                "chart-data":
                                                  _vm.results
                                                    .emailClientDeviceSummary,
                                                options: _vm.getChartOptionsBarPercent(
                                                  "Email Clients Used",
                                                  "% of Gross Opens",
                                                  "Email Client"
                                                ),
                                                height: 350
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          md: _vm.pdfMenu ? "6" : "4"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "pa-2 ma-2 dist-eng-charts",
                                            style: [
                                              _vm.pdfMenu
                                                ? { display: "inline-table" }
                                                : ""
                                            ]
                                          },
                                          [
                                            _c("bar-chart", {
                                              attrs: {
                                                "chart-data":
                                                  _vm.results
                                                    .viewingEngagementTotalSummary,
                                                options: _vm.getChartOptionsBarPercent(
                                                  "Time Spent Viewing",
                                                  "% of Gross Opens",
                                                  "Read Duration"
                                                ),
                                                height:
                                                  _vm.results.mailingMetaData
                                                    .splits.length > 1
                                                    ? 218
                                                    : 350
                                              }
                                            }),
                                            _vm.results.mailingMetaData.splits
                                              .length > 1
                                              ? _c("v-simple-table", {
                                                  staticClass: "px-8",
                                                  class: {
                                                    "pt-4": _vm.pdfMenu
                                                  },
                                                  staticStyle: { flex: "auto" },
                                                  attrs: {
                                                    id: "readingEngagement",
                                                    dense: ""
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function() {
                                                          return [
                                                            _c("thead", [
                                                              _c(
                                                                "tr",
                                                                [
                                                                  _c("th", {
                                                                    staticClass:
                                                                      "text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold reading-eng-th-text"
                                                                  }),
                                                                  _vm._l(
                                                                    _vm.results
                                                                      .splitData
                                                                      .litmusReadTable,
                                                                    function(
                                                                      split,
                                                                      i
                                                                    ) {
                                                                      return _c(
                                                                        "th",
                                                                        {
                                                                          key: i,
                                                                          staticClass:
                                                                            "text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold reading-eng-th-text",
                                                                          class: {
                                                                            "table-border-left-bold":
                                                                              i ===
                                                                              0
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .results
                                                                                  .mailingMetaData
                                                                                  .splits[
                                                                                  i
                                                                                ]
                                                                                  .splitLetter
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  _vm.results
                                                                    .mailingMetaData
                                                                    .winningDistribution >
                                                                  0
                                                                    ? _c(
                                                                        "th",
                                                                        {
                                                                          staticClass:
                                                                            "text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold reading-eng-th-text"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                _vm.results.winnerLabel.replace(
                                                                                  "Winner",
                                                                                  "W"
                                                                                )
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "th",
                                                                    {
                                                                      staticClass:
                                                                        "text-right primary--text font-weight-bold text-subtitle-2 table-border-left-bold table-border-bottom-bold reading-eng-th-text"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            Total\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                2
                                                              )
                                                            ]),
                                                            _c(
                                                              "tbody",
                                                              _vm._l(
                                                                _vm.readTimeMetrics,
                                                                function(
                                                                  metric,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "tr",
                                                                    {
                                                                      key: index
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "td",
                                                                        {
                                                                          staticClass:
                                                                            "text-left primary--text font-weight-bold text-subtitle-2 reading-eng-trtitle-text",
                                                                          style: {
                                                                            color:
                                                                              "#6d2c8a"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                color:
                                                                                  _vm
                                                                                    .results
                                                                                    .viewingEngagementTotalSummary
                                                                                    .datasets[0]
                                                                                    .backgroundColor[
                                                                                    index
                                                                                  ]
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                              mdi-square-rounded\n                            "
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                metric.display
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._l(
                                                                        _vm
                                                                          .results
                                                                          .splitData
                                                                          .litmusReadTable,
                                                                        function(
                                                                          litmusRow,
                                                                          litmusIndex
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "td",
                                                                              {
                                                                                key:
                                                                                  "" +
                                                                                  litmusIndex,
                                                                                staticClass:
                                                                                  "text-right reading-eng-td-row",
                                                                                class: {
                                                                                  "table-border-left-bold":
                                                                                    litmusIndex ===
                                                                                    0
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      _vm._f(
                                                                                        "percent"
                                                                                      )(
                                                                                        litmusRow.find(
                                                                                          function(
                                                                                            x
                                                                                          ) {
                                                                                            return (
                                                                                              x.device ==
                                                                                              "Total"
                                                                                            )
                                                                                          }
                                                                                        )[
                                                                                          metric
                                                                                            .rate
                                                                                        ]
                                                                                      )
                                                                                    ) +
                                                                                    "\n                            "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      ),
                                                                      _vm._l(
                                                                        _vm
                                                                          .results
                                                                          .winnerData
                                                                          .litmusReadTable,
                                                                        function(
                                                                          litmusRow,
                                                                          litmusIndex
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "td",
                                                                              {
                                                                                key:
                                                                                  "w-" +
                                                                                  litmusIndex,
                                                                                staticClass:
                                                                                  "text-right"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      _vm._f(
                                                                                        "percent"
                                                                                      )(
                                                                                        litmusRow.find(
                                                                                          function(
                                                                                            x
                                                                                          ) {
                                                                                            return (
                                                                                              x.device ==
                                                                                              "Total"
                                                                                            )
                                                                                          }
                                                                                        )[
                                                                                          metric
                                                                                            .rate
                                                                                        ]
                                                                                      )
                                                                                    ) +
                                                                                    "\n                            "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      ),
                                                                      _vm._l(
                                                                        _vm
                                                                          .results
                                                                          .litmusReadTable,
                                                                        function(
                                                                          litmusRow,
                                                                          litmusIndex
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "td",
                                                                              {
                                                                                key:
                                                                                  "t-" +
                                                                                  litmusIndex,
                                                                                staticClass:
                                                                                  "text-right font-weight-bold table-border-left-bold reading-eng-td-total"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      _vm._f(
                                                                                        "percent"
                                                                                      )(
                                                                                        litmusRow[
                                                                                          metric
                                                                                            .value
                                                                                        ]
                                                                                      )
                                                                                    ) +
                                                                                    "\n                            "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      )
                                                                    ],
                                                                    2
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    1257070767
                                                  )
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm.showAgeData || _vm.showLinkClicks
                                  ? _c("div", {
                                      staticClass: "html2pdf__page-break"
                                    })
                                  : _vm._e(),
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _vm.showAgeData
                                      ? _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass:
                                                  "pa-2 ma-2 dist-eng-charts"
                                              },
                                              [
                                                _c("bar-chart", {
                                                  staticClass: "pb-2",
                                                  attrs: {
                                                    "chart-data":
                                                      _vm.results
                                                        .ageEngagementChart,
                                                    options: _vm.getChartOptionsBarPercent2(
                                                      "Age Engagement",
                                                      "%",
                                                      "Age Range"
                                                    )
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _c("v-divider", { staticClass: "my-4" }),
                                _vm.showLinkClicks
                                  ? _c("div", {
                                      staticClass: "html2pdf__page-break"
                                    })
                                  : _vm._e(),
                                _vm.showLinkClicks
                                  ? [
                                      _c(
                                        "section",
                                        { staticClass: "title-bar" },
                                        [
                                          _c(
                                            "v-card-subtitle",
                                            {
                                              staticClass:
                                                "grey white--text font-weight-bold text-h5 pa-2 mb-4 title-bar-text",
                                              staticStyle: { display: "flex" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Link Clicks\n                  "
                                              ),
                                              _vm.results.areSplitLinksSame
                                                ? [
                                                    _c("v-spacer"),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var onTooltip =
                                                                  ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        directives: [
                                                                          {
                                                                            name:
                                                                              "show",
                                                                            rawName:
                                                                              "v-show",
                                                                            value: !_vm.pdfMenu,
                                                                            expression:
                                                                              "!pdfMenu"
                                                                          }
                                                                        ],
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          color:
                                                                            "white"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            _vm.$_downloadFileMixin_downloadReportTable(
                                                                              "linkClicksSame",
                                                                              _vm
                                                                                .results
                                                                                .mailingMetaData
                                                                                .campaignNowwOrderNumber +
                                                                                "-" +
                                                                                _vm.results.mailingMetaData.name.replace(
                                                                                  / /g,
                                                                                  ""
                                                                                ) +
                                                                                "-LinkClicks"
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      onTooltip
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "green lighten-3"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            mdi-file-download\n                          "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          false,
                                                          3515404604
                                                        )
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Download Link Clicks"
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                : [
                                                    _c("v-spacer"),
                                                    _vm._l(
                                                      _vm.results.splitData
                                                        .linkClicksSummary,
                                                      function(chart, i) {
                                                        return _c(
                                                          "v-tooltip",
                                                          {
                                                            key: i,
                                                            attrs: { top: "" },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var onTooltip =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            directives: [
                                                                              {
                                                                                name:
                                                                                  "show",
                                                                                rawName:
                                                                                  "v-show",
                                                                                value: !_vm.pdfMenu,
                                                                                expression:
                                                                                  "!pdfMenu"
                                                                              }
                                                                            ],
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              color:
                                                                                "white"
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                _vm.$_downloadFileMixin_downloadReportTable(
                                                                                  i +
                                                                                    "-linkClicks",
                                                                                  _vm
                                                                                    .results
                                                                                    .mailingMetaData
                                                                                    .campaignNowwOrderNumber +
                                                                                    "-" +
                                                                                    _vm.results.mailingMetaData.name.replace(
                                                                                      / /g,
                                                                                      ""
                                                                                    ) +
                                                                                    "-" +
                                                                                    _vm
                                                                                      .$_splitMixin_splitLetters[
                                                                                      i
                                                                                    ] +
                                                                                    "LinkClicks"
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          onTooltip
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                color:
                                                                                  "green lighten-3"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                            mdi-file-download\n                          "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Download " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .$_splitMixin_splitLetters[
                                                                      i
                                                                    ]
                                                                  ) +
                                                                  " Link Clicks"
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    _vm._l(
                                                      _vm.results.winnerData
                                                        .linkClicksSummary,
                                                      function(chart, i) {
                                                        return _c(
                                                          "v-tooltip",
                                                          {
                                                            key: "w-" + i,
                                                            attrs: { top: "" },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var onTooltip =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            directives: [
                                                                              {
                                                                                name:
                                                                                  "show",
                                                                                rawName:
                                                                                  "v-show",
                                                                                value: !_vm.pdfMenu,
                                                                                expression:
                                                                                  "!pdfMenu"
                                                                              }
                                                                            ],
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              color:
                                                                                "white"
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                _vm.$_downloadFileMixin_downloadReportTable(
                                                                                  i +
                                                                                    "-linkClicks",
                                                                                  _vm
                                                                                    .results
                                                                                    .mailingMetaData
                                                                                    .campaignNowwOrderNumber +
                                                                                    "-" +
                                                                                    _vm.results.mailingMetaData.name.replace(
                                                                                      / /g,
                                                                                      ""
                                                                                    ) +
                                                                                    "-" +
                                                                                    _vm.results.winnerLabel
                                                                                      .replace(
                                                                                        / /g,
                                                                                        ""
                                                                                      )
                                                                                      .replace(
                                                                                        "(",
                                                                                        ""
                                                                                      )
                                                                                      .replace(
                                                                                        ")",
                                                                                        ""
                                                                                      ) +
                                                                                    "LinkClicks"
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          onTooltip
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                color:
                                                                                  "green lighten-3"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                            mdi-file-download\n                          "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Download " +
                                                                  _vm._s(
                                                                    _vm.results
                                                                      .winnerLabel
                                                                  ) +
                                                                  " Link Clicks"
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      }
                                                    )
                                                  ]
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _vm.results.areSplitLinksSame
                                            ? [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c(
                                                      "v-card",
                                                      {
                                                        staticClass:
                                                          "pa-2 ma-2 dist-eng-charts"
                                                      },
                                                      [
                                                        _c("bar-chart", {
                                                          attrs: {
                                                            "chart-data":
                                                              _vm.results
                                                                .linkClicksSummarySame,
                                                            options: _vm.getChartOptionsBarPercent2(
                                                              "Link Click Summary",
                                                              "Link Click % of Delivered Messages ",
                                                              "Link Name"
                                                            )
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c(
                                                      "v-card",
                                                      {
                                                        staticClass: "pa-2 ma-2"
                                                      },
                                                      [
                                                        _c(
                                                          "section",
                                                          {
                                                            staticClass:
                                                              "link-click-table"
                                                          },
                                                          [
                                                            _c(
                                                              "v-simple-table",
                                                              {
                                                                staticStyle: {
                                                                  "table-layout":
                                                                    "fixed",
                                                                  width: "100%"
                                                                },
                                                                attrs: {
                                                                  id:
                                                                    "linkClicksSame",
                                                                  dense: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function() {
                                                                        return [
                                                                          _c(
                                                                            "thead",
                                                                            [
                                                                              _c(
                                                                                "tr",
                                                                                [
                                                                                  _c(
                                                                                    "th",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-left primary--text font-weight-bold text-subtitle-2 link-click-table-th",
                                                                                      style: [
                                                                                        {
                                                                                          color:
                                                                                            "#6d2c8a",
                                                                                          "text-align":
                                                                                            "left"
                                                                                        }
                                                                                      ]
                                                                                    }
                                                                                  ),
                                                                                  _vm._l(
                                                                                    _vm
                                                                                      .results
                                                                                      .splitData
                                                                                      .linkClicksSummary,
                                                                                    function(
                                                                                      links,
                                                                                      linksIndex
                                                                                    ) {
                                                                                      return _c(
                                                                                        "th",
                                                                                        {
                                                                                          key: linksIndex,
                                                                                          staticClass:
                                                                                            "text-right primary--text font-weight-bold text-subtitle-2 table-border-left link-click-table-th",
                                                                                          class: {
                                                                                            "table-border-left-bold":
                                                                                              linksIndex ===
                                                                                              0
                                                                                          },
                                                                                          style: [
                                                                                            {
                                                                                              color:
                                                                                                "#6d2c8a",
                                                                                              "text-align":
                                                                                                "right",
                                                                                              "border-left":
                                                                                                "2px solid rgba(0, 0, 0, 0.36)"
                                                                                            }
                                                                                          ],
                                                                                          attrs: {
                                                                                            colspan:
                                                                                              "2"
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                  " +
                                                                                              _vm._s(
                                                                                                _vm
                                                                                                  .results
                                                                                                  .mailingMetaData
                                                                                                  .winningDistribution >
                                                                                                  0 &&
                                                                                                  linksIndex ===
                                                                                                    _vm
                                                                                                      .results
                                                                                                      .splitData
                                                                                                      .linkClicksSummary
                                                                                                      .length -
                                                                                                      1
                                                                                                  ? _vm
                                                                                                      .results
                                                                                                      .winnerLabel
                                                                                                  : _vm
                                                                                                      .$_splitMixin_splitLetters[
                                                                                                      linksIndex
                                                                                                    ]
                                                                                              ) +
                                                                                              " Clicks\n                                "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                  _vm
                                                                                    .results
                                                                                    .splitData
                                                                                    .linkClicksSummary
                                                                                    .length >
                                                                                  1
                                                                                    ? _c(
                                                                                        "th",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text-right primary--text font-weight-bold text-subtitle-2 table-border-left-bold link-click-table-th",
                                                                                          style: [
                                                                                            {
                                                                                              color:
                                                                                                "#6d2c8a",
                                                                                              "text-align":
                                                                                                "right",
                                                                                              "border-left":
                                                                                                "2px solid rgba(0, 0, 0, 0.36)"
                                                                                            }
                                                                                          ],
                                                                                          attrs: {
                                                                                            colspan:
                                                                                              "2"
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                  Total Clicks\n                                "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  _c(
                                                                                    "th",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-left primary--text font-weight-bold text-subtitle-2 table-border-left-bold link-click-table-th",
                                                                                      style: [
                                                                                        {
                                                                                          color:
                                                                                            "#6d2c8a",
                                                                                          "text-align":
                                                                                            "left",
                                                                                          "border-left":
                                                                                            "2px solid rgba(0, 0, 0, 0.36)"
                                                                                        }
                                                                                      ]
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _c(
                                                                                "tr",
                                                                                [
                                                                                  _c(
                                                                                    "th",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold link-click-table-th",
                                                                                      style: [
                                                                                        {
                                                                                          color:
                                                                                            "#6d2c8a",
                                                                                          "text-align":
                                                                                            "left",
                                                                                          "border-bottom":
                                                                                            "2px solid rgba(0, 0, 0, 0.36)"
                                                                                        }
                                                                                      ]
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                  Link Name\n                                "
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._l(
                                                                                    _vm
                                                                                      .results
                                                                                      .splitData
                                                                                      .linkClicksSummary,
                                                                                    function(
                                                                                      links,
                                                                                      linksIndex
                                                                                    ) {
                                                                                      return [
                                                                                        _c(
                                                                                          "th",
                                                                                          {
                                                                                            key:
                                                                                              "g-" +
                                                                                              linksIndex,
                                                                                            staticClass:
                                                                                              "text-right primary--text font-weight-bold text-subtitle-2 table-border-left table-border-bottom-bold link-click-table-th",
                                                                                            class: {
                                                                                              "table-border-left-bold":
                                                                                                linksIndex ===
                                                                                                0
                                                                                            },
                                                                                            style: [
                                                                                              {
                                                                                                color:
                                                                                                  "#6d2c8a",
                                                                                                "text-align":
                                                                                                  "right",
                                                                                                "border-bottom":
                                                                                                  "2px solid rgba(0, 0, 0, 0.36)",
                                                                                                "border-left":
                                                                                                  "2px solid rgba(0, 0, 0, 0.36)"
                                                                                              }
                                                                                            ]
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                    Gross\n                                  "
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "th",
                                                                                          {
                                                                                            key:
                                                                                              "u-" +
                                                                                              linksIndex,
                                                                                            staticClass:
                                                                                              "text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold link-click-table-th",
                                                                                            style: [
                                                                                              {
                                                                                                color:
                                                                                                  "#6d2c8a",
                                                                                                "text-align":
                                                                                                  "right",
                                                                                                "border-bottom":
                                                                                                  "2px solid rgba(0, 0, 0, 0.36)"
                                                                                              }
                                                                                            ]
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                    Unique\n                                  "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    }
                                                                                  ),
                                                                                  _vm
                                                                                    .results
                                                                                    .splitData
                                                                                    .linkClicksSummary
                                                                                    .length >
                                                                                  1
                                                                                    ? _c(
                                                                                        "th",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text-right primary--text font-weight-bold text-subtitle-2 table-border-left-bold table-border-bottom-bold link-click-table-th",
                                                                                          style: [
                                                                                            {
                                                                                              color:
                                                                                                "#6d2c8a",
                                                                                              "text-align":
                                                                                                "right",
                                                                                              "border-bottom":
                                                                                                "2px solid rgba(0, 0, 0, 0.36)",
                                                                                              "border-left":
                                                                                                "2px solid rgba(0, 0, 0, 0.36)"
                                                                                            }
                                                                                          ]
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                  Gross\n                                "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  _vm
                                                                                    .results
                                                                                    .splitData
                                                                                    .linkClicksSummary
                                                                                    .length >
                                                                                  1
                                                                                    ? _c(
                                                                                        "th",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold link-click-table-th",
                                                                                          style: [
                                                                                            {
                                                                                              color:
                                                                                                "#6d2c8a",
                                                                                              "text-align":
                                                                                                "right",
                                                                                              "border-bottom":
                                                                                                "2px solid rgba(0, 0, 0, 0.36)"
                                                                                            }
                                                                                          ]
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                  Unique\n                                "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  _c(
                                                                                    "th",
                                                                                    {
                                                                                      directives: [
                                                                                        {
                                                                                          name:
                                                                                            "show",
                                                                                          rawName:
                                                                                            "v-show",
                                                                                          value: !_vm.pdfMenu,
                                                                                          expression:
                                                                                            "!pdfMenu"
                                                                                        }
                                                                                      ],
                                                                                      staticClass:
                                                                                        "text-left primary--text font-weight-bold text-subtitle-2 table-border-left-bold table-border-bottom-bold link-click-table-th",
                                                                                      style: [
                                                                                        {
                                                                                          color:
                                                                                            "#6d2c8a",
                                                                                          "text-align":
                                                                                            "left",
                                                                                          "border-bottom":
                                                                                            "2px solid rgba(0, 0, 0, 0.36)",
                                                                                          "border-left":
                                                                                            "2px solid rgba(0, 0, 0, 0.36)"
                                                                                        }
                                                                                      ]
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                  Url\n                                "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                2
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "tbody",
                                                                            {
                                                                              style: [
                                                                                _vm.pdfMenu
                                                                                  ? {
                                                                                      "word-break":
                                                                                        "break-all"
                                                                                    }
                                                                                  : ""
                                                                              ]
                                                                            },
                                                                            _vm._l(
                                                                              Object.entries(
                                                                                _vm
                                                                                  .results
                                                                                  .linkNameToUrlSame
                                                                              ),
                                                                              function(
                                                                                keyValue,
                                                                                i
                                                                              ) {
                                                                                return _c(
                                                                                  "tr",
                                                                                  {
                                                                                    key:
                                                                                      keyValue[0]
                                                                                  },
                                                                                  [
                                                                                    _vm.pdfMenu
                                                                                      ? [
                                                                                          _c(
                                                                                            "td",
                                                                                            {
                                                                                              style: {
                                                                                                "margin-left":
                                                                                                  "10px"
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "a",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    href:
                                                                                                      keyValue[1],
                                                                                                    target:
                                                                                                      "_blank"
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                      " +
                                                                                                      _vm._s(
                                                                                                        keyValue[0]
                                                                                                      ) +
                                                                                                      "\n                                    "
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ]
                                                                                      : [
                                                                                          _c(
                                                                                            "td",
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  keyValue[0]
                                                                                                )
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                    _vm._l(
                                                                                      _vm
                                                                                        .results
                                                                                        .linkClicksSummarySame
                                                                                        .datasets2,
                                                                                      function(
                                                                                        links,
                                                                                        linksIndex
                                                                                      ) {
                                                                                        return [
                                                                                          _c(
                                                                                            "td",
                                                                                            {
                                                                                              key:
                                                                                                "gu-" +
                                                                                                linksIndex,
                                                                                              staticClass:
                                                                                                "text-right link-click-table-tb-light",
                                                                                              class: {
                                                                                                "table-border-left":
                                                                                                  linksIndex %
                                                                                                    2 ===
                                                                                                  0,
                                                                                                "table-border-left-bold":
                                                                                                  linksIndex ===
                                                                                                  0
                                                                                              },
                                                                                              style: {
                                                                                                "border-left":
                                                                                                  "2px solid rgba(0, 0, 0, 0.36)"
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                    " +
                                                                                                  _vm._s(
                                                                                                    _vm._f(
                                                                                                      "commaNumber"
                                                                                                    )(
                                                                                                      links
                                                                                                        .data[
                                                                                                        i
                                                                                                      ]
                                                                                                    )
                                                                                                  ) +
                                                                                                  "\n                                  "
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ]
                                                                                      }
                                                                                    ),
                                                                                    _vm
                                                                                      .results
                                                                                      .linkClicksSummarySame
                                                                                      .datasets2
                                                                                      .length >
                                                                                    2
                                                                                      ? _c(
                                                                                          "td",
                                                                                          {
                                                                                            staticClass:
                                                                                              "text-right table-border-left-bold font-weight-bold link-click-table-tb",
                                                                                            style: {
                                                                                              "border-left":
                                                                                                "2px solid rgba(0, 0, 0, 0.36)"
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                  " +
                                                                                                _vm._s(
                                                                                                  _vm.results.linkClicksSummarySame.datasets2
                                                                                                    .filter(
                                                                                                      function(
                                                                                                        element,
                                                                                                        index,
                                                                                                        array
                                                                                                      ) {
                                                                                                        return (
                                                                                                          index %
                                                                                                            2 ===
                                                                                                          0
                                                                                                        )
                                                                                                      }
                                                                                                    )
                                                                                                    .reduce(
                                                                                                      function(
                                                                                                        accumulator,
                                                                                                        current
                                                                                                      ) {
                                                                                                        return (
                                                                                                          accumulator +
                                                                                                          current
                                                                                                            .data[
                                                                                                            i
                                                                                                          ]
                                                                                                        )
                                                                                                      },
                                                                                                      0
                                                                                                    )
                                                                                                ) +
                                                                                                "\n                                "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    _vm
                                                                                      .results
                                                                                      .linkClicksSummarySame
                                                                                      .datasets2
                                                                                      .length >
                                                                                    2
                                                                                      ? _c(
                                                                                          "td",
                                                                                          {
                                                                                            staticClass:
                                                                                              "text-right font-weight-bold link-click-table-tb"
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                  " +
                                                                                                _vm._s(
                                                                                                  _vm.results.linkClicksSummarySame.datasets2
                                                                                                    .filter(
                                                                                                      function(
                                                                                                        element,
                                                                                                        index,
                                                                                                        array
                                                                                                      ) {
                                                                                                        return (
                                                                                                          index %
                                                                                                            2 !==
                                                                                                          0
                                                                                                        )
                                                                                                      }
                                                                                                    )
                                                                                                    .reduce(
                                                                                                      function(
                                                                                                        accumulator,
                                                                                                        current
                                                                                                      ) {
                                                                                                        return (
                                                                                                          accumulator +
                                                                                                          current
                                                                                                            .data[
                                                                                                            i
                                                                                                          ]
                                                                                                        )
                                                                                                      },
                                                                                                      0
                                                                                                    )
                                                                                                ) +
                                                                                                "\n                                "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    _c(
                                                                                      "td",
                                                                                      {
                                                                                        directives: [
                                                                                          {
                                                                                            name:
                                                                                              "show",
                                                                                            rawName:
                                                                                              "v-show",
                                                                                            value: !_vm.pdfMenu,
                                                                                            expression:
                                                                                              "!pdfMenu"
                                                                                          }
                                                                                        ],
                                                                                        staticClass:
                                                                                          "table-border-left-bold",
                                                                                        staticStyle: {
                                                                                          "word-wrap":
                                                                                            "break-word"
                                                                                        },
                                                                                        style: {
                                                                                          "border-left":
                                                                                            "2px solid rgba(0, 0, 0, 0.36)"
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "a",
                                                                                          {
                                                                                            directives: [
                                                                                              {
                                                                                                name:
                                                                                                  "show",
                                                                                                rawName:
                                                                                                  "v-show",
                                                                                                value: !_vm.pdfMenu,
                                                                                                expression:
                                                                                                  "!pdfMenu"
                                                                                              }
                                                                                            ],
                                                                                            staticStyle: {
                                                                                              "word-wrap":
                                                                                                "break-word"
                                                                                            },
                                                                                            attrs: {
                                                                                              href:
                                                                                                keyValue[1],
                                                                                              target:
                                                                                                "_blank"
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                    " +
                                                                                                _vm._s(
                                                                                                  keyValue[1]
                                                                                                ) +
                                                                                                "\n                                  "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  2
                                                                                )
                                                                              }
                                                                            ),
                                                                            0
                                                                          )
                                                                        ]
                                                                      },
                                                                      proxy: true
                                                                    }
                                                                  ],
                                                                  null,
                                                                  false,
                                                                  589676739
                                                                )
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            : [
                                                _vm._l(
                                                  _vm.results.splitData
                                                    .linkClicksSummary,
                                                  function(chart, i) {
                                                    return _c(
                                                      "v-col",
                                                      {
                                                        key: i,
                                                        attrs: { cols: "12" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-card",
                                                          {
                                                            staticClass:
                                                              "pa-2 ma-2 dist-eng-charts"
                                                          },
                                                          [
                                                            _c("bar-chart", {
                                                              attrs: {
                                                                "chart-data": chart,
                                                                options: _vm.getChartOptionsBarPercent2(
                                                                  _vm
                                                                    .$_splitMixin_splitLetters[
                                                                    i
                                                                  ] +
                                                                    " Link Click Summary",
                                                                  "Link Click % of Delivered Messages ",
                                                                  "Link Name"
                                                                )
                                                              }
                                                            }),
                                                            _c("v-divider", {
                                                              staticClass:
                                                                "my-4"
                                                            }),
                                                            _c(
                                                              "v-simple-table",
                                                              {
                                                                staticStyle: {
                                                                  "table-layout":
                                                                    "fixed",
                                                                  width: "100%"
                                                                },
                                                                attrs: {
                                                                  id:
                                                                    i +
                                                                    "-linkClicks",
                                                                  dense: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function() {
                                                                        return [
                                                                          _c(
                                                                            "thead",
                                                                            [
                                                                              _c(
                                                                                "tr",
                                                                                [
                                                                                  _c(
                                                                                    "th",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold link-click-table-th",
                                                                                      style: [
                                                                                        {
                                                                                          color:
                                                                                            "#6d2c8a",
                                                                                          "text-align":
                                                                                            "left",
                                                                                          "border-bottom":
                                                                                            "2px solid rgba(0, 0, 0, 0.36)"
                                                                                        }
                                                                                      ]
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                Link Name\n                              "
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "th",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-right primary--text font-weight-bold text-subtitle-2 table-border-left-bold table-border-bottom-bold link-click-table-th",
                                                                                      style: [
                                                                                        {
                                                                                          color:
                                                                                            "#6d2c8a",
                                                                                          "text-align":
                                                                                            "right",
                                                                                          "border-bottom":
                                                                                            "2px solid rgba(0, 0, 0, 0.36)",
                                                                                          "border-left":
                                                                                            "2px solid rgba(0, 0, 0, 0.36)"
                                                                                        }
                                                                                      ]
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                Gross\n                              "
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "th",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold link-click-table-th",
                                                                                      style: [
                                                                                        {
                                                                                          color:
                                                                                            "#6d2c8a",
                                                                                          "text-align":
                                                                                            "right",
                                                                                          "border-bottom":
                                                                                            "2px solid rgba(0, 0, 0, 0.36)"
                                                                                        }
                                                                                      ]
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                Unique\n                              "
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "th",
                                                                                    {
                                                                                      directives: [
                                                                                        {
                                                                                          name:
                                                                                            "show",
                                                                                          rawName:
                                                                                            "v-show",
                                                                                          value: !_vm.pdfMenu,
                                                                                          expression:
                                                                                            "!pdfMenu"
                                                                                        }
                                                                                      ],
                                                                                      staticClass:
                                                                                        "text-left primary--text font-weight-bold text-subtitle-2 table-border-left-bold table-border-bottom-bold link-click-table-th",
                                                                                      style: [
                                                                                        {
                                                                                          color:
                                                                                            "#6d2c8a",
                                                                                          "text-align":
                                                                                            "left",
                                                                                          "border-bottom":
                                                                                            "2px solid rgba(0, 0, 0, 0.36)",
                                                                                          "border-left":
                                                                                            "2px solid rgba(0, 0, 0, 0.36)"
                                                                                        }
                                                                                      ]
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                Url\n                              "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "tbody",
                                                                            _vm._l(
                                                                              Object.entries(
                                                                                _vm
                                                                                  .results
                                                                                  .splitData
                                                                                  .linkNameToUrl[
                                                                                  i
                                                                                ]
                                                                              ),
                                                                              function(
                                                                                keyValue,
                                                                                keyValueIndex
                                                                              ) {
                                                                                return _c(
                                                                                  "tr",
                                                                                  {
                                                                                    key:
                                                                                      keyValue[0]
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            keyValue[0]
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "td",
                                                                                      {
                                                                                        staticClass:
                                                                                          "text-right table-border-left table-border-left-bold",
                                                                                        style: [
                                                                                          {
                                                                                            "text-align":
                                                                                              "right",
                                                                                            "border-left":
                                                                                              "2px solid rgba(0, 0, 0, 0.36)"
                                                                                          }
                                                                                        ]
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                " +
                                                                                            _vm._s(
                                                                                              _vm._f(
                                                                                                "commaNumber"
                                                                                              )(
                                                                                                chart
                                                                                                  .datasets2[0]
                                                                                                  .data[
                                                                                                  keyValueIndex
                                                                                                ]
                                                                                              )
                                                                                            ) +
                                                                                            "\n                              "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "td",
                                                                                      {
                                                                                        staticClass:
                                                                                          "text-right",
                                                                                        style: {
                                                                                          "text-align":
                                                                                            "right"
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                " +
                                                                                            _vm._s(
                                                                                              _vm._f(
                                                                                                "commaNumber"
                                                                                              )(
                                                                                                chart
                                                                                                  .datasets2[1]
                                                                                                  .data[
                                                                                                  keyValueIndex
                                                                                                ]
                                                                                              )
                                                                                            ) +
                                                                                            "\n                              "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "td",
                                                                                      {
                                                                                        staticClass:
                                                                                          "table-border-left-bold",
                                                                                        staticStyle: {
                                                                                          "word-wrap":
                                                                                            "break-word"
                                                                                        },
                                                                                        style: {
                                                                                          "border-left":
                                                                                            "2px solid rgba(0, 0, 0, 0.36)"
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "a",
                                                                                          {
                                                                                            directives: [
                                                                                              {
                                                                                                name:
                                                                                                  "show",
                                                                                                rawName:
                                                                                                  "v-show",
                                                                                                value: !_vm.pdfMenu,
                                                                                                expression:
                                                                                                  "!pdfMenu"
                                                                                              }
                                                                                            ],
                                                                                            staticStyle: {
                                                                                              "word-wrap":
                                                                                                "break-word"
                                                                                            },
                                                                                            attrs: {
                                                                                              href:
                                                                                                keyValue[1],
                                                                                              target:
                                                                                                "_blank"
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                  " +
                                                                                                _vm._s(
                                                                                                  keyValue[1]
                                                                                                ) +
                                                                                                "\n                                "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              }
                                                                            ),
                                                                            0
                                                                          )
                                                                        ]
                                                                      },
                                                                      proxy: true
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c("v-divider", {
                                                          staticClass: "my-4"
                                                        }),
                                                        _c("div", {
                                                          staticClass:
                                                            "html2pdf__page-break"
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                _vm._l(
                                                  _vm.results.winnerData
                                                    .linkClicksSummary,
                                                  function(chart, i) {
                                                    return _c(
                                                      "v-col",
                                                      {
                                                        key: "w-" + i,
                                                        attrs: { cols: "12" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-card",
                                                          {
                                                            staticClass:
                                                              "pa-2 ma-2 dist-eng-charts"
                                                          },
                                                          [
                                                            _c("bar-chart", {
                                                              staticClass:
                                                                "pb-2",
                                                              attrs: {
                                                                "chart-data": chart,
                                                                options: _vm.getChartOptionsBarPercent2(
                                                                  _vm.results
                                                                    .winnerLabel +
                                                                    " Link Click Summary",
                                                                  "Link Click % of Delivered Messages ",
                                                                  "Link Name"
                                                                )
                                                              }
                                                            }),
                                                            _c("v-divider", {
                                                              staticClass:
                                                                "my-4"
                                                            }),
                                                            _c(
                                                              "v-simple-table",
                                                              {
                                                                staticStyle: {
                                                                  "table-layout":
                                                                    "fixed",
                                                                  width: "100%"
                                                                },
                                                                attrs: {
                                                                  id:
                                                                    "w-" +
                                                                    i +
                                                                    "-linkClicks",
                                                                  dense: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function() {
                                                                        return [
                                                                          _c(
                                                                            "thead",
                                                                            [
                                                                              _c(
                                                                                "tr",
                                                                                [
                                                                                  _c(
                                                                                    "th",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold link-click-table-th",
                                                                                      style: [
                                                                                        {
                                                                                          color:
                                                                                            "#6d2c8a",
                                                                                          "text-align":
                                                                                            "left",
                                                                                          "border-bottom":
                                                                                            "2px solid rgba(0, 0, 0, 0.36)"
                                                                                        }
                                                                                      ]
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                Link Name\n                              "
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "th",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-right primary--text font-weight-bold text-subtitle-2 table-border-left-bold table-border-bottom-bold link-click-table-th",
                                                                                      style: [
                                                                                        {
                                                                                          color:
                                                                                            "#6d2c8a",
                                                                                          "text-align":
                                                                                            "right",
                                                                                          "border-left":
                                                                                            "2px solid rgba(0, 0, 0, 0.36)",
                                                                                          "border-bottom":
                                                                                            "2px solid rgba(0, 0, 0, 0.36)"
                                                                                        }
                                                                                      ]
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                Gross\n                              "
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "th",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold link-click-table-th",
                                                                                      style: [
                                                                                        {
                                                                                          color:
                                                                                            "#6d2c8a",
                                                                                          "text-align":
                                                                                            "right",
                                                                                          "border-bottom":
                                                                                            "2px solid rgba(0, 0, 0, 0.36)"
                                                                                        }
                                                                                      ]
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                Unique\n                              "
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "th",
                                                                                    {
                                                                                      directives: [
                                                                                        {
                                                                                          name:
                                                                                            "show",
                                                                                          rawName:
                                                                                            "v-show",
                                                                                          value: !_vm.pdfMenu,
                                                                                          expression:
                                                                                            "!pdfMenu"
                                                                                        }
                                                                                      ],
                                                                                      staticClass:
                                                                                        "text-left primary--text font-weight-bold text-subtitle-2 table-border-left-bold table-border-bottom-bold link-click-table-th",
                                                                                      style: [
                                                                                        {
                                                                                          color:
                                                                                            "#6d2c8a",
                                                                                          "text-align":
                                                                                            "left",
                                                                                          "border-left":
                                                                                            "2px solid rgba(0, 0, 0, 0.36)",
                                                                                          "border-bottom":
                                                                                            "2px solid rgba(0, 0, 0, 0.36)"
                                                                                        }
                                                                                      ]
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                Url\n                              "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "tbody",
                                                                            _vm._l(
                                                                              Object.entries(
                                                                                _vm
                                                                                  .results
                                                                                  .winnerData
                                                                                  .linkNameToUrl[
                                                                                  i
                                                                                ]
                                                                              ),
                                                                              function(
                                                                                keyValue,
                                                                                keyValueIndex
                                                                              ) {
                                                                                return _c(
                                                                                  "tr",
                                                                                  {
                                                                                    key:
                                                                                      keyValue[0]
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            keyValue[0]
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "td",
                                                                                      {
                                                                                        staticClass:
                                                                                          "text-right table-border-left table-border-left-bold",
                                                                                        style: [
                                                                                          {
                                                                                            "text-align":
                                                                                              "right",
                                                                                            "border-left":
                                                                                              "2px solid rgba(0, 0, 0, 0.36)"
                                                                                          }
                                                                                        ]
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                " +
                                                                                            _vm._s(
                                                                                              _vm._f(
                                                                                                "commaNumber"
                                                                                              )(
                                                                                                chart
                                                                                                  .datasets2[0]
                                                                                                  .data[
                                                                                                  keyValueIndex
                                                                                                ]
                                                                                              )
                                                                                            ) +
                                                                                            "\n                              "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "td",
                                                                                      {
                                                                                        staticClass:
                                                                                          "text-right"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                " +
                                                                                            _vm._s(
                                                                                              _vm._f(
                                                                                                "commaNumber"
                                                                                              )(
                                                                                                chart
                                                                                                  .datasets2[1]
                                                                                                  .data[
                                                                                                  keyValueIndex
                                                                                                ]
                                                                                              )
                                                                                            ) +
                                                                                            "\n                              "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "td",
                                                                                      {
                                                                                        staticClass:
                                                                                          "table-border-left-bold",
                                                                                        staticStyle: {
                                                                                          "word-wrap":
                                                                                            "break-word"
                                                                                        },
                                                                                        style: {
                                                                                          "border-left":
                                                                                            "2px solid rgba(0, 0, 0, 0.36)"
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "a",
                                                                                          {
                                                                                            directives: [
                                                                                              {
                                                                                                name:
                                                                                                  "show",
                                                                                                rawName:
                                                                                                  "v-show",
                                                                                                value: !_vm.pdfMenu,
                                                                                                expression:
                                                                                                  "!pdfMenu"
                                                                                              }
                                                                                            ],
                                                                                            staticStyle: {
                                                                                              "word-wrap":
                                                                                                "break-word"
                                                                                            },
                                                                                            attrs: {
                                                                                              href:
                                                                                                keyValue[1],
                                                                                              target:
                                                                                                "_blank"
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                  " +
                                                                                                _vm._s(
                                                                                                  keyValue[1]
                                                                                                ) +
                                                                                                "\n                                "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              }
                                                                            ),
                                                                            0
                                                                          )
                                                                        ]
                                                                      },
                                                                      proxy: true
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c("v-divider", {
                                                          staticClass: "my-4"
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  }
                                                )
                                              ]
                                        ],
                                        2
                                      )
                                    ]
                                  : _vm._e()
                              ]
                            : _c(
                                "v-card-subtitle",
                                {
                                  staticClass:
                                    "red--text font-weight-bold text-h5 pa-2 mb-4"
                                },
                                [
                                  _vm._v(
                                    "\n            The email is still sending. Please check back later for an updated Single Mailing Report which will be completed on (" +
                                      _vm._s(
                                        _vm._f("displayDateFormat")(
                                          _vm.results.mailingMetaData
                                            .winningDistribution > 0
                                            ? _vm
                                                .moment(
                                                  _vm.$_global_parseAsUtc(
                                                    _vm.results.mailingMetaData
                                                      .winningScheduleDateEnd
                                                  )
                                                )
                                                .add(1, "d")
                                            : _vm
                                                .moment(
                                                  _vm.$_global_parseAsUtc(
                                                    _vm.results.mailingMetaData
                                                      .scheduleDateEnd
                                                  )
                                                )
                                                .add(1, "d")
                                        )
                                      ) +
                                      ").\n            "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n            If you want to see basic metrics for this mailing, check out the\n            "
                                  ),
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "MailingResultsDashboard",
                                          params: {
                                            campaignId: _vm.campaignId,
                                            mailingItemId: _vm.mailingItemId
                                          }
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              Real Time Dashboard\n            "
                                      )
                                    ]
                                  ),
                                  _vm._v("\n            .\n          ")
                                ],
                                1
                              )
                        ],
                        2
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }