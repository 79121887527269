<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      style="z-index: 10"
      width="512"
      :height="$_windowMixin_contentHeight + $_windowMixin_getFooterHeight()"
    >
      <v-skeleton-loader
        v-if="loadingScheduled"
        type="list-item@20"
      />
      <v-list
        v-else
        nav
        dense
      >
        <v-list-item-group
          :value="mailingItemId"
          active-class="deep-purple--text text--accent-4"
        >
          <template
            v-for="(mailing, i) in scheduledMailings"
          >
            <v-divider
              v-if="i > 0 && !scheduledMailings[i-1].scheduleDateLocal.isSame(mailing.scheduleDateLocal, 'date')"
              :key="`${mailing.mailingItemId}-D`"
            />
            <v-subheader
              v-if="i === 0 || !scheduledMailings[i-1].scheduleDateLocal.isSame(mailing.scheduleDateLocal, 'date')"
              :key="`${mailing.mailingItemId}-T`"
              class="text-h5 font-weight-bold"
            >
              {{ mailing.scheduleDateLocal | displayDateFormat3 }}
            </v-subheader>
            <v-list-item
              :key="mailing.mailingItemId"
              :value="mailing.mailingItemId"
            >
              <v-list-item-title
                @click="$router.push({ name: 'SingleMailingReport', params: { campaignId: mailing.campaignId, mailingItemId: mailing.mailingItemId } })"
              >
                <v-row>
                  <v-col cols="auto">
                    <v-chip
                      :color="mailing.status === 4 ? 'green lighten-2' : 'accent'"
                    >
                      {{ mailing.scheduleDateLocal | displayTimeFormat3 }}
                    </v-chip>
                  </v-col>
                  <v-col
                    cols="auto"
                    class="align-self-center"
                  >
                    {{ mailing.campaignName }} - {{ mailing.mailingName }}
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <CampaignHeader
      ref="campaignHeader"
      disabled
      :campaign-id="campaignId"
      :mailing-item-id="mailingItemId"
    />
    <v-skeleton-loader
      v-if="reportLoading"
      type="table"
    />
    <v-card
      v-else-if="results"
      class="single-mailing-report"
    >
      <v-card-text class="black--text font-weight-normal">
        <!--
        :pdf-content-width="`${$_windowMixin_getContentWidth() - 56}px`"
          @beforeDownload="beforePdfDownload($event)" -->
        <vue-html2pdf
          ref="html2Pdf"
          :show-layout="false"
          :float-layout="false"
          :enable-download="false"
          :preview-modal="false"
          :manual-pagination="true"
          :html-to-pdf-options="htmlToPdfOptions"
          pdf-format="letter"
          pdf-orientation="landscape"
          pdf-content-width="100%"
          @beforeDownload="beforeDownload($event)"
        >
          <section
            slot="pdf-content"
            class="pdf-content"
          >
            <section class="pdf-header">
              <v-row
                no-gutters
                class="primary--text pb-4"
              >
                <v-col cols="8">
                  <span class="text-h4 font-weight-bold">Med-E-Mail Analytics</span>
                  <br>
                  <span class="text-h5 font-weight-light">Single Mailing Report Summary</span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        v-show="!pdfMenu"
                        class="ml-4"
                        icon
                        v-on="onTooltip"
                        @click="drawer = !drawer"
                      >
                        <v-icon
                          color="tertiary"
                        >
                          mdi-menu
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>View Other Sent Mailings</span>
                  </v-tooltip>
                  <v-menu
                    v-model="pdfMenu"
                    right
                    x-offset
                    origin="right"
                    :close-on-click="false"
                    transition="slide-x-transition"
                  >
                    <template v-slot:activator="{ on: onMenu }">
                      <v-tooltip top>
                        <template v-slot:activator="{ on: onTooltip }">
                          <v-btn
                            v-show="!pdfMenu"
                            icon
                            v-on="{ ...onMenu, ...onTooltip }"
                            @click="beforePdfDownload()"
                          >
                            <v-icon
                              color="success"
                            >
                              mdi-file-pdf-box
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Download Single Mailing Report PDF</span>
                      </v-tooltip>
                    </template>
                    <v-card>
                      <v-card-text class="pa-5">
                        <div>Formatting PDF for download...</div>
                        <div class="font-italic">
                          If the report looks too small, resize your browser window!
                        </div>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="tertiary"
                          @click="afterPdfDownload()"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="success"
                          @click="generatePdfReport()"
                        >
                          Download
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        v-show="!pdfMenu"
                        icon
                        v-on="onTooltip"
                        @click="downloadEntireReport()"
                      >
                        <v-icon color="green lighten-3">
                          mdi-file-download
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Download Single Mailing Report CSV</span>
                  </v-tooltip>
                  <!-- <v-tooltip
              top
            >
              <template v-slot:activator="{ on: onTooltip }">
                <v-btn
                  icon
                  v-on="onTooltip"
                  @click="generatePdfReport()"
                >
                  <v-icon
                    color="success"
                  >
                    mdi-file-pdf-box
                  </v-icon>
                </v-btn>
              </template>
              <span>Download Single Mailing Report PDF</span>
            </v-tooltip> -->
                  <!-- <v-btn @click="$_windowMixin_addContentClass('pdf-letter-width')">
              on
            </v-btn>
            <v-btn @click="$_windowMixin_removeContentClass('pdf-letter-width')">
              off
            </v-btn> -->
                  <v-tooltip
                    v-if="results.reportType === 2 && results.deliverabilityMetricsTotals.totalAudience > 0"
                    top
                  >
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        v-show="!pdfMenu"
                        icon
                        v-on="onTooltip"
                        @click="downloadLeadScoresReport()"
                      >
                        <v-icon color="green lighten-3">
                          mdi-file-download
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Download Lead Scores Report</span>
                  </v-tooltip>
                  <!--
              v-if="results.reportType === 2 && results.deliverabilityMetricsTotals.totalAudience > 0"
              v-if="results.reportType === 3 && results.deliverabilityMetricsTotals.totalAudience > 0" -->
                  <v-tooltip
                    v-if="results.reportType === 3 && results.deliverabilityMetricsTotals.totalAudience > 0"
                    top
                  >
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-menu
                        bottom
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-show="!pdfMenu"
                            icon
                            v-bind="attrs"
                            v-on="{ ...on, ...onTooltip }"
                          >
                            <v-icon color="green lighten-3">
                              mdi-file-download
                            </v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="downloadComprehensiveReportZip()">
                            <v-list-item-title>All Reports</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="downloadComprehensiveReportReceived()">
                            <v-list-item-title>Received</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="downloadComprehensiveReportOpens()">
                            <v-list-item-title>Opens</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="downloadComprehensiveReportClicks()">
                            <v-list-item-title>Clicks</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="downloadComprehensiveReportOptOuts()">
                            <v-list-item-title>Opt Outs</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="downloadComprehensiveReportEnhanced()">
                            <v-list-item-title>Enhanced</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>
                    <span>Download Comprehensive Reports</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="showLinkToggle"
                    top
                  >
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        v-show="!pdfMenu"
                        icon
                        color="white"
                        v-on="onTooltip"
                        @click="toggleLinkData = !toggleLinkData"
                      >
                        <v-icon :color="toggleLinkData ? 'info' : 'tertiary'">
                          {{ toggleLinkData ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off' }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Toggle Link Data {{ toggleLinkData ? 'off' : 'on' }}</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="showAgeToggle"
                    top
                  >
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        v-show="!pdfMenu"
                        icon
                        color="white"
                        v-on="onTooltip"
                        @click="toggleAgeData = !toggleAgeData"
                      >
                        <v-icon :color="toggleAgeData ? 'info' : 'tertiary'">
                          {{ toggleAgeData ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off' }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Toggle Age Data {{ toggleAgeData ? 'off' : 'on' }}</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="4">
                  <v-img
                    height="73"
                    contain
                    src="@/assets/mms-signature.jpg"
                  />
                </v-col>
              </v-row>
            </section>
            <v-divider class="mb-4" />
            <section class="summ-table">
              <v-card class="pa-2 ma-2">
                <v-row
                  no-gutters
                >
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-simple-table
                      id="headerTable1"
                      dense
                    >
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td
                              class="text-left"
                              width="175"
                            >
                              Client Name:
                            </td>
                            <td>{{ results.customerName }}</td>
                          </tr>
                          <tr>
                            <td
                              class="text-left"
                              width="175"
                            >
                              Campaign Name:
                            </td>
                            <td>{{ results.campaignName }}</td>
                          </tr>
                          <tr>
                            <td
                              class="text-left"
                              width="175"
                            >
                              Mailing Name:
                            </td>
                            <td>{{ results.mailingMetaData.name }}</td>
                          </tr>
                          <tr>
                            <td
                              class="text-left"
                              width="175"
                            >
                              NOWW Job Number:
                            </td>
                            <td>{{ results.mailingMetaData.campaignNowwOrderNumber }}</td>
                          </tr>
                          <tr v-if="results.mailingMetaData.sequence > 1">
                            <td
                              class="text-left"
                              width="175"
                            >
                              Resend Type:
                            </td>
                            <td>{{ $_resendType_display(results.mailingMetaData.resendType) }}</td>
                          </tr>
                          <tr>
                            <td
                              class="text-left"
                              width="175"
                            >
                              Lists:
                            </td>
                            <td>{{ results.lists.join(', ') }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                  <v-divider
                    vertical
                    class="mx-2 d-none d-sm-flex"
                  />
                  <v-col
                    cols="12"
                    sm="7"
                  >
                    <v-simple-table
                      id="headerTable2"
                      dense
                    >
                      <template v-slot:default>
                        <tbody>
                          <tr
                            v-for="split in results.mailingMetaData.splits"
                            :key="split.id"
                          >
                            <td
                              class="text-left"
                              width="175"
                            >
                              Subject ({{ split.splitLetter }}):
                            </td>
                            <td>{{ split.subject }}</td>
                          </tr>
                          <tr>
                            <td
                              class="text-left"
                              width="175"
                            >
                              {{ results.mailingMetaData.winningDistribution > 0 ? 'Split ' : '' }}Send Schedule:
                            </td>
                            <td>{{ $_global_parseAsUtc(results.mailingMetaData.scheduleDate) | displayDateTimeFormat }} - {{ $_global_parseAsUtc(results.mailingMetaData.scheduleDateEnd) | displayDateTimeFormat }}</td>
                          </tr>
                          <tr v-if="results.mailingMetaData.winningDistribution > 0">
                            <td
                              class="text-left"
                              width="175"
                            >
                              Winning Send Schedule:
                            </td>
                            <td>{{ $_global_parseAsUtc(results.mailingMetaData.winningScheduleDate) | displayDateTimeFormat }} - {{ $_global_parseAsUtc(results.mailingMetaData.winningScheduleDateEnd) | displayDateTimeFormat }}</td>
                          </tr>
                          <tr v-if="results.mailingMetaData.winningDistribution > 0">
                            <td
                              class="text-left"
                              width="175"
                            >
                              Winner:
                            </td>
                            <td>{{ results.winningSplit.splitLetter }} ({{ $_winningCriteria_display(results.mailingMetaData.winningCriteria) }})</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-card>
            </section>
            <v-divider class="my-4" />
            <div
              class="html2pdf__page-break"
            />
            <template v-if="results.deliverabilityMetricsTotals.totalAudience > 0">
              <section class="title-bar">
                <v-card-subtitle
                  class="grey white--text font-weight-bold text-h5 pa-2 mb-4 title-bar-text"
                  style="display: flex;"
                >
                  Deliverability Metrics
                  <v-spacer />
                  <v-tooltip top>
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        v-show="!pdfMenu"
                        icon
                        color="white"
                        v-on="onTooltip"
                        @click="$_downloadFileMixin_downloadReportTable('deliverabilityMetrics', `${results.mailingMetaData.campaignNowwOrderNumber}-${results.mailingMetaData.name.replace(/ /g, '')}-DeliverabilityMetrics`)"
                      >
                        <v-icon color="green lighten-3">
                          mdi-file-download
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Download Deliverability Metrics</span>
                  </v-tooltip>
                </v-card-subtitle>
              </section>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  :lg="results.mailingMetaData.splits.length > 1 && pdfMenu ? '12': '6'"
                  sm="6"
                >
                  <section
                    class="delv-met"
                  >
                    <v-card class="pa-2 ma-2">
                      <v-simple-table
                        id="deliverabilityMetrics"
                        dense
                      >
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th
                                :style="[{ 'color': '#6d2c8a', 'text-align': 'right'}]"
                                class="text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text"
                              />
                              <template v-if="results.splitData.deliverabilityMetrics.length > 1">
                                <template v-for="(split, i) in results.splitData.deliverabilityMetrics">
                                  <th
                                    :key="i"
                                    :style="[{ 'color': '#6d2c8a', 'text-align': 'right'}]"
                                    class="text-right primary--text font-weight-bold text-subtitle-2 table-border-left table-border-bottom-bold delv-met-th-text"
                                  >
                                    {{ results.mailingMetaData.splits[i].splitLetter }}
                                  </th>
                                  <th
                                    :key="`${i}-rate`"
                                    :style="[{ 'color': '#6d2c8a', 'text-align': 'right'}]"
                                    class="text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text"
                                  >
                                    {{ results.mailingMetaData.splits[i].splitLetter }} Rate
                                  </th>
                                </template>
                              </template>
                              <template v-if="results.winnerData.deliverabilityMetrics.length > 0">
                                <template v-for="(split, i) in results.winnerData.deliverabilityMetrics">
                                  <th
                                    :key="`w-${i}`"
                                    :style="[{ 'color': '#6d2c8a', 'text-align': 'right'}]"
                                    class="text-right primary--text font-weight-bold text-subtitle-2 table-border-left table-border-bottom-bold delv-met-th-text"
                                  >
                                    {{ results.winnerLabel.replace('Winner', 'W') }}
                                  </th>
                                  <th
                                    :key="`w-${i}-rate`"
                                    :style="[{ 'color': '#6d2c8a', 'text-align': 'right'}]"
                                    class="text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text"
                                  >
                                    {{ results.winnerLabel.replace('Winner', 'W') }} Rate
                                  </th>
                                </template>
                              </template>
                              <template v-if="!pdfMenu || results.splitData.deliverabilityMetrics.length < 4">
                                <th
                                  :style="{ 'color': '#6d2c8a'}"
                                  class="text-right primary--text font-weight-bold text-subtitle-2 table-border-left-bold table-border-bottom-bold delv-met-th-total"
                                >
                                  Total
                                </th>
                                <th
                                  :style="{ 'color': '#6d2c8a'}"
                                  class="text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-total"
                                >
                                  Rate
                                </th>
                              </template>
                            </tr>
                          </thead>
                          <tbody>
                            <!-- <tr>
                            <td
                              class="text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold"
                              width="200"
                            >
                              Target Audience
                            </td>
                            <template v-if="results.splitData.deliverabilityMetrics.length > 1">
                              <template v-for="(deliverabilityMetrics, i) in results.splitData.deliverabilityMetrics">
                                <td
                                  :key="i"
                                  class="text-right table-border-left table-border-bottom-bold"
                                >
                                  {{ deliverabilityMetrics.totalAudience | commaNumber }}
                                </td>
                                <td
                                  :key="`${i}-rate`"
                                  class="text-right table-border-bottom-bold"
                                />
                              </template>
                            </template>
                            <template v-if="results.winnerData.deliverabilityMetrics.length > 0">
                              <template v-for="(deliverabilityMetrics, i) in results.winnerData.deliverabilityMetrics">
                                <td
                                  :key="`w-${i}`"
                                  class="text-right table-border-left table-border-bottom-bold"
                                >
                                  {{ deliverabilityMetrics.totalAudience | commaNumber }}
                                </td>
                                <td
                                  :key="`w-${i}-rate`"
                                  class="text-right table-border-bottom-bold"
                                />
                              </template>
                            </template>
                            <td
                              class="text-right table-border-left-bold font-weight-bold table-border-bottom-bold"
                            >
                              {{ results.deliverabilityMetricsTotals.totalAudience | commaNumber }}
                            </td>
                            <td
                              class="text-right font-weight-bold table-border-bottom-bold"
                            />
                          </tr> -->
                            <!-- <tr>
                            <td
                              class="text-left primary--text font-weight-bold text-subtitle-2"
                              width="200"
                            >
                              Suppressed
                            </td>
                            <template v-if="results.splitData.deliverabilityMetrics.length > 1">
                              <template v-for="(deliverabilityMetrics, i) in results.splitData.deliverabilityMetrics">
                                <td
                                  :key="i"
                                  class="text-right table-border-left"
                                >
                                  {{ deliverabilityMetrics.suppressed | commaNumber }}
                                </td>
                                <td
                                  :key="`${i}-rate`"
                                  class="text-right"
                                >
                                  {{ deliverabilityMetrics.suppressedRate | percent(2) }}
                                </td>
                              </template>
                            </template>
                            <template v-if="results.winnerData.deliverabilityMetrics.length > 0">
                              <template v-for="(deliverabilityMetrics, i) in results.winnerData.deliverabilityMetrics">
                                <td
                                  :key="`w-${i}`"
                                  class="text-right table-border-left"
                                >
                                  {{ deliverabilityMetrics.suppressed | commaNumber }}
                                </td>
                                <td
                                  :key="`w-${i}-rate`"
                                  class="text-right"
                                >
                                  {{ deliverabilityMetrics.suppressedRate | percent(2) }}
                                </td>
                              </template>
                            </template>
                            <td
                              class="text-right table-border-left-bold font-weight-bold"
                            >
                              {{ results.deliverabilityMetricsTotals.suppressed | commaNumber }}
                            </td>
                            <td
                              class="text-right font-weight-bold"
                            >
                              {{ results.deliverabilityMetricsTotals.suppressedRate | percent(2) }}
                            </td>
                          </tr> -->
                            <tr>
                              <td
                                :style="{ 'color': '#6d2c8a'}"
                                class="text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text"
                                width="200"
                              >
                                Sent
                              </td>
                              <template v-if="results.splitData.deliverabilityMetrics.length > 1">
                                <template v-for="(deliverabilityMetrics, i) in results.splitData.deliverabilityMetrics">
                                  <td
                                    :key="i"
                                    :style="{ 'text-align': 'right'}"
                                    class="text-right table-border-left table-border-bottom-bold"
                                  >
                                    <!-- <v-icon
                                  :color="$_splitMixin_splitColors[i]"
                                >
                                  mdi-square-rounded
                                </v-icon> -->
                                    {{ deliverabilityMetrics.sentMessages | commaNumber }}
                                  </td>
                                  <td
                                    :key="`${i}-rate`"
                                    :style="{ 'text-align': 'right'}"
                                    class="text-right table-border-bottom-bold"
                                  >
                                    {{ deliverabilityMetrics.sentRate | percent(2) }}
                                  </td>
                                </template>
                              </template>
                              <template v-if="results.winnerData.deliverabilityMetrics.length > 0">
                                <template v-for="(deliverabilityMetrics, i) in results.winnerData.deliverabilityMetrics">
                                  <td
                                    :key="`w-${i}`"
                                    :style="{ 'text-align': 'right'}"
                                    class="text-right table-border-left table-border-bottom-bold"
                                  >
                                    <!-- <v-icon
                                  :color="$_splitMixin_winningColor"
                                >
                                  mdi-square-rounded
                                </v-icon> -->
                                    {{ deliverabilityMetrics.sentMessages | commaNumber }}
                                  </td>
                                  <td
                                    :key="`w-${i}-rate`"
                                    :style="{ 'text-align': 'right'}"
                                    class="text-right table-border-bottom-bold"
                                  >
                                    {{ deliverabilityMetrics.sentRate | percent(2) }}
                                  </td>
                                </template>
                              </template>
                              <template v-if="!pdfMenu || results.splitData.deliverabilityMetrics.length < 4">
                                <td
                                  class="text-right table-border-left-bold font-weight-bold table-border-bottom-bold delv-met-th-total"
                                >
                                  {{ results.deliverabilityMetricsTotals.sentMessages | commaNumber }}
                                </td>
                                <td
                                  class="text-right font-weight-bold table-border-bottom-bold delv-met-th-total"
                                >
                                  {{ results.deliverabilityMetricsTotals.sentRate | percent(2) }}
                                </td>
                              </template>
                            </tr>
                            <tr>
                              <td
                                :style="{ 'color': '#6d2c8a'}"
                                class="text-left primary--text font-weight-bold text-subtitle-2 delv-met-th-text"
                                width="200"
                              >
                                Hard Bounce
                              </td>
                              <template v-if="results.splitData.deliverabilityMetrics.length > 1">
                                <template v-for="(deliverabilityMetrics, i) in results.splitData.deliverabilityMetrics">
                                  <td
                                    :key="i"
                                    :style="{ 'text-align': 'right'}"
                                    class="text-right table-border-left"
                                  >
                                    {{ deliverabilityMetrics.hardBounces | commaNumber }}
                                  </td>
                                  <td
                                    :key="`${i}-rate`"
                                    :style="{ 'text-align': 'right'}"
                                    class="text-right"
                                  >
                                    {{ deliverabilityMetrics.hardBounceRate | percent(2) }}
                                  </td>
                                </template>
                              </template>
                              <template v-if="results.winnerData.deliverabilityMetrics.length > 0">
                                <template v-for="(deliverabilityMetrics, i) in results.winnerData.deliverabilityMetrics">
                                  <td
                                    :key="`w-${i}`"
                                    :style="{ 'text-align': 'right'}"
                                    class="text-right table-border-left"
                                  >
                                    {{ deliverabilityMetrics.hardBounces | commaNumber }}
                                  </td>
                                  <td
                                    :key="`w-${i}-rate`"
                                    :style="{ 'text-align': 'right'}"
                                    class="text-right"
                                  >
                                    {{ deliverabilityMetrics.hardBounceRate | percent(2) }}
                                  </td>
                                </template>
                              </template>
                              <template v-if="!pdfMenu || results.splitData.deliverabilityMetrics.length < 4">
                                <td
                                  class="text-right table-border-left-bold font-weight-bold delv-met-th-total"
                                >
                                  <!-- <v-icon
                                :color="results.deliverabilityMetricsTotalsPieChart.datasets[0].backgroundColor[1]"
                              >
                                mdi-square-rounded
                              </v-icon> -->
                                  {{ results.deliverabilityMetricsTotals.hardBounces | commaNumber }}
                                </td>
                                <td
                                  class="text-right font-weight-bold delv-met-th-total"
                                >
                                  {{ results.deliverabilityMetricsTotals.hardBounceRate | percent(2) }}
                                </td>
                              </template>
                            </tr>
                            <tr>
                              <td
                                :style="{ 'color': '#6d2c8a'}"
                                class="text-left primary--text font-weight-bold text-subtitle-2 delv-met-th-text"
                                width="200"
                              >
                                Soft Bounce
                              </td>
                              <template v-if="results.splitData.deliverabilityMetrics.length > 1">
                                <template v-for="(deliverabilityMetrics, i) in results.splitData.deliverabilityMetrics">
                                  <td
                                    :key="i"
                                    :style="{ 'text-align': 'right'}"
                                    class="text-right table-border-left"
                                  >
                                    {{ deliverabilityMetrics.softBounces | commaNumber }}
                                  </td>
                                  <td
                                    :key="`${i}-rate`"
                                    :style="{ 'text-align': 'right'}"
                                    class="text-right"
                                  >
                                    {{ deliverabilityMetrics.softBounceRate | percent(2) }}
                                  </td>
                                </template>
                              </template>
                              <template v-if="results.winnerData.deliverabilityMetrics.length > 0">
                                <template v-for="(deliverabilityMetrics, i) in results.winnerData.deliverabilityMetrics">
                                  <td
                                    :key="`w-${i}`"
                                    :style="{ 'text-align': 'right'}"
                                    class="text-right table-border-left"
                                  >
                                    {{ deliverabilityMetrics.softBounces | commaNumber }}
                                  </td>
                                  <td
                                    :key="`w-${i}-rate`"
                                    :style="{ 'text-align': 'right'}"
                                    class="text-right"
                                  >
                                    {{ deliverabilityMetrics.softBounceRate | percent(2) }}
                                  </td>
                                </template>
                              </template>
                              <template v-if="!pdfMenu || results.splitData.deliverabilityMetrics.length < 4">
                                <td
                                  class="text-right table-border-left-bold font-weight-bold delv-met-th-total"
                                >
                                  <!-- <v-icon
                                :color="results.deliverabilityMetricsTotalsPieChart.datasets[0].backgroundColor[2]"
                              >
                                mdi-square-rounded
                              </v-icon> -->
                                  {{ results.deliverabilityMetricsTotals.softBounces | commaNumber }}
                                </td>
                                <td
                                  class="text-right font-weight-bold delv-met-th-total"
                                >
                                  {{ results.deliverabilityMetricsTotals.softBounceRate | percent(2) }}
                                </td>
                              </template>
                            </tr>
                            <tr>
                              <td
                                :style="{ 'color': '#6d2c8a'}"
                                class="text-left primary--text font-weight-bold text-subtitle-2 delv-met-th-text"
                                width="200"
                              >
                                Delivered Messages
                              </td>
                              <template v-if="results.splitData.deliverabilityMetrics.length > 1">
                                <template v-for="(deliverabilityMetrics, i) in results.splitData.deliverabilityMetrics">
                                  <td
                                    :key="i"
                                    :style="{ 'text-align': 'right'}"
                                    class="text-right table-border-left"
                                  >
                                    {{ deliverabilityMetrics.deliveredMessages | commaNumber }}
                                  </td>
                                  <td
                                    :key="`${i}-rate`"
                                    :style="{ 'text-align': 'right'}"
                                    class="text-right"
                                  >
                                    {{ deliverabilityMetrics.deliveredRate | percent(2) }}
                                  </td>
                                </template>
                              </template>
                              <template v-if="results.winnerData.deliverabilityMetrics.length > 0">
                                <template v-for="(deliverabilityMetrics, i) in results.winnerData.deliverabilityMetrics">
                                  <td
                                    :key="`w-${i}`"
                                    :style="{ 'text-align': 'right'}"
                                    class="text-right table-border-left"
                                  >
                                    {{ deliverabilityMetrics.deliveredMessages | commaNumber }}
                                  </td>
                                  <td
                                    :key="`w-${i}-rate`"
                                    :style="{ 'text-align': 'right'}"
                                    class="text-right"
                                  >
                                    {{ deliverabilityMetrics.deliveredRate | percent(2) }}
                                  </td>
                                </template>
                              </template>
                              <template v-if="!pdfMenu || results.splitData.deliverabilityMetrics.length < 4">
                                <td
                                  class="text-right table-border-left-bold font-weight-bold delv-met-th-total"
                                >
                                  <!-- <v-icon
                                :color="results.deliverabilityMetricsTotalsPieChart.datasets[0].backgroundColor[3]"
                              >
                                mdi-square-rounded
                              </v-icon> -->
                                  {{ results.deliverabilityMetricsTotals.deliveredMessages | commaNumber }}
                                </td>
                                <td
                                  class="text-right font-weight-bold delv-met-th-total"
                                >
                                  {{ results.deliverabilityMetricsTotals.deliveredRate | percent(2) }}
                                </td>
                              </template>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </section>
                  <template v-if="pdfMenu && results.splitData.deliverabilityMetrics.length >= 4">
                    <section
                      :style="[{ 'margin-top': '20px', 'margin-bottom': '20px' }]"
                      class="delv-met"
                    >
                      <v-col
                        style="display: inline-table"
                        cols="12"
                        sm="6"
                      >
                        <tr>
                          <th
                            :style="[ {'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'} ]"
                            class="text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text"
                          />
                          <th
                            :style="[
                              { 'color': '#6d2c8a',
                                'text-align': 'right',
                                'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'
                              }]"
                            class="text-right primary--text font-weight-bold text-subtitle-2 table-border-left-bold table-border-bottom-bold delv-met-th-total"
                          >
                            Total
                          </th>
                          <th
                            :style="[
                              { 'color': '#6d2c8a',
                                'text-align': 'right',
                                'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'
                              }]"
                            class="text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-total"
                          >
                            Rate
                          </th>
                        </tr>
                        <tr>
                          <td
                            :style="[
                              { 'color': '#6d2c8a',
                                'text-align': 'left',
                                'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'
                              }]"
                            class="text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text"
                            width="200"
                          >
                            Sent
                          </td>
                          <td
                            :style="[ {'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'} ]"
                            class="text-right table-border-left-bold font-weight-bold table-border-bottom-bold delv-met-th-total"
                          >
                            {{ results.deliverabilityMetricsTotals.sentMessages | commaNumber }}
                          </td>
                          <td
                            :style="[ {'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'} ]"
                            class="text-right font-weight-bold table-border-bottom-bold delv-met-th-total"
                          >
                            {{ results.deliverabilityMetricsTotals.sentRate | percent(2) }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            :style="[
                              { 'color': '#6d2c8a',
                                'text-align': 'left',
                                'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'
                              }]"
                            class="text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text"
                            width="200"
                          >
                            Hard Bounce
                          </td>
                          <td
                            :style="[ {'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'} ]"
                            class="text-right table-border-left-bold font-weight-bold delv-met-th-total"
                          >
                            {{ results.deliverabilityMetricsTotals.hardBounces | commaNumber }}
                          </td>
                          <td
                            :style="[ {'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'} ]"
                            class="text-right font-weight-bold delv-met-th-total"
                          >
                            {{ results.deliverabilityMetricsTotals.hardBounceRate | percent(2) }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            :style="[
                              { 'color': '#6d2c8a',
                                'text-align': 'left',
                                'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'
                              }]"
                            class="text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text"
                            width="200"
                          >
                            Soft Bounce
                          </td>
                          <td
                            :style="[ {'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'} ]"
                            class="text-right table-border-left-bold font-weight-bold delv-met-th-total"
                          >
                            {{ results.deliverabilityMetricsTotals.softBounces | commaNumber }}
                          </td>
                          <td
                            :style="[ {'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'} ]"
                            class="text-right font-weight-bold delv-met-th-total"
                          >
                            {{ results.deliverabilityMetricsTotals.softBounceRate | percent(2) }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            :style="[
                              { 'color': '#6d2c8a',
                                'text-align': 'left',
                                'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'
                              }]"
                            class="text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold delv-met-th-text"
                            width="200"
                          >
                            Delivered Messages
                          </td>
                          <td
                            :style="[ {'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'} ]"
                            class="text-right table-border-left-bold font-weight-bold delv-met-th-total"
                          >
                            {{ results.deliverabilityMetricsTotals.deliveredMessages | commaNumber }}
                          </td>
                          <td
                            :style="[ {'border-bottom': '2px solid rgba(0, 0, 0, 0.12)'} ]"
                            class="text-right font-weight-bold delv-met-th-total"
                          >
                            {{ results.deliverabilityMetricsTotals.deliveredRate | percent(2) }}
                          </td>
                        </tr>
                      </v-col>
                    </section>
                  </template>
                </v-col>
                <v-col
                  cols="12"
                  :sm="results.mailingMetaData.splits.length > 1 && !pdfMenu ? '3' : '6'"
                >
                  <v-card class="pa-2 ma-2">
                    <pie-chart
                      :chart-data="results.deliverabilityMetricsTotalsPieChart"
                      :options="getChartOptionsPie(`Total Deliverability`)"
                      :height="224"
                      class="pb-2"
                    />
                  </v-card>
                </v-col>
                <v-col
                  v-if="results.mailingMetaData.splits.length > 1"
                  cols="12"
                  :lg="pdfMenu ? '6': '3'"
                  sm="3"
                >
                  <v-card class="pa-2 ma-2">
                    <pie-chart
                      :chart-data="results.sentCountPieChart"
                      :options="getChartOptionsPie(`Sent Count`)"
                      :height="224"
                      class="pb-2"
                    />
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-for="(summary, i) in liveResults.splitData.summaries"
                  :key="summary.mailingName"
                  cols="11"
                >
                  <span
                    class="text-h5 font-weight-bold text-center ml-4 mb-3"
                  >
                    Sending Status: {{ summary.isSendComplete ? 100 : Math.round((summary.sends / summary.sendCount * 100)) }}%
                  </span>
                  <v-progress-linear
                    class="ml-4 mr-4 mb-5"
                    :value="summary.isSendComplete ? 100 : Math.round((summary.sends / summary.sendCount * 100))"
                    :color="$_splitMixin_splitColors[i]"
                    :height="20"
                    :buffer-value="summary.isSendComplete ? 0 : Math.round((summary.sends / summary.sendCount * 100)) + 5"
                    striped
                    stream
                  />
                </v-col>
              </v-row>
              <v-divider
                v-if="!pdfMenu"
                class="my-4"
              />
              <div class="html2pdf__page-break" />
              <section class="title-bar">
                <v-card-subtitle
                  class="grey white--text font-weight-bold text-h5 pa-2 mb-4 title-bar-text"
                  style="display: flex;"
                >
                  Engagement Summary
                  <v-spacer />
                  <!-- <v-tooltip top>
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        v-show="!pdfMenu"
                        icon
                        color="white"
                        v-on="onTooltip"
                        @click="$_downloadFileMixin_downloadReportTable('engagementSummary', `${results.mailingMetaData.campaignNowwOrderNumber}-${results.mailingMetaData.name.replace(/ /g, '')}-EngagementSummary`)"
                      >
                        <v-icon color="green lighten-3">
                          mdi-file-download
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Download Engagement Summary</span>
                  </v-tooltip> -->
                  <v-tooltip top>
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        v-show="!pdfMenu"
                        icon
                        color="white"
                        v-on="onTooltip"
                        @click="$_downloadFileMixin_downloadReportTable('engagementSummaryByDay', `${results.mailingMetaData.campaignNowwOrderNumber}-${results.mailingMetaData.name.replace(/ /g, '')}-EngagementSummaryByDay`)"
                      >
                        <v-icon color="green lighten-3">
                          mdi-file-download
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Download Engagement Summary by Day</span>
                  </v-tooltip>
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        v-show="!pdfMenu"
                        icon
                        color="white"
                        v-on="onTooltip"
                        @click="toggleGrossData = !toggleGrossData"
                      >
                        <v-icon :color="toggleGrossData ? 'light-blue lighten-4' : 'white'">
                          {{ toggleGrossData ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off' }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Toggle Gross/Opt Out Data {{ toggleGrossData ? 'off' : 'on' }}</span>
                  </v-tooltip>
                </v-card-subtitle>
              </section>
              <v-row no-gutters>
                <v-col
                  cols="12"
                >
                  <section class="dist-eng-summ">
                    <v-card class="pa-2 ma-2 text-center">
                      <v-row
                        no-gutters
                        justify="center"
                      >
                        <v-col
                          cols="12"
                        >
                          <div class="text-h5 font-weight-bold pa-2 dist-eng-summ-title">
                            Distinct Engagement Summary
                          </div>
                          <div class="text-h5 pb-2">
                            You delivered messages to <span class="green--text font-weight-bold dist-eng-summ-green">{{ results.distinctMetrics.distinctReceived | commaNumber }}</span> individuals in your mailing.
                          </div>
                          <div
                            class="text-h5 pb-2 text-center"
                          >
                            Of those delivered messages...
                            <div>
                              Your mailing engaged with <span class="green--text font-weight-bold dist-eng-summ-green">{{ results.distinctMetrics.distinctOpens | commaNumber }} ({{ results.distinctMetrics.distinctOpenRate }}%)</span> individuals!
                            </div>
                            <div>
                              You got clicks from <span class="green--text font-weight-bold dist-eng-summ-green">{{ results.distinctMetrics.distinctClicks | commaNumber }} ({{ results.distinctMetrics.distinctClickRate }}%)</span> individuals!
                            </div>
                            <div v-show="toggleGrossData">
                              <span class="grey--text font-weight-bold dist-eng-summ-gray">{{ results.distinctMetrics.distinctOptOuts | commaNumber }} ({{ results.distinctMetrics.distinctOptOutRate }}%)</span> individuals opted out of your emails.
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </section>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-card class="pa-2 ma-2 dist-eng-charts">
                    <bar-chart
                      :chart-data="results.clickToOpenChart"
                      :options="getChartOptionsBarPercent(`Unique Click to Open %`, '', '')"
                      :height="400"
                    />
                    <!-- <v-simple-table
                      id="engagementSummary"
                      dense
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th
                              class="text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold"
                            />
                            <template v-if="results.splitData.deliverabilityMetrics.length > 1">
                              <template v-for="(split, i) in results.splitData.deliverabilityMetrics">
                                <th
                                  :key="i"
                                  class="text-right primary--text font-weight-bold text-subtitle-2 table-border-left table-border-bottom-bold"
                                >
                                  {{ results.mailingMetaData.splits[i].splitLetter }}
                                </th>
                                <th
                                  :key="`${i}-rate`"
                                  class="text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold"
                                >
                                  {{ results.mailingMetaData.splits[i].splitLetter }} Rate
                                </th>
                              </template>
                            </template>
                            <template v-if="results.winnerData.deliverabilityMetrics.length > 0">
                              <template v-for="(split, i) in results.winnerData.deliverabilityMetrics">
                                <th
                                  :key="`w-${i}`"
                                  class="text-right primary--text font-weight-bold text-subtitle-2 table-border-left table-border-bottom-bold"
                                >
                                  Winner ({{ results.winningSplit.splitLetter }})
                                </th>
                                <th
                                  :key="`w-${i}-rate`"
                                  class="text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold"
                                >
                                  Winner ({{ results.winningSplit.splitLetter }}) Rate
                                </th>
                              </template>
                            </template>
                            <th
                              class="text-right primary--text font-weight-bold text-subtitle-2 table-border-left-bold table-border-bottom-bold"
                            >
                              Total
                            </th>
                            <th
                              class="text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold"
                            >
                              Rate
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              class="text-left primary--text font-weight-bold text-subtitle-2"
                              width="175"
                            >
                              Unique Clicks
                            </td>
                            <template v-if="results.splitData.engagementSummary.length > 1">
                              <template v-for="(engagementSummary, i) in results.splitData.engagementSummary">
                                <td
                                  :key="i"
                                  class="text-right table-border-left"
                                >
                                  {{ engagementSummary.uniqueClicks }}
                                </td>
                                <td
                                  :key="`${i}-rate`"
                                  class="text-right"
                                >
                                  {{ engagementSummary.uniqueClickRate | percent(2) }}
                                </td>
                              </template>
                            </template>
                            <template v-if="results.winnerData.engagementSummary.length > 0">
                              <template v-for="(engagementSummary, i) in results.winnerData.engagementSummary">
                                <td
                                  :key="`w-${i}`"
                                  class="text-right table-border-left"
                                >
                                  {{ engagementSummary.uniqueClicks }}
                                </td>
                                <td
                                  :key="`w-${i}-rate`"
                                  class="text-right"
                                >
                                  {{ engagementSummary.uniqueClickRate | percent(2) }}
                                </td>
                              </template>
                            </template>
                            <td
                              class="text-right table-border-left-bold font-weight-bold"
                            >
                              {{ results.engagementSummaryTotals.uniqueClicks }}
                            </td>
                            <td
                              class="text-right font-weight-bold"
                            >
                              {{ results.engagementSummaryTotals.uniqueClickRate | percent(2) }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="text-left primary--text font-weight-bold text-subtitle-2"
                              width="175"
                            >
                              Unique Opens
                            </td>
                            <template v-if="results.splitData.engagementSummary.length > 1">
                              <template v-for="(engagementSummary, i) in results.splitData.engagementSummary">
                                <td
                                  :key="i"
                                  class="text-right table-border-left"
                                >
                                  {{ engagementSummary.uniqueOpens }}
                                </td>
                                <td
                                  :key="`${i}-rate`"
                                  class="text-right"
                                >
                                  {{ engagementSummary.uniqueOpenRate | percent(2) }}
                                </td>
                              </template>
                            </template>
                            <template v-if="results.winnerData.engagementSummary.length > 0">
                              <template v-for="(engagementSummary, i) in results.winnerData.engagementSummary">
                                <td
                                  :key="`w-${i}`"
                                  class="text-right table-border-left"
                                >
                                  {{ engagementSummary.uniqueOpens }}
                                </td>
                                <td
                                  :key="`w-${i}-rate`"
                                  class="text-right"
                                >
                                  {{ engagementSummary.uniqueOpenRate | percent(2) }}
                                </td>
                              </template>
                            </template>
                            <td
                              class="text-right table-border-left-bold font-weight-bold"
                            >
                              {{ results.engagementSummaryTotals.uniqueOpens }}
                            </td>
                            <td
                              class="text-right font-weight-bold"
                            >
                              {{ results.engagementSummaryTotals.uniqueOpenRate | percent(2) }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="text-left primary--text font-weight-bold text-subtitle-2"
                              width="175"
                            >
                              Click to Open
                            </td>
                            <template v-if="results.splitData.engagementSummary.length > 1">
                              <template v-for="(engagementSummary, i) in results.splitData.engagementSummary">
                                <td
                                  :key="`${i}-rate`"
                                  class="text-right table-border-left"
                                  colspan="2"
                                >
                                  {{ engagementSummary.clickToOpen | percent(2) }}
                                </td>
                              </template>
                            </template>
                            <template v-if="results.winnerData.engagementSummary.length > 0">
                              <template v-for="(engagementSummary, i) in results.winnerData.engagementSummary">
                                <td
                                  :key="`w-${i}-rate`"
                                  class="text-right table-border-left"
                                  colspan="2"
                                >
                                  {{ engagementSummary.clickToOpen | percent(2) }}
                                </td>
                              </template>
                            </template>
                            <td
                              class="text-right table-border-left-bold font-weight-bold"
                              colspan="2"
                            >
                              {{ results.engagementSummaryTotals.clickToOpen | percent(2) }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table> -->
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  md="9"
                >
                  <v-card class="pa-2 ma-2 dist-eng-charts">
                    <line-chart
                      :chart-data="engagementMetricsDataSplits"
                      :options="getChartOptionsLine(results.mailingMetaData.winningDistribution > 0 ? `Split Clicks/Opens from ${$options.filters.displayDateFormat($_global_parseAsUtc(results.mailingMetaData.scheduleDate))}` : `Clicks/Opens from ${$options.filters.displayDateFormat($_global_parseAsUtc(results.mailingMetaData.scheduleDate))}`)"
                    />
                  </v-card>
                </v-col>
                <v-col
                  v-if="results.mailingMetaData.winningDistribution > 0"
                  cols="12"
                >
                  <div class="html2pdf__page-break" />
                  <v-card
                    :style="pdfMenu ? {'margin-top': '20px'} : ''"
                    class="pa-2 ma-2 dist-eng-charts"
                  >
                    <line-chart
                      :chart-data="engagementMetricsDataWinner"
                      :options="getChartOptionsLine(`${results.winnerLabel} Clicks/Opens from ${$options.filters.displayDateFormat($_global_parseAsUtc(results.mailingMetaData.winningScheduleDate))}`)"
                    />
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-divider class="my-4" />
                  <section class="eng-summ-table">
                    <v-card class="pa-2 ma-2">
                      <v-simple-table
                        id="engagementSummaryByDay"
                        dense
                      >
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th
                                :style="[{ 'color': '#6d2c8a', 'text-align': 'left'}]"
                                class="text-left primary--text font-weight-bold text-subtitle-2 eng-summ-table-th"
                                :class="{ 'table-border-bottom-bold': results.mailingMetaData.splits.length <= 1 }"
                              >
                              </th>
                              <th
                                v-for="(metric, index) in engagementMetricsComputed"
                                :key="index"
                                :style="{ 'color': '#6d2c8a'}"
                                class="text-right primary--text font-weight-bold text-subtitle-2 table-border-left-bold eng-summ-table-th"
                                :class="{ 'table-border-bottom-bold': results.mailingMetaData.splits.length <= 1 }"
                                :colspan="getEngagementHeaderColspan()"
                              >
                                <!-- :class="{ 'winningCriteriaHeader': metric === $_winningCriteria_display(results.mailingMetaData.winningCriteria) }" -->
                                <v-icon
                                  v-if="!pdfMenu && results.mailingMetaData.splits.length === 1"
                                  :color="engagementMetricsDataSplits.datasets[index].backgroundColor"
                                >
                                  mdi-square-rounded
                                </v-icon>
                                <v-tooltip
                                  v-if="results.mailingMetaData.winningDistribution > 0 && metric === $_winningCriteria_display(results.mailingMetaData.winningCriteria)"
                                  top
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-icon
                                      class="mr-2 mb-1"
                                      :color="$_splitMixin_winningColor"
                                      v-on="on"
                                    >
                                      mdi-crown
                                    </v-icon>
                                  </template>
                                  <span>Winning Criteria</span>
                                </v-tooltip>
                                {{ metric }}
                              </th>
                            </tr>
                            <tr v-if="results.mailingMetaData.splits.length > 1">
                              <th
                                :style="[{ 'color': '#6d2c8a', 'text-align': 'left'}]"
                                class="text-left primary--text font-weight-bold text-subtitle-2 eng-summ-table-th"
                              >
                              Date
                              </th>
                              <template v-for="(metrics, n) in engagementMetricsComputed">
                                <template v-for="(split, i) in results.mailingMetaData.splits">
                                  <th
                                    :key="`${n}-${i}`"
                                    :style="[{ 'color': '#6d2c8a', 'text-align': 'right'}]"
                                    class="text-right primary--text font-weight-bold text-subtitle-2 eng-summ-table-th"
                                    :class="{ 'table-border-left-bold' : i === 0 }"
                                  >
                                    <v-icon
                                      v-if="!pdfMenu"
                                      :color="engagementMetricsDataSplits.datasets[i * engagementMetricsComputed.length + n].backgroundColor"
                                    >
                                      mdi-square-rounded
                                    </v-icon>
                                    {{ split.splitLetter }}
                                  </th>
                                </template>
                                <th
                                  v-if="results.mailingMetaData.winningDistribution > 0"
                                  :key="`w-${n}`"
                                  :style="[{ 'color': '#6d2c8a', 'text-align': 'right'}]"
                                  class="text-right primary--text font-weight-bold text-subtitle-2 eng-summ-table-th"
                                >
                                  <v-icon
                                    v-if="!pdfMenu"
                                    :color="engagementMetricsDataWinner.datasets[n].backgroundColor"
                                  >
                                    mdi-square-rounded
                                  </v-icon>
                                  {{ results.winnerLabel.replace('Winner', 'W') }}
                                </th>
                                <th
                                  :key="`t-${n}`"
                                  :style="[{ 'color': '#6d2c8a', 'text-align': 'right'}]"
                                  class="text-right primary--text font-weight-bold text-subtitle-2 table-border-left eng-summ-table-th"
                                >
                                  Total
                                </th>
                              </template>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(label, i) in engagementMetricsDataSplits.labels"
                              :key="`${label}`"
                              style="display: none;"
                            >
                              <td
                                class="text-left primary--text font-weight-bold text-subtitle-2"
                              >
                                {{ label }}
                              </td>
                              <template
                                v-for="(metric) in engagementMetricsComputed"
                              >
                                <td
                                  v-for="(split, splitIndex) in results.mailingMetaData.splits"
                                  :key="`${metric}-${split.splitLetter}-${i}`"
                                  class="text-right"
                                  :class="{ 'table-border-left-bold' : splitIndex === 0 }"
                                >
                                  {{ engagementMetricsDataSplits.datasets.find(x => x.label === `${split.splitLetter} - ${metric}`).data[i] | commaNumber }}
                                </td>
                                <td
                                  v-if="results.mailingMetaData.winningDistribution > 0"
                                  :key="`w-${metric}`"
                                  class="text-right"
                                >
                                  {{ engagementMetricsDataWinner.datasets.find(x => x.label === `${results.winnerLabel} - ${metric}`).data[i] | commaNumber }}
                                </td>
                                <td
                                  v-if="results.mailingMetaData.splits.length > 1"
                                  :key="`t-${metric}`"
                                  class="text-right font-weight-bold table-border-left"
                                >
                                  {{ getTotalRowForEngagement(i, metric) | commaNumber }}
                                </td>
                              </template>
                            </tr>
                            <tr>
                              <td
                                :style="[{ 'color': '#6d2c8a', 'text-align': 'left'}]"
                                class="text-left primary--text font-weight-bold text-subtitle-2 table-border-top-bold eng-summ-table-row-top-border"
                              >
                                Total
                              </td>
                              <template
                                v-for="(metric) in engagementMetricsComputed"
                              >
                                <td
                                  v-for="(split, splitIndex) in results.mailingMetaData.splits"
                                  :key="`t-${metric}-${split.splitLetter}`"
                                  class="text-right table-border-top-bold font-weight-bold eng-summ-table-row-top-border"
                                  :class="{ 'table-border-left-bold' : splitIndex === 0 }"
                                >
                                  {{ engagementMetricsDataSplits.datasets.find(x => x.label === `${split.splitLetter} - ${metric}`).data.reduce((prev, next) => prev + next) | commaNumber }}
                                </td>
                                <td
                                  v-if="results.mailingMetaData.winningDistribution > 0"
                                  :key="`w-${metric}`"
                                  class="text-right table-border-top-bold font-weight-bold eng-summ-table-row-top-border"
                                >
                                  {{ engagementMetricsDataWinner.datasets.find(x => x.label === `${results.winnerLabel} - ${metric}`).data.reduce((prev, next) => prev + next) | commaNumber }}
                                </td>
                                <td
                                  v-if="results.mailingMetaData.splits.length > 1"
                                  :key="`tt-${metric}`"
                                  class="text-right table-border-top-bold font-weight-bold table-border-left eng-summ-table-head-total"
                                >
                                  {{ getTotalTableForEngagement(metric) | commaNumber }}
                                </td>
                              </template>
                            </tr>
                            <tr>
                              <td
                                :style="[{ 'color': '#6d2c8a', 'text-align': 'left'}]"
                                class="text-left primary--text font-weight-bold text-subtitle-2 eng-summ-table-th"
                              >
                                %
                              </td>
                              <template v-if="results.mailingMetaData.splits.length > 1 && pdfMenu">
                                <template
                                  v-for="(metric) in engagementMetricsComputed"
                                >
                                  <td
                                    v-for="(split, splitIndex) in results.mailingMetaData.splits"
                                    :key="`%-${metric}-${splitIndex}`"
                                    class="text-right font-weight-bold eng-summ-table-td-total"
                                    :class="{ 'table-border-left-bold' : splitIndex === 0 }"
                                  >
                                    {{ getEngagementPercent(engagementMetricsDataSplits.datasets, `${split.splitLetter} - ${metric}`, results.splitData.engagementSummary[splitIndex].deliveredMessages) | percent(0) }}
                                  </td>
                                  <td
                                    v-if="results.mailingMetaData.winningDistribution > 0"
                                    :key="`w-${metric}`"
                                    class="text-right font-weight-bold eng-summ-table-td-total"
                                  >
                                    {{ getEngagementPercent(engagementMetricsDataWinner.datasets, `${results.winnerLabel} - ${metric}`, results.winnerData.engagementSummary[0].deliveredMessages) | percent(0) }}
                                  </td>
                                  <td
                                    v-if="results.mailingMetaData.splits.length > 1"
                                    :key="`tt-${metric}`"
                                    class="text-right font-weight-bold table-border-left eng-summ-table-bottom-total"
                                  >
                                    {{ getTotalTableForEngagementPercent(metric) | percent(0) }}
                                  </td>
                                </template>
                              </template>
                              <template v-else>
                                <template
                                  v-for="(metric) in engagementMetricsComputed"
                                >
                                  <td
                                    v-for="(split, splitIndex) in results.mailingMetaData.splits"
                                    :key="`%-${metric}-${splitIndex}`"
                                    class="text-right font-weight-bold"
                                    :class="{ 'table-border-left-bold' : splitIndex === 0 }"
                                  >
                                    {{ getEngagementPercent(engagementMetricsDataSplits.datasets, `${split.splitLetter} - ${metric}`, results.splitData.engagementSummary[splitIndex].deliveredMessages) | percent(2) }}
                                  </td>
                                  <td
                                    v-if="results.mailingMetaData.winningDistribution > 0"
                                    :key="`w-${metric}`"
                                    class="text-right font-weight-bold"
                                  >
                                    {{ getEngagementPercent(engagementMetricsDataWinner.datasets, `${results.winnerLabel} - ${metric}`, results.winnerData.engagementSummary[0].deliveredMessages) | percent(2) }}
                                  </td>
                                  <td
                                    v-if="results.mailingMetaData.splits.length > 1"
                                    :key="`tt-${metric}`"
                                    class="text-right font-weight-bold table-border-left"
                                  >
                                    {{ getTotalTableForEngagementPercent(metric) | percent(2) }}
                                  </td>
                                </template>
                              </template>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </section>
                </v-col>
              </v-row>
              <v-divider class="my-4" />
              <div class="html2pdf__page-break" />
              <section class="title-bar">
                <v-card-subtitle
                  class="grey white--text font-weight-bold text-h5 pa-2 mb-4 title-bar-text"
                  style="display: flex;"
                >
                  Reading Engagement
                  <v-spacer />
                  <v-tooltip top>
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        v-show="!pdfMenu"
                        icon
                        color="white"
                        v-on="onTooltip"
                        @click="$_downloadFileMixin_downloadReportChart(results.readingEnvironmentsChart, '%', `${results.mailingMetaData.campaignNowwOrderNumber}-${results.mailingMetaData.name.replace(/ /g, '')}-ReadingEnvironments`)"
                      >
                        <v-icon color="green lighten-3">
                          mdi-file-download
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Download Reading Environments</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        v-show="!pdfMenu"
                        icon
                        color="white"
                        v-on="onTooltip"
                        @click="$_downloadFileMixin_downloadReportChart(results.emailClientDeviceSummary, '%', `${results.mailingMetaData.campaignNowwOrderNumber}-${results.mailingMetaData.name.replace(/ /g, '')}-EmailClientsUsed`)"
                      >
                        <v-icon color="green lighten-3">
                          mdi-file-download
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Download Email Clients Used</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        v-show="!pdfMenu"
                        icon
                        color="white"
                        v-on="onTooltip"
                        @click="$_downloadFileMixin_downloadReportChart(results.viewingEngagementTotalSummary, '%', `${results.mailingMetaData.campaignNowwOrderNumber}-${results.mailingMetaData.name.replace(/ /g, '')}-TimeSpentViewing`)"
                      >
                        <v-icon color="green lighten-3">
                          mdi-file-download
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Download Time Spent Viewing</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="results.mailingMetaData.splits.length > 1"
                    top
                  >
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        v-show="!pdfMenu"
                        icon
                        color="white"
                        v-on="onTooltip"
                        @click="$_downloadFileMixin_downloadReportTable('readingEngagement', `${results.mailingMetaData.campaignNowwOrderNumber}-${results.mailingMetaData.name.replace(/ /g, '')}-TimeSpentViewingTable`)"
                      >
                        <v-icon color="green lighten-3">
                          mdi-file-download
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Download Time Spent Viewing Table</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="showAgeData"
                    top
                  >
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn
                        v-show="!pdfMenu"
                        icon
                        color="white"
                        v-on="onTooltip"
                        @click="$_downloadFileMixin_downloadReportChart(results.ageEngagementChart, '%', `${results.mailingMetaData.campaignNowwOrderNumber}-${results.mailingMetaData.name.replace(/ /g, '')}-AgeEngagement`, true)"
                      >
                        <v-icon color="green lighten-3">
                          mdi-file-download
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Download Age Engagement</span>
                  </v-tooltip>
                </v-card-subtitle>
              </section>
              <span
                v-if="validateReadingEngagement(results.splitData.viewingEngagementSummary)"
                class="text-h5 font-weight-light"
                style="color:#8E24AA; font-size:24px"
              >
                Crunching the numbers, check back tomorrow...
              </span>
              <section class="reading-eng">
                <v-card
                  class="pa-2 ma-2 dist-eng-charts"
                >
                  <v-row no-gutters>
                    <v-col cols="12">
                      <line-chart
                        :chart-data="results.splitData.viewingEngagementSummary"
                        :options="getChartOptionsLinePercent(`Total Viewing Engagement`, `% of Open Audience`, `Time spent viewing (seconds)`)"
                        class="pb-2"
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </section>
              <v-divider
                v-if="!pdfMenu"
                class="my-4"
              />
              <div
                class="html2pdf__page-break"
              />
              <v-row
                no-gutters
              >
                <v-col
                  cols="12"
                  :md="pdfMenu ? '6': '4'"
                >
                  <v-card class="pa-2 ma-2 dist-eng-charts">
                    <bar-chart
                      :chart-data="results.readingEnvironmentsChart"
                      :options="getChartOptionsBarPercent(`Reading Environments`, '% of Gross Opens', 'Environment')"
                      :height="350"
                    />
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  :md="pdfMenu ? '6': '4'"
                >
                  <v-card class="pa-2 ma-2">
                    <bar-chart
                      :chart-data="results.emailClientDeviceSummary"
                      :options="getChartOptionsBarPercent(`Email Clients Used`, '% of Gross Opens', 'Email Client')"
                      :height="350"
                    />
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  :md="pdfMenu ? '6': '4'"
                >
                  <v-card
                    class="pa-2 ma-2 dist-eng-charts"
                    :style="[pdfMenu ? {'display': 'inline-table'} : '']"
                  >
                    <bar-chart
                      :chart-data="results.viewingEngagementTotalSummary"
                      :options="getChartOptionsBarPercent(`Time Spent Viewing`, '% of Gross Opens', 'Read Duration')"
                      :height="results.mailingMetaData.splits.length > 1 ? 218 : 350"
                    />
                    <v-simple-table
                      v-if="results.mailingMetaData.splits.length > 1"
                      id="readingEngagement"
                      dense
                      style="flex: auto;"
                      class="px-8"
                      :class="{ 'pt-4': pdfMenu }"
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th
                              class="text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold reading-eng-th-text"
                            />
                            <th
                              v-for="(split, i) in results.splitData.litmusReadTable"
                              :key="i"
                              class="text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold reading-eng-th-text"
                              :class="{ 'table-border-left-bold': i === 0 }"
                            >
                              {{ results.mailingMetaData.splits[i].splitLetter }}
                            </th>
                            <th
                              v-if="results.mailingMetaData.winningDistribution > 0"
                              class="text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold reading-eng-th-text"
                            >
                              {{ results.winnerLabel.replace('Winner', 'W') }}
                            </th>
                            <th
                              class="text-right primary--text font-weight-bold text-subtitle-2 table-border-left-bold table-border-bottom-bold reading-eng-th-text"
                            >
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(metric, index) in readTimeMetrics"
                            :key="index"
                          >
                            <td
                              :style="{ 'color': '#6d2c8a'}"
                              class="text-left primary--text font-weight-bold text-subtitle-2 reading-eng-trtitle-text"
                            >
                              <v-icon
                                :color="results.viewingEngagementTotalSummary.datasets[0].backgroundColor[index]"
                              >
                                mdi-square-rounded
                              </v-icon>
                              {{ metric.display }}
                            </td>
                            <template
                              v-for="(litmusRow, litmusIndex) in results.splitData.litmusReadTable"
                            >
                              <td
                                :key="`${litmusIndex}`"
                                class="text-right reading-eng-td-row"
                                :class="{ 'table-border-left-bold': litmusIndex === 0 }"
                              >
                                {{ litmusRow.find(x => x.device == 'Total')[metric.rate] | percent }}
                              </td>
                            </template>
                            <template
                              v-for="(litmusRow, litmusIndex) in results.winnerData.litmusReadTable"
                            >
                              <td
                                :key="`w-${litmusIndex}`"
                                class="text-right"
                              >
                                {{ litmusRow.find(x => x.device == 'Total')[metric.rate] | percent }}
                              </td>
                            </template>
                            <template
                              v-for="(litmusRow, litmusIndex) in results.litmusReadTable"
                            >
                              <td
                                :key="`t-${litmusIndex}`"
                                class="text-right font-weight-bold table-border-left-bold reading-eng-td-total"
                              >
                                {{ litmusRow[metric.value] | percent }}
                              </td>
                            </template>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </v-col>
              </v-row>
              <div
                v-if="showAgeData || showLinkClicks"
                class="html2pdf__page-break"
              />
              <v-row no-gutters>
                <v-col
                  v-if="showAgeData"
                  cols="12"
                >
                  <v-card class="pa-2 ma-2 dist-eng-charts">
                    <bar-chart
                      :chart-data="results.ageEngagementChart"
                      :options="getChartOptionsBarPercent2(`Age Engagement`, `%`, `Age Range`)"
                      class="pb-2"
                    />
                  </v-card>
                </v-col>
              </v-row>
              <v-divider class="my-4" />
              <div
                v-if="showLinkClicks"
                class="html2pdf__page-break"
              />
              <template v-if="showLinkClicks">
                <section class="title-bar">
                  <v-card-subtitle
                    class="grey white--text font-weight-bold text-h5 pa-2 mb-4 title-bar-text"
                    style="display: flex;"
                  >
                    Link Clicks
                    <template v-if="results.areSplitLinksSame">
                      <v-spacer />
                      <v-tooltip top>
                        <template v-slot:activator="{ on: onTooltip }">
                          <v-btn
                            v-show="!pdfMenu"
                            icon
                            color="white"
                            v-on="onTooltip"
                            @click="$_downloadFileMixin_downloadReportTable('linkClicksSame', `${results.mailingMetaData.campaignNowwOrderNumber}-${results.mailingMetaData.name.replace(/ /g, '')}-LinkClicks`)"
                          >
                            <v-icon color="green lighten-3">
                              mdi-file-download
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Download Link Clicks</span>
                      </v-tooltip>
                    </template>
                    <template v-else>
                      <v-spacer />
                      <v-tooltip
                        v-for="(chart, i) in results.splitData.linkClicksSummary"
                        :key="i"
                        top
                      >
                        <template v-slot:activator="{ on: onTooltip }">
                          <v-btn
                            v-show="!pdfMenu"
                            icon
                            color="white"
                            v-on="onTooltip"
                            @click="$_downloadFileMixin_downloadReportTable(`${i}-linkClicks`, `${results.mailingMetaData.campaignNowwOrderNumber}-${results.mailingMetaData.name.replace(/ /g, '')}-${$_splitMixin_splitLetters[i]}LinkClicks`)"
                          >
                            <v-icon color="green lighten-3">
                              mdi-file-download
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Download {{ $_splitMixin_splitLetters[i] }} Link Clicks</span>
                      </v-tooltip>
                      <v-tooltip
                        v-for="(chart, i) in results.winnerData.linkClicksSummary"
                        :key="`w-${i}`"
                        top
                      >
                        <template v-slot:activator="{ on: onTooltip }">
                          <v-btn
                            v-show="!pdfMenu"
                            icon
                            color="white"
                            v-on="onTooltip"
                            @click="$_downloadFileMixin_downloadReportTable(`${i}-linkClicks`, `${results.mailingMetaData.campaignNowwOrderNumber}-${results.mailingMetaData.name.replace(/ /g, '')}-${results.winnerLabel.replace(/ /g, '').replace('(', '').replace(')', '')}LinkClicks`)"
                          >
                            <v-icon color="green lighten-3">
                              mdi-file-download
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Download {{ results.winnerLabel }} Link Clicks</span>
                      </v-tooltip>
                    </template>
                  </v-card-subtitle>
                </section>
                <v-row no-gutters>
                  <template v-if="results.areSplitLinksSame">
                    <v-col cols="12">
                      <v-card class="pa-2 ma-2 dist-eng-charts">
                        <bar-chart
                          :chart-data="results.linkClicksSummarySame"
                          :options="getChartOptionsBarPercent2(`Link Click Summary`, `Link Click % of Delivered Messages `, `Link Name`)"
                        />
                      </v-card>
                    </v-col>
                    <v-col cols="12">
                      <v-card class="pa-2 ma-2">
                        <section class="link-click-table">
                          <v-simple-table
                            id="linkClicksSame"
                            dense
                            style="table-layout: fixed; width: 100%;"
                          >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th
                                    :style="[{ 'color': '#6d2c8a', 'text-align': 'left'}]"
                                    class="text-left primary--text font-weight-bold text-subtitle-2 link-click-table-th"
                                  />
                                  <th
                                    v-for="(links, linksIndex) in results.splitData.linkClicksSummary"
                                    :key="linksIndex"
                                    :style="[{ 'color': '#6d2c8a', 'text-align': 'right', 'border-left': '2px solid rgba(0, 0, 0, 0.36)'}]"
                                    class="text-right primary--text font-weight-bold text-subtitle-2 table-border-left  link-click-table-th"
                                    :class="{ 'table-border-left-bold': linksIndex === 0 }"
                                    colspan="2"
                                  >
                                    {{ results.mailingMetaData.winningDistribution > 0 && linksIndex === results.splitData.linkClicksSummary.length - 1 ? results.winnerLabel : $_splitMixin_splitLetters[linksIndex] }} Clicks
                                  </th>
                                  <th
                                    v-if="results.splitData.linkClicksSummary.length > 1"
                                    :style="[{ 'color': '#6d2c8a', 'text-align': 'right', 'border-left': '2px solid rgba(0, 0, 0, 0.36)' }]"
                                    class="text-right primary--text font-weight-bold text-subtitle-2 table-border-left-bold link-click-table-th"
                                    colspan="2"
                                  >
                                    Total Clicks
                                  </th>
                                  <th
                                    :style="[{ 'color': '#6d2c8a', 'text-align': 'left', 'border-left': '2px solid rgba(0, 0, 0, 0.36)' }]"
                                    class="text-left primary--text font-weight-bold text-subtitle-2 table-border-left-bold link-click-table-th"
                                  />
                                </tr>
                                <tr>
                                  <th
                                    :style="[{ 'color': '#6d2c8a', 'text-align': 'left', 'border-bottom': '2px solid rgba(0, 0, 0, 0.36)' }]"
                                    class="text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold link-click-table-th"
                                  >
                                    Link Name
                                  </th>
                                  <template
                                    v-for="(links, linksIndex) in results.splitData.linkClicksSummary"
                                  >
                                    <th
                                      :key="`g-${linksIndex}`"
                                      :style="[{ 'color': '#6d2c8a', 'text-align': 'right', 'border-bottom': '2px solid rgba(0, 0, 0, 0.36)', 'border-left': '2px solid rgba(0, 0, 0, 0.36)' }]"
                                      class="text-right primary--text font-weight-bold text-subtitle-2 table-border-left table-border-bottom-bold link-click-table-th"
                                      :class="{ 'table-border-left-bold': linksIndex === 0 }"
                                    >
                                      Gross
                                    </th>
                                    <th
                                      :key="`u-${linksIndex}`"
                                      :style="[{ 'color': '#6d2c8a', 'text-align': 'right', 'border-bottom': '2px solid rgba(0, 0, 0, 0.36)' }]"
                                      class="text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold link-click-table-th"
                                    >
                                      Unique
                                    </th>
                                  </template>
                                  <th
                                    v-if="results.splitData.linkClicksSummary.length > 1"
                                    :style="[{ 'color': '#6d2c8a', 'text-align': 'right', 'border-bottom': '2px solid rgba(0, 0, 0, 0.36)', 'border-left': '2px solid rgba(0, 0, 0, 0.36)' }]"
                                    class="text-right primary--text font-weight-bold text-subtitle-2 table-border-left-bold table-border-bottom-bold link-click-table-th"
                                  >
                                    Gross
                                  </th>
                                  <th
                                    v-if="results.splitData.linkClicksSummary.length > 1"
                                    :style="[{ 'color': '#6d2c8a', 'text-align': 'right', 'border-bottom': '2px solid rgba(0, 0, 0, 0.36)' }]"
                                    class="text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold link-click-table-th"
                                  >
                                    Unique
                                  </th>
                                  <th
                                    v-show="!pdfMenu"
                                    :style="[{ 'color': '#6d2c8a', 'text-align': 'left', 'border-bottom': '2px solid rgba(0, 0, 0, 0.36)', 'border-left': '2px solid rgba(0, 0, 0, 0.36)' }]"
                                    class="text-left primary--text font-weight-bold text-subtitle-2 table-border-left-bold table-border-bottom-bold link-click-table-th"
                                  >
                                    Url
                                  </th>
                                </tr>
                              </thead>
                              <tbody :style="[pdfMenu ? {'word-break': 'break-all'} : '' ]">
                                <tr
                                  v-for="(keyValue, i) in Object.entries(results.linkNameToUrlSame)"
                                  :key="keyValue[0]"
                                >
                                  <template
                                    v-if="pdfMenu"
                                  >
                                    <td :style="{'margin-left': '10px'}">
                                      <a
                                        :href="keyValue[1]"
                                        target="_blank"
                                      >
                                        {{ keyValue[0] }}
                                      </a>
                                    </td>
                                  </template>
                                  <template v-else>
                                    <td>{{ keyValue[0] }}</td>
                                  </template>
                                  <!-- <template
                            v-for="(split, splitIndex) in results.mailingMetaData.splits"
                          > -->
                                  <template
                                    v-for="(links, linksIndex) in results.linkClicksSummarySame.datasets2"
                                  >
                                    <td
                                      :key="`gu-${linksIndex}`"
                                      :style="{'border-left': '2px solid rgba(0, 0, 0, 0.36)'}"
                                      class="text-right link-click-table-tb-light"
                                      :class="{ 'table-border-left': linksIndex % 2 === 0,'table-border-left-bold': linksIndex === 0 }"
                                    >
                                      {{ links.data[i] | commaNumber }}
                                    </td>
                                    <!-- <td
                              :key="`u-${linksIndex}`"
                              class="text-right"
                            >
                              {{ links.data[i] }}
                            </td> -->
                                  </template>
                                  <!-- </template> -->
                                  <td
                                    v-if="results.linkClicksSummarySame.datasets2.length > 2"
                                    :style="{'border-left': '2px solid rgba(0, 0, 0, 0.36)'}"
                                    class="text-right table-border-left-bold font-weight-bold link-click-table-tb"
                                  >
                                    {{ results.linkClicksSummarySame.datasets2.filter(function(element, index, array) {
                                      return (index % 2 === 0);
                                    }).reduce(function (accumulator, current) {
                                      return accumulator + current.data[i];
                                    }, 0) }}
                                  </td>
                                  <td
                                    v-if="results.linkClicksSummarySame.datasets2.length > 2"
                                    class="text-right font-weight-bold link-click-table-tb"
                                  >
                                    {{ results.linkClicksSummarySame.datasets2.filter(function(element, index, array) {
                                      return (index % 2 !== 0);
                                    }).reduce(function (accumulator, current) {
                                      return accumulator + current.data[i];
                                    }, 0) }}
                                  </td>
                                  <td
                                    v-show="!pdfMenu"
                                    :style="{'border-left': '2px solid rgba(0, 0, 0, 0.36)'}"
                                    class="table-border-left-bold"
                                    style="word-wrap: break-word;"
                                  >
                                    <a
                                      v-show="!pdfMenu"
                                      :href="keyValue[1]"
                                      target="_blank"
                                      style="word-wrap: break-word;"
                                    >
                                      {{ keyValue[1] }}
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </section>
                      </v-card>
                    </v-col>
                  </template>
                  <template v-else>
                    <v-col
                      v-for="(chart, i) in results.splitData.linkClicksSummary"
                      :key="i"
                      cols="12"
                    >
                      <v-card class="pa-2 ma-2 dist-eng-charts">
                        <bar-chart
                          :chart-data="chart"
                          :options="getChartOptionsBarPercent2(`${$_splitMixin_splitLetters[i]} Link Click Summary`, `Link Click % of Delivered Messages `, `Link Name`)"
                        />
                        <v-divider class="my-4" />
                        <v-simple-table
                          :id="`${i}-linkClicks`"
                          dense
                          style="table-layout: fixed; width: 100%;"
                        >
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th
                                  :style="[{ 'color': '#6d2c8a', 'text-align': 'left', 'border-bottom': '2px solid rgba(0, 0, 0, 0.36)' }]"
                                  class="text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold link-click-table-th"
                                >
                                  Link Name
                                </th>
                                <th
                                  :style="[{ 'color': '#6d2c8a', 'text-align': 'right', 'border-bottom': '2px solid rgba(0, 0, 0, 0.36)', 'border-left': '2px solid rgba(0, 0, 0, 0.36)' }]"
                                  class="text-right primary--text font-weight-bold text-subtitle-2 table-border-left-bold table-border-bottom-bold link-click-table-th"
                                >
                                  Gross
                                </th>
                                <th
                                  :style="[{ 'color': '#6d2c8a', 'text-align': 'right', 'border-bottom': '2px solid rgba(0, 0, 0, 0.36)' }]"
                                  class="text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold link-click-table-th"
                                >
                                  Unique
                                </th>
                                <th
                                  v-show="!pdfMenu"
                                  :style="[{ 'color': '#6d2c8a', 'text-align': 'left', 'border-bottom': '2px solid rgba(0, 0, 0, 0.36)', 'border-left': '2px solid rgba(0, 0, 0, 0.36)' }]"
                                  class="text-left primary--text font-weight-bold text-subtitle-2 table-border-left-bold table-border-bottom-bold link-click-table-th"
                                >
                                  Url
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(keyValue, keyValueIndex) in Object.entries(results.splitData.linkNameToUrl[i])"
                                :key="keyValue[0]"
                              >
                                <td>{{ keyValue[0] }}</td>
                                <td
                                  :style="[{'text-align': 'right', 'border-left': '2px solid rgba(0, 0, 0, 0.36)' }]"
                                  class="text-right table-border-left table-border-left-bold"
                                >
                                  {{ chart.datasets2[0].data[keyValueIndex] | commaNumber }}
                                </td>
                                <td
                                  :style="{'text-align': 'right' }"
                                  class="text-right"
                                >
                                  {{ chart.datasets2[1].data[keyValueIndex] | commaNumber }}
                                </td>
                                <td
                                  :style="{ 'border-left': '2px solid rgba(0, 0, 0, 0.36)' }"
                                  class="table-border-left-bold"
                                  style="word-wrap: break-word;"
                                >
                                  <a
                                    v-show="!pdfMenu"
                                    :href="keyValue[1]"
                                    target="_blank"
                                    style="word-wrap: break-word;"
                                  >
                                    {{ keyValue[1] }}
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card>
                      <v-divider class="my-4" />
                      <div class="html2pdf__page-break" />
                    </v-col>
                    <v-col
                      v-for="(chart, i) in results.winnerData.linkClicksSummary"
                      :key="`w-${i}`"
                      cols="12"
                    >
                      <v-card class="pa-2 ma-2 dist-eng-charts">
                        <bar-chart
                          :chart-data="chart"
                          :options="getChartOptionsBarPercent2(`${results.winnerLabel} Link Click Summary`, `Link Click % of Delivered Messages `, `Link Name`)"
                          class="pb-2"
                        />
                        <v-divider class="my-4" />
                        <v-simple-table
                          :id="`w-${i}-linkClicks`"
                          dense
                          style="table-layout: fixed; width: 100%;"
                        >
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th
                                  :style="[{ 'color': '#6d2c8a', 'text-align': 'left', 'border-bottom': '2px solid rgba(0, 0, 0, 0.36)' }]"
                                  class="text-left primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold link-click-table-th"
                                >
                                  Link Name
                                </th>
                                <th
                                  :style="[{ 'color': '#6d2c8a', 'text-align': 'right', 'border-left': '2px solid rgba(0, 0, 0, 0.36)', 'border-bottom': '2px solid rgba(0, 0, 0, 0.36)' }]"
                                  class="text-right primary--text font-weight-bold text-subtitle-2 table-border-left-bold table-border-bottom-bold link-click-table-th"
                                >
                                  Gross
                                </th>
                                <th
                                  :style="[{ 'color': '#6d2c8a', 'text-align': 'right', 'border-bottom': '2px solid rgba(0, 0, 0, 0.36)' }]"
                                  class="text-right primary--text font-weight-bold text-subtitle-2 table-border-bottom-bold link-click-table-th"
                                >
                                  Unique
                                </th>
                                <th
                                  v-show="!pdfMenu"
                                  :style="[{ 'color': '#6d2c8a', 'text-align': 'left', 'border-left': '2px solid rgba(0, 0, 0, 0.36)', 'border-bottom': '2px solid rgba(0, 0, 0, 0.36)' }]"
                                  class="text-left primary--text font-weight-bold text-subtitle-2 table-border-left-bold table-border-bottom-bold link-click-table-th"
                                >
                                  Url
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(keyValue, keyValueIndex) in Object.entries(results.winnerData.linkNameToUrl[i])"
                                :key="keyValue[0]"
                              >
                                <td>{{ keyValue[0] }}</td>
                                <td
                                  :style="[{'text-align': 'right', 'border-left': '2px solid rgba(0, 0, 0, 0.36)' }]"
                                  class="text-right table-border-left table-border-left-bold"
                                >
                                  {{ chart.datasets2[0].data[keyValueIndex] | commaNumber }}
                                </td>
                                <td
                                  class="text-right"
                                >
                                  {{ chart.datasets2[1].data[keyValueIndex] | commaNumber }}
                                </td>
                                <td
                                  :style="{ 'border-left': '2px solid rgba(0, 0, 0, 0.36)' }"
                                  class="table-border-left-bold"
                                  style="word-wrap: break-word;"
                                >
                                  <a
                                    v-show="!pdfMenu"
                                    :href="keyValue[1]"
                                    target="_blank"
                                    style="word-wrap: break-word;"
                                  >
                                    {{ keyValue[1] }}
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card>
                      <v-divider class="my-4" />
                    </v-col>
                  </template>
                </v-row>
              </template>
            </template>
            <v-card-subtitle
              v-else
              class="red--text font-weight-bold text-h5 pa-2 mb-4"
            >
              The email is still sending. Please check back later for an updated Single Mailing Report which will be completed on ({{ results.mailingMetaData.winningDistribution > 0 ? moment($_global_parseAsUtc(results.mailingMetaData.winningScheduleDateEnd)).add(1, 'd') : moment($_global_parseAsUtc(results.mailingMetaData.scheduleDateEnd)).add(1, 'd') | displayDateFormat }}).
              <br>
              If you want to see basic metrics for this mailing, check out the
              <router-link :to="{ name: 'MailingResultsDashboard', params: { campaignId, mailingItemId} }">
                Real Time Dashboard
              </router-link>
              .
            </v-card-subtitle>
          </section>
        </vue-html2pdf>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { inputDateFormat } from '@/shared/constants'
import { mapGetters } from 'vuex'
import claims from '@/shared/models/auth/claims'
import { TableCSVExporter } from '@/shared/models/mailing'
import VueHtml2pdf from 'vue-html2pdf'
import moment from 'moment'
import { splitMixin } from '@/shared/mixins/mailing'
import { enumsMixin, downloadFileMixin, windowMixin } from '@/shared/mixins/general'
import { mailingService, azBlobService } from '@/shared/services'
// import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels'

export default {
  name: 'SingleMailingReport',

  components: {
    CampaignHeader: () => import('@/views/pages/components/mailing/CampaignHeader'),
    LineChart: () => import('@/views/pages/components/charts/LineChart'),
    BarChart: () => import('@/views/pages/components/charts/BarChart'),
    PieChart: () => import('@/views/pages/components/charts/PieChart'),
    VueHtml2pdf
  },

  mixins: [splitMixin, enumsMixin, downloadFileMixin, windowMixin],

  props: {
    campaignId: {
      type: String,
      required: true
    },
    mailingItemId: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      reportLoading: false,
      pdfMenu: false,
      engagementMetrics: [
        'Gross Clicks',
        'Unique Clicks',
        'Gross Opens',
        'Unique Opens',
        'Opt Outs'
      ],
      engagementMetricsOff: [
        'Unique Clicks',
        'Unique Opens'
      ],
      readTimeMetrics: [
        { display: 'Glanced (0-2s)', value: 'glanced', rate: 'glancedRate' },
        { display: 'Skimmed (3-7s)', value: 'skimmed', rate: 'skimmedRate' },
        { display: 'Read (8s+)', value: 'read', rate: 'readRate' }
      ],
      // readTimeDevices: [
      //   'Desktop',
      //   'Mobile',
      //   'Total'
      // ],
      results: null,
      liveResults: null,
      toggleLinkData: false,
      toggleAgeData: false,
      toggleGrossData: false,
      drawer: false,
      loadingScheduled: false,
      scheduledMailings: [],
      daysFromSend: 14
    }
  },

  watch: {
    '$route': function () {
      this.getSingleMailingReport()
    },
    drawer: function (newValue, oldValue) {
      if (newValue) {
        this.$vuetify.goTo(0)
      }
    },
    daysFromSend (newValue, oldValue) {
      this.getLiveMailingResults()
    }
  },

  created () {
    this.getSingleMailingReport()
    this.getLiveMailingResults()
    this.loadScheduledMailings()
  },

  computed: {
    ...mapGetters('auth', ['hasClaimKV']),

    htmlToPdfOptions () {
      return {
        margin: 0,
        filename: `${this.results.mailingMetaData.campaignNowwOrderNumber}_${this.results.mailingMetaData.name}_SingleMailingReport`,
        image: {
          type: 'jpeg',
          quality: 0.98
        },
        enableLinks: true,

        html2canvas: {
          scale: 2,
          useCORS: true
        },
        jsPDF: {
          unit: 'in',
          format: 'letter',
          orientation: 'landscape'
        }
      }
    },
    showLinkClicks () {
      return this.hasClaimKV(claims.R_LD) && (!this.showLinkToggle || this.toggleLinkData)
    },
    showLinkToggle () {
      return this.hasClaimKV(claims.MMS_TRA)
    },
    showAgeData () {
      return this.hasClaimKV(claims.R_AD) && (!this.showAgeToggle || this.toggleAgeData)
    },
    showAgeToggle () {
      return this.hasClaimKV(claims.MMS_TRA)
    },
    engagementMetricsComputed () {
      return this.toggleGrossData ? this.engagementMetrics : this.engagementMetricsOff
    },
    engagementMetricsDataSplits () {
      if (!this.results || !this.results.splitData || !this.results.splitData.engagementSummaryChart) return {}
      return {
        ...this.results.splitData.engagementSummaryChart,
        datasets: this.results.splitData.engagementSummaryChart.datasets.filter(x => this.engagementMetricsComputed.some(metric => x.label.includes(metric)))
      }
    },
    engagementMetricsDataWinner () {
      if (!this.results || !this.results.winnerData || !this.results.winnerData.engagementSummaryChart) return {}
      return {
        ...this.results.winnerData.engagementSummaryChart,
        datasets: this.results.winnerData.engagementSummaryChart.datasets.filter(x => this.engagementMetricsComputed.some(metric => x.label.includes(metric)))
      }
    },
    tableElementsList () {
      let arr = []
      arr.push(document.getElementById('deliverabilityMetrics'))
      arr.push(document.getElementById('engagementSummaryByDay'))
      if (this.results.mailingMetaData.splits.length > 1) {
        arr.push(document.getElementById('readingEngagement'))
      }
      if (this.showLinkClicks) {
        if (this.results.areSplitLinksSame) {
          arr.push(document.getElementById('linkClicksSame'))
        } else {
          results.splitData.linkClicksSummary.forEach((x, i) => {
            arr.push(document.getElementById(`${i}-linkClicks`))
          })
          results.winnerData.linkClicksSummary.forEach((x, i) => {
            arr.push(document.getElementById(`w-${i}-linkClicks`))
          })
        }
      }
      return arr
    },
    isSendComplete () {
      const currentTimeUtc = new Date()
      const date = new Date(this.results.mailingMetaData.sentOn)
      const sendCompleteTime = new Date(
        date.getTime() + 24 * 60 * 60 * 1000
      )
      return sendCompleteTime < currentTimeUtc
    },
    progressColor () {
      const progressPercentage = this.isSendComplete ? 100 : Math.round(this.results.deliverabilityMetricsTotals.sentMessages / this.results.deliverabilityMetricsTotals.sentMessages * 100)

      if (progressPercentage === 0) {
        return 'grey' // Set to grey if progress is 0%
      } else if (progressPercentage < 100) {
        return 'orange lighten-1' // Set to yellow if progress is between 1% and 99%
      } else {
        return 'green lighten-2' // Set to green if progress is 100%
      }
    }
  },

  methods: {
    validateReadingEngagement (chartData) {
      for (const dataset of chartData.datasets) {
        if (!dataset.data.every(value => value === 0)) {
          return false
        }
      }
      return true
    },
    async loadScheduledMailings () {
      this.loadingScheduled = true
      await mailingService.getScheduledMailings(this.$store.getters['simulatedCustomerCrmId'](), moment().add(-90, 'd').format(inputDateFormat), moment().add(-1, 'd').format(inputDateFormat), true)
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.scheduledMailings = resp.map((x, index) => (
              {
                index,
                ...x,
                modifiedOnLocal: x.modifiedOn ? moment(this.$_global_parseAsUtc(x.modifiedOn)).local() : null,
                scheduleDateLocal: x.scheduleDate ? moment(this.$_global_parseAsUtc(x.scheduleDate)).local() : null,
                defaulScheduleDateLocal: x.defaulScheduleDate ? moment(this.$_global_parseAsUtc(x.defaulScheduleDate)).local() : null,
                winningScheduleDateLocal: x.winningScheduleDate ? moment(this.$_global_parseAsUtc(x.winningScheduleDate)).local() : null,
                sentOnLocal: x.sentOn ? moment(this.$_global_parseAsUtc(x.sentOn)).local() : null
              }
            ))
          }
        })
        .finally(() => {
          this.loadingScheduled = false
        })
    },
    getTimezoneOffset () {
      var dateString = new Date()
      return dateString.getTimezoneOffset()
    },
    moment (date) {
      return moment(date)
    },
    async getSingleMailingReport () {
      this.$vuetify.goTo(0)
      this.results = null
      this.reportLoading = true
      await mailingService.getSingleMailingReport({ mailingItemId: this.mailingItemId, clientTimeZone: this.getTimezoneOffset() })
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.results = resp
          }
        })
        .finally(() => {
          this.reportLoading = false
        })
    },
    async getLiveMailingResults () {
      this.$vuetify.goTo(0)
      await mailingService.getMailingResults({ mailingItemId: this.mailingItemId, daysFromSend: this.daysFromSend, clientTimeZone: this.getTimezoneOffset() })
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.liveResults = resp
          }
        })
    },
    getChartOptionsLine (title) {
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
          fontStyle: 'bold',
          fontSize: 18,
          text: title
        },
        legend: {
          position: 'right',
          labels: {
            fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
            fontSize: 14
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'"
            }
          }],
          xAxes: [{
            ticks: {
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'"
            }
          }]
        }
        // plugins: {
        //   datalabels: {
        //     display: false,
        //     clamp: true,
        //     anchor: 'end',
        //     align: 'top',
        //     rotation: -30,
        //     formatter: function (value, context) {
        //       return `${parseFloat(value).toFixed(2)}`
        //     },
        //     font: {
        //       family: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
        //       weight: 'bold'
        //     }
        //   }
        // }
      }
    },
    getChartOptionsLinePercent (title, yAxisLabel, xAxisLabel) {
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
          fontStyle: 'bold',
          fontSize: 18,
          text: title
        },
        legend: {
          labels: {
            fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
            fontSize: 14
          }
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              fontStyle: 'bold',
              fontSize: 14,
              labelString: yAxisLabel
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 50,
              beginAtZero: true,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              // Include a '%' sign in the ticks
              callback: function (value, index, values) {
                return `${value}%`
              }
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              fontStyle: 'bold',
              fontSize: 14,
              labelString: xAxisLabel
            },
            ticks: {
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'"
            }
          }]
        }
        // plugins: {
        //   datalabels: {
        //     clamp: true,
        //     anchor: 'end',
        //     align: 'top',
        //     rotation: -30,
        //     formatter: function (value, context) {
        //       return `${parseFloat(value).toFixed(2)}%`
        //     },
        //     font: {
        //       family: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
        //       weight: 'bold'
        //     }
        //   }
        // }
      }
    },
    getChartOptionsBar (title) {
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
          fontStyle: 'bold',
          fontSize: 18,
          text: title
        },
        legend: {
          labels: {
            fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
            fontSize: 14
          }
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              fontStyle: 'bold',
              fontSize: 14
            },
            ticks: {
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'"
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              fontStyle: 'bold',
              fontSize: 14
            },
            ticks: {
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'"
            }
          }]
        }
        // plugins: {
        //   datalabels: {
        //     clamp: true,
        //     anchor: 'end',
        //     align: 'top',
        //     rotation: -30,
        //     formatter: function (value, context) {
        //       return `${parseFloat(value).toFixed(2)}`
        //     },
        //     font: {
        //       family: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
        //       weight: 'bold'
        //     }
        //   }
        // }
      }
    },
    getChartOptionsBarPercent (title, yAxisLabel, xAxisLabel) {
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
          fontStyle: 'bold',
          fontSize: 18,
          text: title
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: yAxisLabel,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              fontStyle: 'bold',
              fontSize: 14,
              labelString: yAxisLabel
            },
            ticks: {
              beginAtZero: true,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              // max: max,
              // Include a '%' sign in the ticks
              callback: function (value, index, values) {
                return `${value}%`
              }
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: xAxisLabel,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              fontStyle: 'bold',
              fontSize: 14,
              labelString: xAxisLabel
            },
            ticks: {
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'"
            }
          }]
        }
        // plugins: {
        //   datalabels: {
        //     clamp: true,
        //     anchor: 'end',
        //     align: 'top',
        //     rotation: -30,
        //     formatter: function (value, context) {
        //       return `${parseFloat(value).toFixed(2)}%`
        //     },
        //     font: {
        //       family: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
        //       weight: 'bold'
        //     }
        //   }
        // }
      }
    },
    getChartOptionsBarPercent2 (title, yAxisLabel, xAxisLabel) {
      var bar = this.getChartOptionsBarPercent(title, yAxisLabel, xAxisLabel)
      return {
        ...bar,
        legend: {
          labels: {
            fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
            fontSize: 14
          }
        }
      }
    },
    getChartOptionsPie (title, legendPosition = 'right') {
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
          fontStyle: 'bold',
          fontSize: 18,
          text: title
        },
        legend: {
          position: legendPosition,
          labels: {
            fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'"
          }
        }
        // plugins: {
        //   datalabels: {
        //     display: false,
        //     clamp: true,
        //     anchor: 'end',
        //     align: 'top',
        //     rotation: -30,
        //     font: {
        //       family: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
        //       weight: 'bold'
        //     }
        //   }
        // }
      }
    },
    getChartOptionsPie2 (title, legendPosition = 'top') {
      var pie = this.getChartOptionsPie(title, legendPosition)
      return {
        ...pie,
        legend: {
          ...pie.legend,
          labels: {
            ...pie.legend.labels,
            fontStyle: 'bold',
            fontSize: 18
          }
        }
      }
    },
    // getMaxYAxisForChart (chart) {
    //   var max = 0
    //   if (!chart || !chart.datasets) return 100
    //   chart.datasets.forEach(x => {
    //     max = Math.max(max, Math.max(...x.data))
    //   })
    //   return Math.ceil(max * 1.2)
    // },
    getEngagementPercent (data, label, delivered) {
      var sum = data.find(x => x.label === label).data.reduce((prev, next) => prev + next)
      if (sum === 0 || delivered === 0) {
        return 0
      }
      return sum / delivered * 100
    },
    getTotalTableForEngagementPercent (metric) {
      var sum = 0
      var delivered = 0
      this.results.mailingMetaData.splits.forEach((split, splitIndex) => {
        sum += this.engagementMetricsDataSplits.datasets.find(x => x.label === `${split.splitLetter} - ${metric}`).data.reduce((prev, next) => prev + next)
        delivered += this.results.splitData.engagementSummary[splitIndex].deliveredMessages
      })
      if (this.results.mailingMetaData.winningDistribution > 0) {
        sum += this.engagementMetricsDataWinner.datasets.find(x => x.label === `${this.results.winnerLabel} - ${metric}`).data.reduce((prev, next) => prev + next)
        delivered += this.results.winnerData.engagementSummary[0].deliveredMessages
      }
      if (sum === 0 || delivered === 0) {
        return 0
      }
      return sum / delivered * 100
    },
    getNumberOfSendsPresented () {
      let total = this.results.mailingMetaData.splits.length
      total += this.results.mailingMetaData.winningDistribution > 0 ? 1 : 0
      return total
    },
    getEngagementHeaderColspan () {
      let total = this.results.mailingMetaData.splits.length > 1 ? 1 : 0
      total += this.results.mailingMetaData.winningDistribution > 0 ? 1 : 0
      return total + this.results.mailingMetaData.splits.length
      // results.mailingMetaData.winningDistribution > 0 ? results.splitData.engagementSummary.length + 1 : results.splitData.engagementSummary.length
    },
    getReadTimeHeaderColspan () {
      let total = this.results.mailingMetaData.winningDistribution > 0 ? 1 : 0
      return total + this.results.mailingMetaData.splits.length
    },
    getTotalRowForEngagement (labelIndex, metric) {
      let total = 0
      this.results.mailingMetaData.splits.forEach((split, splitIndex) => {
        total += this.engagementMetricsDataSplits.datasets.find(x => x.label === `${split.splitLetter} - ${metric}`).data[labelIndex]
      })
      if (this.results.mailingMetaData.winningDistribution > 0) {
        total += this.engagementMetricsDataWinner.datasets.find(x => x.label === `${this.results.winnerLabel} - ${metric}`).data[labelIndex]
      }
      return total
    },
    getTotalTableForEngagement (metric) {
      let total = 0
      this.results.mailingMetaData.splits.forEach((split, splitIndex) => {
        total += this.engagementMetricsDataSplits.datasets.find(x => x.label === `${split.splitLetter} - ${metric}`).data.reduce((prev, next) => prev + next)
      })
      if (this.results.mailingMetaData.winningDistribution > 0) {
        total += this.engagementMetricsDataWinner.datasets.find(x => x.label === `${this.results.winnerLabel} - ${metric}`).data.reduce((prev, next) => prev + next)
      }
      return total
    },
    async downloadEntireReport () {
      const exporter = new TableCSVExporter(this.tableElementsList, [document.getElementById('headerTable1'), document.getElementById('headerTable2')])
      var csvOutput = exporter.convertToCSV()

      let colCount = exporter.findLongestRowLength()
      csvOutput += this.$_downloadFileMixin_getChartReportAsText(this.results.readingEnvironmentsChart, '%', colCount)
      csvOutput += `${','.repeat(colCount - 1)}\n`
      csvOutput += this.$_downloadFileMixin_getChartReportAsText(this.results.emailClientDeviceSummary, '%', colCount)
      csvOutput += `${','.repeat(colCount - 1)}\n`
      csvOutput += this.$_downloadFileMixin_getChartReportAsText(this.results.viewingEngagementTotalSummary, '%', colCount)
      if (this.showAgeData) {
        csvOutput += `${','.repeat(colCount - 1)}\n`
        csvOutput += this.$_downloadFileMixin_getChartReportAsText(this.results.ageEngagementChart, '%', colCount, true)
      }

      const csvBlob = new Blob([csvOutput], { type: 'application/text' })
      const blobUrl = URL.createObjectURL(csvBlob)
      const anchorElement = document.createElement('a')

      anchorElement.href = blobUrl
      anchorElement.download = `${this.results.mailingMetaData.campaignNowwOrderNumber}-${this.results.mailingMetaData.name.replace(/ /g, '')}-SMR.csv`
      anchorElement.click()

      setTimeout(() => {
        URL.revokeObjectURL(blobUrl)
      }, 500)
    },
    async downloadComprehensiveReportZip () {
      var _this = this
      this.$store.commit('setLoadingMessage', 'Downloading Comprehensive Report')
      await azBlobService
        .downloadComprehensiveReportZip(this.mailingItemId)
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.$_downloadFileMixin_downloadResponse(resp)
          }
        })
    },
    async downloadComprehensiveReportReceived () {
      var _this = this
      this.$store.commit('setLoadingMessage', 'Downloading Comprehensive Report')
      await azBlobService
        .downloadComprehensiveReportReceived(this.mailingItemId)
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.$_downloadFileMixin_downloadResponse(resp)
          }
        })
    },
    async downloadComprehensiveReportOpens () {
      var _this = this
      this.$store.commit('setLoadingMessage', 'Downloading Comprehensive Report')
      await azBlobService
        .downloadComprehensiveReportOpens(this.mailingItemId)
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.$_downloadFileMixin_downloadResponse(resp)
          }
        })
    },
    async downloadComprehensiveReportClicks () {
      var _this = this
      this.$store.commit('setLoadingMessage', 'Downloading Comprehensive Report')
      await azBlobService
        .downloadComprehensiveReportClicks(this.mailingItemId)
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.$_downloadFileMixin_downloadResponse(resp)
          }
        })
    },
    async downloadComprehensiveReportOptOuts () {
      var _this = this
      this.$store.commit('setLoadingMessage', 'Downloading Comprehensive Report')
      await azBlobService
        .downloadComprehensiveReportOptOuts(this.mailingItemId)
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.$_downloadFileMixin_downloadResponse(resp)
          }
        })
    },
    async downloadComprehensiveReportEnhanced () {
      var _this = this
      this.$store.commit('setLoadingMessage', 'Downloading Comprehensive Report')
      await azBlobService
        .downloadComprehensiveReportEnhanced(this.mailingItemId)
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.$_downloadFileMixin_downloadResponse(resp)
          }
        })
    },
    async downloadLeadScoresReport () {
      var _this = this
      this.$store.commit('setLoadingMessage', 'Downloading Lead Scores Report')
      await azBlobService
        .downloadLeadScoresReport(this.mailingItemId)
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.$_downloadFileMixin_downloadResponse(resp)
          }
        })
    },
    generatePdfReport () {
      this.$refs.html2Pdf.generatePdf()
    },

    async beforeDownload ({ html2pdf, options, pdfContent }) {
      await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setFontSize(10)
          pdf.setTextColor(150)
          pdf.text('Generated by NOWW Platform - MMS, Inc. Confidential.  https://www.mmslists.com', (pdf.internal.pageSize.getWidth() * 0.26), (pdf.internal.pageSize.getHeight() - 0.2))
        }
      }).save()
      this.$_windowMixin_removeContentClass('pdf-letter-width')
      this.pdfMenu = false
    },

    beforePdfDownload () {
      this.$_windowMixin_addContentClass('pdf-letter-width')
    },
    afterPdfDownload () {
      this.$_windowMixin_removeContentClass('pdf-letter-width')
      this.pdfMenu = false
    }
  }
}
</script>

<style lang="scss">
.single-mailing-report *, .single-mailing-report .text-h4, .single-mailing-report .text-h5, .single-mailing-report .text-subtitle-1, .single-mailing-report .text-subtitle-2 {
  font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;
  vertical-align: top;
}

.pdf-content {
  .pdf-header {
    display: flex;
    padding: 10px;
    padding-bottom: 5px;

    .text-h4 {
      font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;
      font-size: 1.52rem;
      font-weight: bold;
      color: #6d2c8a;
    }

    .text-h5 {
      font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;
      font-size: 1.0625rem;
      color: #6d2c8a;
    }
  }

  .summ-table {
    font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;
  }

  .dist-eng-summ {
    font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;
    text-align: center;

    .dist-eng-summ-title {
      font-size: 1.5rem;
      line-height: 2rem;
      letter-spacing: normal;
      font-weight: 700;
    }

    .dist-eng-summ-green {
      color: #4CAF50;
      font-weight: 700 !important;
    }

    .dist-eng-summ-gray {
      color: gray;
      font-weight: 700 !important;
    }
  }

  .dist-eng-charts {
    margin-left: 40px
  }

  .eng-summ-table {
    font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;

    .eng-summ-table-th {
      font-size: 0.875rem;
      line-height: 1.375rem;
      font-weight: 700;
      letter-spacing: 0.0071428571em;
    }

    .eng-summ-table-row-top-border {
      border-top: 2px solid rgba(0, 0, 0, 0.36);
      text-align: right;
      font-weight: 700;
    }

    .eng-summ-table-head-total {
      border-top: 2px solid rgba(0, 0, 0, 0.36);
      border-right: 2px solid rgba(0, 0, 0, 0.12);
      text-align: right;
      font-weight: 700;
    }

    .eng-summ-table-bottom-total {
      border-right: 2px solid rgba(0, 0, 0, 0.12);
      text-align: right;
      font-weight: 700;
    }

    .eng-summ-table-td-total {
      text-align: right;
      font-weight: 700;
    }
  }

  .reading-eng {
    font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;
    text-align: center;

    .read-eng-table {
      border-bottom: none
    }

    .reading-eng-desc {
      font-size: 1.3rem;
      line-height: 2rem;
      letter-spacing: normal;
      font-weight: 400;
      border-bottom: none;
      border:none
    }

    .reading-eng-summ {
      font-size: 1.80rem;
      font-weight: 400;
      line-height: 2.5rem;
      letter-spacing: 0.0073529412em;
    }

    .reading-eng-chart {
      margin-left: 25px;
    }
  }

  .link-click-table {
    font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;

      .link-click-table-th {
      font-size: 0.875rem;
      line-height: 1.375rem;
      font-weight: 700;
      letter-spacing: 0.0071428571em;
    }
      .link-click-table-tb-light {
        text-align: right;
        font-weight: 400;
      }

      .link-click-table-tb {
        text-align: right;
        font-weight: 700;
      }
  }

  .reading-eng-th-text {
      font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;
      font-size: 0.875rem;
      line-height: 1.375rem;
      font-weight: 700;
      letter-spacing: 0.0071428571em;
    }

  .reading-eng-trtitle-text {
      font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;
      text-align: left;
      font-size: 0.875rem;
      line-height: 1.375rem;
      font-weight: 700;
      letter-spacing: 0.0071428571em;
    }

  .reading-eng-td-row {
      font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;
      text-align: right;
      font-weight: 400;
    }

  .reading-eng-td-total {
      font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;
      text-align: right;
      font-weight: 700;
    }

  .title-bar {
    line-height: 2rem;
    letter-spacing: normal !important;
    background-color: #9e9e9e !important;
    border-color: #9e9e9e !important;

    .title-bar-text {
      font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;
      font-size: 1.20rem;
      font-weight: bold;
      color: #FFFFFF !important
    }
  }

  .delv-met {
    font-family: Century Gothic,CenturyGothic,AppleGothic,Roboto,sans-serif !important;

    .delv-met-th-text {
      font-size: 0.875rem;
      line-height: 1.375rem;
      font-weight: 700;
      letter-spacing: 0.0071428571em;
    }

    .delv-met-th-total {
      font-size: 0.875rem;
      text-align: right;
      font-weight: 700;
    }
  }
}

.single-mailing-report .table-border-top {
  border-top: 2px solid rgba(3, 2, 2, 0.12);
}
.single-mailing-report .table-border-top-bold {
  border-top: 2px solid rgba(0, 0, 0, 0.36);
}
.single-mailing-report .table-border-right {
  border-right: 2px solid rgba(0, 0, 0, 0.12);
}
.single-mailing-report .table-border-right-bold {
  border-right: 2px solid rgba(0, 0, 0, 0.36);
}
.single-mailing-report .table-border-bottom {
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
}
.single-mailing-report .table-border-bottom-bold {
  border-bottom: 2px solid rgba(0, 0, 0, 0.36) !important;
}
.single-mailing-report .table-border-left {
  border-left: 2px solid rgba(0, 0, 0, 0.12);
}
.single-mailing-report .table-border-left-bold {
  border-left: 2px solid rgba(0, 0, 0, 0.36);
}
.single-mailing-report td, .single-mailing-report th {
  padding: 0 4px !important
}
.single-mailing-report .winningCriteriaHeader {
  background-color: #A5D6A7;
}
.single-mailing-report .no-borders td {
  border-bottom: none !important;
}
.single-mailing-report .no-hover tbody {
     tr:hover {
        background-color: transparent !important;
     }
  }
</style>
